@import 'variables';

$rxcap: (
  $--login-logo: url('../images/brands/rxcap/rxCapwhiteicon.svg'),
  $--logo: url('../images/brands/rxcap/rxCapwhiteicon.svg'),
  $--navBar-logo-left-margin: 20px,
);
$caredx: (
  $--login-logo: url('../images/brands/caredx/AlloHomeLogo.svg'),
  $--logo: url('../images/brands/caredx/NavIcon_Logo_AlloHome.svg'),
  $--navBar-logo-left-margin: 20px,
);
$esi: (
  $--login-logo: url('../images/brands/rxcap/rxCapwhiteicon.svg'),
  $--logo: url('../images/brands/esi/newLogoLidSync.svg'),
  $--navBar-logo-left-margin: 0,
);
$gxcap: (
  $--login-logo: url('../images/brands/gxcap/gxCapwhiteicon.svg'),
  $--logo: url('../images/brands/gxcap/gxCapwhiteicon.svg'),
  $--navBar-logo-left-margin: 20px,
);
