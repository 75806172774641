@import "../../../../style/_variables";

$scale-koef: 0.875;
$font-color: #4F4F4F;

.history-view {

  .refresh-button{
    width: 42px !important;
    height: 42px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .refresh-button img{
    width: 80%;
  }

  .infoBox {
    font-size: 24px*$scale-koef;
  }

  .total-bubble {
    font-size: 27px*$scale-koef;
    font-weight: 600;
    margin: 0 20px 0 0;
    padding: 7px 15px;
    color: black !important;
    border: 3px solid #B1D590;

    border-radius: 25px * $scale-koef;
    background: none !important;

    &.poor {
      border-color: $color-poor;
    }

    &.average {
      border-color: $color-average;
    }

    &.good {
      border-color: $color-good;
    }
  }

  .right-select {
    margin-left: auto;
  }

  .select-box-dashboard {

    select {
      font-family: GillSans;
      font-size: 22px * $scale-koef;
      overflow: hidden;
      padding-left: 30px;
      text-align: center;
      white-space: nowrap;
      text-transform: capitalize;
      text-overflow: ellipsis;
      color: $font-color;
      border: 0;
      background: transparent;
      background: url("~/images/rx/selected-down.svg") no-repeat;
      background-position: left;
      background-size: 50px;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;

      &.left {
        padding: 8px 12px 13px 55px;
        padding-left: 30px;
        background-position: left;
      }

      &.right {
        max-width: 187px;
        padding: 8px 55px 13px 12px;
        padding-left: 30px;
        background-position: right;
      }
    }
  }
}
