.adherence-container {
  padding: 30px 20px 30px 10px;
  border-radius: 13.3px;
  background-color: #fbfbfb;
  margin-top: 20px;
  overflow-x: auto;
  min-height: 180px;

  &.with-loader .spinner-container .spinner {
    position: absolute;
    top: 5px;
    left: auto;
    right: 10px;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
  }
}

.no-data-image-container-adherence {
  position: absolute;
      margin: 0 auto;
      width: 90%;
      margin-top: 50px;
      height: auto;
      > .no-data-image {
        margin: auto;
        /* height: 84px; */
        /* width: 84px; */
        /* display: inline-block; */
        /* background-image: url(/react/1e63ba8….png); */
        background-color: white;
        width: 101px;
        height: 30px;
        opacity: 0.5;
        font-family: GillSans;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: center;
        color: #929292;
        border: 1px solid #929292;
        border-radius: 7px;
      }
}

.adherence-table {
  .navigation-buttons {
    float: right;
    margin-top: 6px;
    margin-right: -2px;
  }

  .year-navigation {
    display: flex;
    justify-content: space-between;

    .months {
      display: flex;
      flex-wrap: nowrap;
      padding: 3px;
      margin-top: 13px;
      flex-basis: 74%;
      .month {
        flex: 1 1 100%;
        height: 8px;
        background-color: #2492cf;
        border-right: 1px solid #fff;
        box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.13);

        &.active-period {
          background-color: rgba(36, 146, 207, 0.2);
          cursor: pointer;

          &:hover {
            background-color: #2492cf;
            border-radius: 10px;
          }

          &.active-month {
            background-color: #2492cf;
            border-radius: 10px;
          }
        }

        &.disabled {
          background-color: #f7f7f7;
        }

        &:first-of-type {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:nth-last-child(1) {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  button {
    background: white;
    color: #929292;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.06);
    padding: 0;
    width: 67px;
    height: 24px;
    border-radius: 20px;
    min-width: 67px;
    margin: 0 15px;

    &.right {
      margin-right: 0;
    }

    &.disabled {
      background-color: #f9f9f9;
      pointer-events: none;
      cursor: not-allowed;
    }

    &.next {
      // border-radius: 0 5px 5px 0;
      // margin-left: -1px;
    }

    &.prev {
      // border-radius: 5px 0 0 5px;
    }

    &.adherence-arrow {
      &:after {
        content: " ";
        width: 10px;
        height: 14px;
        background: url("../images/adherence-arrow.png");
        display: block;
        margin: auto;
      }

      &.left:after {
        background-position-y: 14px;
        background-position-x: 10px;
      }

      &.right:after {
        // background-position-y: 14px;
        background-position-x: 10px;
      }

      &.disabled:after {
        background-position-x: 10px;
      }
    }
  }

  .adherence-percent {
    line-height: 40px;
    width: auto;
    display: inline-block;
    background-color: #ffffff;
    border-radius: 10px;
    border: 0;
    &.day {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
    }
  }

  .help-rows {
    float: right;
    margin-top: 0;
    background-color: transparent;
    border: none;

    .help-row {
      display: inline-block;
      line-height: 40px;
      margin-right: 20px;

      &.mr-0 {
        margin-right: 0;
      }

      .circle-wrapper {
        margin-top: 10px;
        margin-right: 10px;
        padding: 4px;
        border-radius: 10px;
        float: left;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.06);
        background-color: #ffffff;

        .circle {
          width: 10px;
          height: 10px;
          border-radius: 5px;
          box-sizing: border-box;
          // border: 1px transparent solid;
          background: #eaac6c;

          &.complied {
            background-color: #92da7b;
          }

          &.missed {
            background-color: #e6819f;
          }
        }
      }
    }
  }

  td,
  th {
    // border: 1px solid $color-border-base;
    // height: 35px;
    text-align: center;
  }

  tr.control > th {
    border: none;
    background-color: transparent;
    vertical-align: middle;
    text-align: center;
    // height: 105px;
    overflow: visible;

    span {
      font-size: $font-size-large;
    }

    span.adherence-percent,
    span.caption.date {
      line-height: 44px;
      margin-top: 6px;

      &.adherence-percent {
        float: left;
        font-size: 100%;
      }

      &.caption.date {
        display: inline-block;
      }
    }
  }

  tbody > tr {
    transform: translate(0, 10px);
  }

  .head .tr {
    display: flex;
    flex-wrap: nowrap;
    padding: 3px 21px;
  }

  .head {
    .tr {
      .th {
        display: inline-block;
        background-color: #f9fafa;
        height: 30px;
        width: 34px;
        color: #4f4f4f;
        font-weight: normal;
        font-family: GillSans;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 23px;
        letter-spacing: 0px;
        text-align: center;
        color: #4f4f4f;
        background: #e5f0f6;
        border-radius: 0px;
        border: 0;
        margin-right: 2px;
        margin: 0px auto;
        width: 100%;
        margin-right: 2px;

        @media (max-width: 1440px) {
          height:  23px;
        }

        &.weekend-pre {
          border-top-left-radius: 14.3px;
          border-bottom-left-radius: 14.3px;
          background-color: #f6f0e5;
          margin-right: 0;

          @media (max-width: 1440px) {   
            border-top-left-radius: 11.3px;
            border-bottom-left-radius: 11.3px;

          }
        }

        &.weekend-post {
          border-top-right-radius: 14.3px;
          border-bottom-right-radius: 14.3px;
          background-color: #f6f0e5;

          @media (max-width: 1440px) {    
            border-top-right-radius: 11.3px;
            border-bottom-right-radius: 11.3px;
          }
        }

        &.day1 {
          border-top-left-radius: 14.3px;
          border-bottom-left-radius: 14.3px;
          margin-right: 0;
          border-right: 1px solid #e5f0f6;

          @media (max-width: 1440px) {     
            border-top-left-radius: 11.3px;
            border-bottom-left-radius: 11.3px;
          }
        }

        &.day2,
        &.day3,
        &.day4 {
          margin-right: 0;
          border-right: 1px solid #e5f0f6;
        }

        &.last-day {
          margin-right: 2px;
        }

        &.day5 {
          border-top-right-radius: 14.3px;
          border-bottom-right-radius: 14.3px;

          @media (max-width: 1440px) {           
            border-top-right-radius: 11.3px;
            border-bottom-right-radius: 11.3px;
         }
        }

        .day-seq {
          width: 21px;
          height: 23px;
          line-height: 26px;
          font-size: 13px;
          margin-top: 3px;
          border-radius: 15.3px;
          margin-left: 7px;
          margin: 3px auto;

          @media (max-width: 1440px) {           
              width: 18px;
              height: 15px;
              line-height: 16px;
              margin-top: 4px;
              border-radius: 11.3px;
              margin-left: 3px;
             font-size: 10px;
          }

          &:hover {
            background: #fff;
          }
        }

        &.color {
          .day-seq {
            background: #fff;
          }
        }

        &.td,
        &.name {
          background: transparent;
        }

        &.patient-total {
          width: 82px;
          background-color: transparent;
        }
        &:first-of-type {
          font-family: GillSans;
          font-size: 12px;
          letter-spacing: 0px;
          color: #4f4f4f;
          flex: 0 0 133px;
          background: transparent;
          text-align: left;
          margin-left: 0;
          margin-right: 0;
        }
        &:nth-child(2) {
          border-top-left-radius: 14.3px;
          border-bottom-left-radius: 14.3px;

          @media (max-width: 1440px) {     
            border-top-left-radius: 11.3px;
            border-bottom-left-radius: 11.3px;
          }
        }
        &:nth-last-child(2) {
          border-top-right-radius: 14.3px;
          border-bottom-right-radius: 14.3px;

          @media (max-width: 1440px) {     
            border-top-right-radius: 11.3px;
            border-bottom-right-radius: 11.3px;
          }
        }
        &:nth-last-child(1) {
          width: 82px;
          background-color: transparent;
          border-radius: 11.3px;
          background-color: #f0f0f0;
          flex: 0 0 44px;
          font-size: 13px;
          line-height: 30px;

          @media (max-width: 1440px) {   
            line-height: 22px;
            font-size: 10px;
          }
        }
      }
    }

    &.body-content {
      .tr {
        margin: 10px 0;
        padding: 3px 21px;
        border-radius: 8px;
        .th {
          display: inline-block;
          background-color: #f9fafa;
          width: 34px;
          height: 15px;
          color: #4f4f4f;
          font-weight: normal;
          // min-width: 21px;
          font-family: GillSans;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0px;
          text-align: center;
          color: #4f4f4f;
          background: #e5f0f6;
          border-radius: 0px;
          border: 0;
          margin-right: 2px;
          line-height: 1.3;
          min-width: 23px;

          .day-seq {
            width: 21px;
            height: 15px;
            line-height: 16px;
            margin-top: 10px;
            border-radius: 11.3px;
            background-color: #ffff;
          }

          .average {
            width: 23px;
            height: 15px;
            border-radius: 11.3px;

            &.perfect {
              background-color: transparent;
            }
            .perfect {
              width: 21px;
              height: 15px;
              border-radius: 11.3px;
              background-color: #caecbf;

              &:hover {
                background-color: #92da7b;
              }
            }

            .active {
              background-color: #caecbf;
              height: 15px;
              border-top-left-radius: 12.3px;
              display: inline-block;
              border-bottom-left-radius: 11.3px;

              &:hover {
                background-color: #92da7b;
              }

              &:after {
                content: " ";
                width: 1px;
                height: 16px;
                background-color: #fff;
                display: block;
                float: right;
              }
            }

            .remain {
              display: inline-block;
              background: #f7bfcd;
              height: 15px;
              border-radius: 0;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              &.perfect-pink {
                width: 100% !important;
                border-radius: 10px;

                &:hover {
                  background-color: #e6819f;
                }
              }
            }
          }

          &.td,
          &.name {
            background: transparent;
          }

          &.patient-total {
            width: 82px;
            background-color: transparent;
          }
          &:first-of-type {
            font-family: GillSans;
            font-size: 12px;
            letter-spacing: 0px;
            color: #4f4f4f;
            flex: 0 0 133px;
            background: transparent;
            line-height: 1.2;
            text-align: left;
          }
          &:nth-child(2) {
            border-top-left-radius: 11.3px;
            border-bottom-left-radius: 11.3px;
          }
          &:nth-last-child(2) {
            border-top-right-radius: 11.3px;
            border-bottom-right-radius: 11.3px;
          }
          &:nth-last-child(1) {
            width: 82px;
            background-color: transparent;
            border-radius: 11.3px;
            flex: 0 0 44px;
          }
        }
      }
    }
  }

  tbody > tr:last-of-type > td {
    border-bottom-color: $color-border-base;
    border: 0;
  }

  head .tr .th {
    overflow: hidden;
    padding: 5px;
    // height: 40px;
    &:first-of-type {
      border-top-left-radius: 11.3px;
      border-bottom-left-radius: 11.3px;
    }

    &:last-of-type {
      border-top-right-radius: 11.3px;
      border-bottom-right-radius: 11.3px;
    }

    &.name {
      white-space: nowrap;
      width: 245px;
      font-family: GillSans;
      font-size: 12px;
      letter-spacing: 0px;
      color: #929292;
      text-align: left;
      padding-left: 15px;
    }
  }

  .head.body-content .tr:hover {
    background-color: #fff;
    .th {
      &.name {
        color: #2492cf;
      }
    }
    .td {
      &:nth-last-child(1) {
        color: #2492cf;
      }
    }
  }

  tbody > tr > td > span {
    display: block;
    float: left;
    height: 27px;
    border: solid;
    border-width: 1px 0 1px 0;
    cursor: pointer;

    &:first-of-type {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      border-left-width: 1px;
      border: 0;
    }

    &:last-of-type {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      border-right-width: 1px;
    }

    &.complied {
      border-color: $color-plot-complied;
      background-color: transparentize($color-plot-complied, 0.7);
    }

    &.missed {
      // border-color: $color-plot-missed;
      // background-color: transparentize($color-plot-missed, 0.7);
      width: 21px;
      height: 15px;
      border-radius: 11.3px;
      background-color: #f7bfcd;
    }
  }
  // td:first-of-type,
  th:first-of-type {
    width: auto;
    background-color: #fbfbfb;
    border-left-color: $color-border-base;
    text-align: left;
    padding-left: 16px;
    border: 0;
  }

  td:last-of-type,
  th:last-of-type {
    border-right-color: $color-border-base;
  }

  .adherence-percent {
    .low {
      color: #e71e5b;
      width: 100%;
      text-align: center;
      padding-bottom: 10px;

      &.day {
        border-bottom: 1px solid #dbdbdb;
        line-height: 1;
        padding: 0 0 10px 0;
      }
    }

    .high {
      text-align: center;
      color: #4ec02b;
      border-bottom: 1px solid #dbdbdb;
      line-height: 1;
      padding: 0 0 10px 0;
    }
  }

  .__react_component_tooltip {
    &.type-light {
      &::before {
        border: 0;
      }
    }
    &.adherence-percent {
      border-radius: 0;
      text-align: right;
      padding: 10px;
      border-radius: 10px;
      line-height: 2em;
      width: 88px;
      height: 80px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
      background-color: #ffffff;
      font-size: 12px;

      &.year {
        text-align: right;
        padding: 0px 20px;
        border-radius: 10px;
        line-height: 2em;
        font-size: 10px;
        width: 100px;
        height: 20px;
        text-align: center;
      }

      .content {
        text-align: center;
        line-height: 1;

        &.mt-10 {
          margin-top: 10px;
        }
      }
    }
  }
}
.averageText {
  width: 33px;
  display: inline-block;
}

.color {
  background-color: #fff;
}
