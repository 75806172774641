@import "../../style/variables";

$input-shadow: inset 0 1px 0px 0px rgba(181, 181, 181, 0.1);
$input-height: 2.7em;

@function to-em($pixel) {
  @return ($pixel/16) + em;
}

div.input-wrapper {
  position: relative;

  &.required {
    &::after {
      position: absolute;
      content: "*";
      color: $color-required-mark;
      top: 3px;
      left: 10px;
    }
  }
}

.special-nopad {
  &.form-container {
    padding: 0;
    overflow: visible;
    position: relative;
    width: 296px;
    margin: 0 auto;
  }
}
.two-columns-layout {
  .reset-password {
    form.reset-by-sms {
      width: 296px;
      margin: 35px auto;
    }

    div.special-nopad {
      &.input-wrapper {
        position: relative;
        padding: 0px 12px 1px 13px;
        &.required::after {
          top: 3px;
          left: 17px;
        }
      }
    }
  }
}

.dialog {
  .toggle {
    margin-top: 2px;
  }
}

.toggle {
  width: to-em(40);
  height: to-em(40);
  cursor: pointer;
  float: right;
  background-size: to-em(27) to-em(27);
  background: url("../../images/rx/countryDownArrow.svg") no-repeat center;
  margin-top: 6px;
  &.expand {
    background: url("../../images/rx/countryUpArrow.svg") no-repeat center;
  }
}

.delete-item {
  width: to-em(40);
  height: to-em(40);
  cursor: pointer;
  float: right;
  background-size: to-em(27) to-em(27);
  background: url("../../images/rx/close.svg") no-repeat center;

  &:hover {
    box-shadow: none;
  }
}

.selectedValue {
  display: block;
  width: 100%;
  line-height: to-em(40);
  border: 0;
  padding: 0 0 0 to-em(15);
  font-size: to-em(17);
  background: transparent;
  box-shadow: none;
  text-align: left;
  .code {
    margin-right: 10px;
    width: 60px;
    font-weight: 600;
  }

  &.hide {
    visibility: hidden;
    margin-bottom: to-em(15);
  }

  &.show {
    visibility: visible;
    margin-bottom: to-em(15);
  }
}

.tel-code {
  font-weight: 600;
  font-size: to-em(14);
  margin-right: 20px;
  width: 55px;
}

.country {
  font-size: to-em(17);
  letter-spacing: 0px;
  color: #bebebe;
}

.input-box {
  display: block;
  width: 100%;
  // border-radius: 13px;
  margin-bottom: to-em(15);
  line-height: to-em(50);
  border: 0;
  padding: 0 to-em(15);
  font-size: to-em(17);
  background: transparent;
  box-shadow: none;
}

.container {
  background: white;
  border-radius: 13.3px;
  // box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.06);
  // background-color: #ffffff;
  width: 100%;
  z-index: 1;
  padding: 0 10px;
  padding: 12px 12px 0 12px;
  //   margin-bottom: 4px;

  &.list-open {
    box-shadow: none;
  }
}

.open-container {
  position: absolute;
  z-index: 1;
  background: white;
  width: 100%;
  border-radius: 13.3px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  padding: 12px 12px 0 12px;
  overflow: hidden;
  padding: 0 10px 15px;
  margin-top: -5.2em;

  .mt-20 {
    margin-top: 12px;
  }
}

.option-box {
  height: 136px;
  background: white;
  float: left;
  width: 96%;
  z-index: 1;
  overflow-y: auto;
  cursor: pointer;
  .option {
    line-height: to-em(50);
    border: 0;
    padding: 0 to-em(15);
    font-size: to-em(17);
    text-align: left;
    margin-right: 10px;

    &:hover {
      background: #f7f7f7;
      border-radius: 13.3px;
    }

    .selected {
      float: right;
      width: 20px;
      background: url("../../images/rx/tick.png") no-repeat center;
      height: 20px;
      margin-top: 15px;
    }

    .code {
      margin-right: 10px;
      width: 60px;
      font-weight: 600;
      &.label {
        color: #4a4a4a;
        font-weight: 400;
      }
    }
  }

  &.open {
    display: block;
  }

  &.close {
    // position: absolute;
    display: none;
  }
}
