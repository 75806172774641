@import "./variables";

@mixin stripIcons($width, $height, $columns...) {
  @each $c in $columns {
    &.#{nth($c, 1)} {
      background-position-y: #{nth($c, 2) * $height};
    }
  }
}

table.table {
  & > tbody {
    & > tr {
      & > td.status {
        & > button {
          margin-left: 16px;
          opacity: 1;
        }

        & > span {
          vertical-align: middle;
          line-height: 26px;
          color: transparent;
          width: 26px;
          height: 26px;
          border-radius: 14px;
          display: inline-block;
          padding: 0;
          margin: 0;
          background-image: url("../images/rx/Status-Icon_stripX2_flat.png");
          background-size: 26px 156px;

          @include stripIcons(
            26px,
            26px,
            "taken" 6,
            "missed" 5,
            "ready" 4,
            "not-ready" 3,
            "skipped" 2
          );

     
        }

       
      }

      & > td.time-of-day {
        span {
          vertical-align: middle;
          line-height: 40px;
          color: transparent;
          width: 40px;
          height: 40px;
          display: inline-block;
          background-size: 40px 40px;
          padding: 0;
          margin: 0;
        }
      }
    }
    tr {
      &.morning > td.time-of-day span {
        background-image: url("../images/morning_unselected.png");
      }

      &.afternoon > td.time-of-day span {
        background-image: url("../images/afternoon_unselected.png");
      }

      &.evening > td.time-of-day span {
        background-image: url("../images/evening_unselected.png");
      }
      &.night > td.time-of-day span {
        background-image: url("../images/night_unselected.png");
      }
    }
    tr.selected {
      &.morning > td.time-of-day span {
        background-image: url("../images/morning_selected.png");
      }

      &.afternoon > td.time-of-day span {
        background-image: url("../images/afternoon_selected.png");
      }

      &.evening > td.time-of-day span {
        background-image: url("../images/evening_selected.png");
      }
      &.night > td.time-of-day span {
        background-image: url("../images/night_selected.png");
      }
    }
  }
}

table {
  & > tbody {
    .afternoon ~ .afternoon,
    .morning ~ .morning,
    .evening ~ .evening,
    .night ~ .night {
      & > td.time-of-day > span {
        background-image: none !important;
      }
    }
    /*tr.selected {
      > td {
        &:before {
          content: ' ';
          position: absolute;
          top: -2px;
          right: 0;
          bottom: 0;
          left: 0;
          border: 0 solid $color-background;
          border-top-width: 4px;

        }

        &:first-of-type:before {
          border-left-width: 4px;
          left: -18px;
        }
        &:last-of-type:before {
          border-right-width: 4px;
          right: -18px;
        }
      }

      & ~ .selected {
        > td:before {
          border-top: 0;
          border-bottom: 0;
        }
      }

    }
    & > .selected:last-of-type {
      > td {
        &:before {
          border-bottom-width: 4px;
          bottom: -2px;

        }
      }
    }
    & > .selected + :not(.selected) {
      > td {
        &:before {
          content: ' ';
          position: absolute;
          top: -2px;
          right: 0;
          bottom: 0;
          left: 0;
          border: 0 solid $color-background;
          border-top-width: 4px;

        }
        &:first-of-type:before {
          left: -18px;
        }
        &:last-of-type:before {
          right: -18px;
        }
      }
    }*/
  }
}
