/* plot colours */
@import "../../style/variables";

$color-plot-complied: #3cb943;
$color-plot-complied-secondary: #4ec02b;
$color-plot-missed: $color-brand-red;
$color-plot-skipped: #ffc000;
$color-plot-pending: $color-brand-light;

$color-plot-new-yellow: #69ade0;
$color-plot-new-lightblue: #d1d1d1;
$color-plot-new-red: #e71d5b;
$color-plot-new-green: #74b236;

$color-plot-dot: #3cb943;
$color-plot-timestamp-dot: #4ec02b;

.legend-bp {
  transform: translateX(-50%) translateY(-50%) rotate( -90deg);
  position: absolute;
  top: 50%;
  left: -14px;
    
}

.legend-pr {
  transform:  translateX(-50%) translateY(-50%) rotate(90deg);
  position: absolute;
  right: -102px;
  top: 50%;
}

.area.bottom {
  fill: blue;
}

.area.bottom {
  fill: pink;
}

.event-graph {
  div.box {
    svg.plot {
      height:65vh;
    }  
  }
}

div.box {
  margin: 30px 32px;
  // height: 300px;
  // height: auto;
  // min-height: 235px;
  // margin-top: 0;
  // padding: 30px;
  // margin: 0;

  > header {
    // position: relative;
    // font-size: $font-size-base;
    // color: $font-color-base;
    // font-weight: $font-weight-semibold;
    // display: block;
    // width: auto;
    // border-bottom: 1px $color-border dashed;
    // height: 56px;
    // vertical-align: middle;
    // margin: 0 -26px 34px;
    // line-height: 56px;
    text-align: center;
    .float-right {
      float: right;
    }

    span[role="button"] {
      height: 28px;
      line-height: 28px;
      margin: 0 8px;
      width: auto;
    }

    // .spinner {
    //   position: absolute;
    //   top: 15px;
    //   right: 0;
    //   width: 24px;
    //   height: 24px;
    //   background-size: 24px 24px;
    // }
  }


  svg.plot {
    height: 235px;
    width: 100%;
    g.grid g.tick line {
      stroke: #f0f0f0;
      stroke-width: 0.5;
    }

    g.series,
    path {
      rect {
        transition: fill $animation-swift-out, stroke $animation-swift-out;
      }
      &.complied {
        fill: transparentize($color-plot-complied-secondary, $transparency);
        //stroke: transparentize($color-plot-complied-secondary, $transparency);
      }

      &.skipped {
        fill: transparentize($color-plot-skipped, $transparency);
        //stroke: transparentize($color-plot-skipped, $transparency);
      }

      &.missed {
        fill: transparentize($color-plot-missed, $transparency2);
        //stroke: transparentize($color-plot-missed, $transparency);
      }

      &.pending {
        fill: transparentize($color-brand-light, $transparency);
        //stroke: transparentize($color-brand-light, $transparency);
      }

      /* @todo: use more convenient naming */

      &.invite-accepted {
        fill: transparentize($color-brand-light, $transparency);
      }

      &.invite-pending {
        fill: transparentize($color-plot-skipped, $transparency);
      }

      &.new-red {
        fill: $color-plot-new-red;
      }

      &.new-transparent {
        fill: transparent;
      }

      &.new-gray {
        fill: #dedede;
      }

      &.new-green {
        fill: $color-plot-new-green;
      }

      &.new-yellow {
        fill: $color-plot-new-yellow;
      }

      &.new-lightblue {
        fill: $color-plot-new-lightblue;
      }
    }

    path.line {
      &.complied {
        stroke: transparentize($color-plot-complied-secondary, $transparency);
      }

      &.skipped {
        //fill: transparentize($color-plot-skipped, $transparency);
        stroke: transparentize($color-plot-skipped, $transparency);
      }

      &.missed {
        //fill: transparentize($color-plot-missed, $transparency2);
        stroke: transparentize($color-plot-missed, $transparency);
      }

      &.pending {
        //fill: transparentize($color-brand-light, $transparency);
        stroke: transparentize($color-brand-light, $transparency);
      }
    }

    g.axis {
      font-size: $font-size-base;

      path.domain,
      line {
        stroke: #cecece;
        stroke-width: 0.5;
      }
      .tick > text {
        fill: #4f4f4f;
        font-size: 12px;
      }

      &.no-domain {
        path.domain {
          stroke: transparent;
        }
      }

      &.middle {
        g.tick text {
          fill: #4f4f4f;
        }
      }
    }
  }

  @import "PiePlot/style";
  @import "TimeDeltaPlot/style";
  @import "HistogramPlot/style";
  @import "TimeStampPlot/style";
}

.plot-row {
  select {
    font-family: GillSans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    text-align: left;
    background: transparent;
    color: #4f4f4f;
    border: 0;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: url("../images/rx/medicationSelect.svg") no-repeat;
    max-width: 130px;
    background-position: left;
    padding-left: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-position: left;
    padding-left: 30px;
  }
}

div.tooltip {
  position: absolute;
  text-align: center;
  width: 60px;
  height: 28px;
  padding: 2px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}

.title-graph {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  color: #000000;
}
.graph-box {
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  margin-top: 20px;
  border-radius: 20px;
  position: relative;
}

.plot-row.timestamp-row {
  margin: 0;
  margin-top: 20px;

  .column.full-width {
    position: relative;
    margin: 0;

    

    .adherence-time-plot.box {
      margin: 0;
      position: relative;
      overflow: hidden;
      height: 460px;

      .spinner {
        position: absolute;
        top: 18px;
        left: 8px;
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
      }
    }
  }
}

.geo-map-row {
  .box {
    height: auto;
    margin: 0;

    .map-frame {
      margin: 0 -28px;
    }
  }

  [role="button"] {
    height: auto;
    border-radius: 0;
    border: none;
    line-height: normal;
  }
}
