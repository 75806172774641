@import '../../style/variables';

:root {
  --action-widget-bg-gray-color: #DADADA;
  --action-widget-accent-color: var($--secondary-theme-color);
  --action-button-bg-color: #F3F3F3;
}

.button-before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 38px;
  height: 38px;
  background-color: var($--main-theme-color);
}

.action-flightmode::before {
  @extend .button-before;
  -webkit-mask: url("../../images/actions/flight_mode.svg") no-repeat center/contain;
  mask: url("../../images/actions/flight_mode.svg") no-repeat center/contain;
}

.action-reminder::before {
  @extend .button-before;
  -webkit-mask: url("../../images/actions/reminder.svg") no-repeat center/contain;
  mask: url("../../images/actions/reminder.svg") no-repeat center/contain;
}

.action-led::before {
  @extend .button-before;
  -webkit-mask: url("../../images/actions/led.svg") no-repeat center/contain;
  mask: url("../../images/actions/led.svg") no-repeat center/contain;
}

.action-download::before {
  @extend .button-before;
  -webkit-mask: url("../../images/actions/action_download.svg") no-repeat center/contain;
  mask: url("../../images/actions/action_download.svg") no-repeat center/contain;
}

.action-pinset::before {
  @extend .button-before;
  -webkit-mask: url("../../images/actions/pin_set.svg") no-repeat center/contain;
  mask: url("../../images/actions/pin_set.svg") no-repeat center/contain;
}

.action-delete::before {
  @extend .button-before;
  background-color: $color-error;
  -webkit-mask: url("../../images/closeIcon.svg") no-repeat center/contain;
  mask: url("../../images/closeIcon.svg") no-repeat center/contain;
}

.action-enroll::before {
  @extend .button-before;
  -webkit-mask: url("../../images/actions/enroll.svg") no-repeat center/contain;
  mask: url("../../images/actions/enroll.svg") no-repeat center/contain;
}

.action-archive::before {
  @extend .button-before;
  -webkit-mask: url("../../images/actions/archive.svg") no-repeat center/contain;
  mask: url("../../images/actions/archive.svg") no-repeat center/contain;
}

.action-unread::before {
  @extend .button-before;
  -webkit-mask: url("../../images/actions/inbox.svg") no-repeat center/contain;
  mask: url("../../images/actions/inbox.svg") no-repeat center/contain;
}

.action-pairingmode::before {
  @extend .button-before;
  -webkit-mask: url("../../images/actions/pairing_mode.svg") no-repeat center/85%;
  mask: url("../../images/actions/pairing_mode.svg") no-repeat center/85%;
}

.action-sleepmode::before {
  @extend .button-before;
  -webkit-mask: url("../../images/actions/sleep_mode.svg") no-repeat center/75%;
  mask: url("../../images/actions/sleep_mode.svg") no-repeat center/75%;
}

.action-edit::before {
  @extend .button-before;
  -webkit-mask: url("../../images/rx/edit.svg") no-repeat center/55%;
  mask: url("../../images/rx/edit.svg") no-repeat center/55%;
}

.action-reset::before {
  @extend .button-before;
  -webkit-mask: url("../../images/rx/refresh.svg") no-repeat center/55%;
  mask: url("../../images/rx/refresh.svg") no-repeat center/55%;
}

.action-dropdown-icon-container {
  width: 39px;
  height: 39px;

  border-width: 2px;
  border-color: var(--action-button-bg-color);
  border-style: solid;
  border-radius: 100%;

  background-color: var(--action-button-bg-color);
  background-position: center;
  background-size: 100%;
}

.action-button {
  cursor: pointer;

  width: 38px;
  height: 38px;

  transform: translate(-1px, -1px);
}

.action-dropdown-icon-container:hover {
  border-color: var(--action-widget-accent-color);
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  filter: grayscale(100%);
  opacity: .5;
}

.disabled:hover {
  opacity: 1;
}

.action-widget {
  display: flex;
  column-gap: 10px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  border: 1px solid var(--action-widget-bg-gray-color);
  padding: 15px;
  border-radius: 100px;
  box-shadow: 2px 5px 10px var(--action-widget-bg-gray-color);
  background-color: white;
  z-index: 3;
  width: -moz-fit-content;
  width: fit-content;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 10;
  width: 120px;
  bottom: 180%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.cell-with-select {
  display: flex;
  flex-direction: row;
  
  align-items: center;
}

.selector-label {
  margin-left: 10px;
}

.selector {
  align-items: center;
}

.item-checkbox {
  margin-bottom: 0;
}

.action-dropdown-on-off-menu {
  --action-dropdown-on-off-menu-height: 120px;
  --action-dropdown-on-off-menu-width: 250px;

  width: var(--action-dropdown-on-off-menu-width);
  // height: var(--action-dropdown-on-off-menu-height);
  
  background-color: white;
  
  border: 1px solid var(--action-widget-bg-gray-color);
  border-radius: 10px;
  box-shadow: 2px 5px 10px var(--action-widget-bg-gray-color);
  
  position: absolute;
  transform: translate(-85%, -115%);
  
  z-index: 3;
  display: flex;
  flex-direction: column;
}

.action-dropdown-menu-label-radio {
    display: flex;
    vertical-align: middle;
    flex-direction: row;
    padding: 5px;
    justify-content: flex-end;
    border-radius: 10px;
    margin: 5px;
}

.action-dropdown-menu-label-radio:hover {
    background-color: #ecf3f8;
}

.action-dropdown-menu-label {
    font-size: 20px;
    display: flex;
    padding: 5px;
    align-items: center;
    width: 100%;
}

.action-dropdown-menu-checkbox-unchecked {
    background: url("../../images/radiobutton/unchecked.png") no-repeat;
    background-position: center;
    width: 38px;
    height: 38px;
    display: flex;
    padding: 5px;
}

.action-dropdown-menu-checkbox-checked {
    background: url("../../images/radiobutton/checked.png") no-repeat;
    background-position: center;
    width: 38px;
    height: 38px;
    display: flex;
    padding: 5px;
}
