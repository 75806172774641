@import '../../style/variables';

.stepProgress {
    position: absolute;
    height: 10px;
    width: 80%;
    z-index: 9999;
    right: 0;
    left: 0;
    top: -5px;
    margin: 0 auto;
    z-index: 999999;
}

.item-container {
    height: 310px;
    padding: 20px 0;
}

.devices-list-container {
    height: 250px;
    margin-top: 20px;
    margin-right: -15px;
    padding-right: 15px;
}

.modal-content {
    border-top: solid 1px #d9d9d9;

    &.add-medicine-modal {
        width: 500px;

        .inner-box {
            overflow: hidden;
            overflow-y: auto;
            background-color: #fbfbfb;
            border-top: solid 1px #d9d9d9;
            height: unset;
        }

        .auto-spinner-conatiner {
            position: relative;
            margin-bottom: 20px;

            .auto-spinner {
                position: absolute;
                right: 0;
                top: 26px;
                right: -11px;
            }
        }

        select {
            border-radius: 13px;
            height: 40px;
            background-color: #F7F7F7;
            width: auto;
        }
    }
}

.modal-content {
    border-top: solid 1px #d9d9d9;

    .inner-box {
        padding: 60px;
        height: 71vh;

        .item-container {
            height: 46vh;
            margin-bottom: 50px;
        }

        .item-container2 {
            position: relative;
            height: 59vh;
            display: flex;
            flex-direction: column;
        }
    }
}

.item-container2 {
    .review-options {
        padding: 45px;
        height: 46vh;
        margin-bottom: auto;

        div {
            display: flex;
        }
    }

    .button-bar {
        display: flex;
        justify-content: space-between;
        margin-top: 85px;
        margin-top: auto;
        padding: 0 40px 40px;

        & button {
            width: 100%;
            margin-left: 5px;
        }
    }

    .review-options .header-label {
        display: block;
        // width: 100px;
        font-size: 18px;
        color: #000;
    }

    label {
        width: 50%;
        font-size: 18px;
        color: #000;
        word-wrap: break-word;
    }
}

.device-label {
    font-size: 27px;
    font-weight: 600;
    color: var($--main-theme-color);
    margin-bottom: 15px;
}

hr {
    border: solid 1px #979797;
    margin-bottom: 29px;
    opacity: 0.44;
}