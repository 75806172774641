@import "../../style/variables";

$input-shadow: inset 0 1px 0px 0px rgba(181, 181, 181, 0.1);
$input-height: 2.7em;

@function to-em($pixel) {
  @return ($pixel/16) + em;
}

.react-phone-number-input__icon--international {
  display: none;
}

.phone-error {
  color: $color-brand-red;
}

.dialog-overlay {
  z-index: 100;
  div {
    &.dialog {
      form {
        .react-phone-number-input {
          select,
          input {
            box-sizing: border-box;
            width: 100%;
            height: 50px;
            line-height: 50px;
            border: 1px solid $color-border-input;
            border-radius: 0;
            background: $color-background-light;
            color: $font-color-placeholder;
            margin-bottom: 40px;
            padding: 0 45px;

            &.react-phone-number-input__country-select {
              background: $color-background-light
                url("../../images/small-arrow-bottom.svg") no-repeat right
                to-em(18) center;
              background-size: to-em(12) to-em(8);
            }
          }

          input {
            padding: 0 25px;
          }

          ::placeholder {
            color: $font-color-placeholder;
            opacity: 1;
          }

          ::-webkit-input-placeholder {
            color: $font-color-placeholder;
            opacity: 1;
          }

          ::-moz-placeholder {
            color: $font-color-placeholder;
            opacity: 1;
          }

          :-ms-input-placeholder {
            color: $font-color-placeholder;
            opacity: 1;
          }
          :-moz-placeholder {
            color: $font-color-placeholder;
            opacity: 1;
          }
        }

        input {
          &.react-phone-number-input__input {
            border: 1px solid $color-border-input;
          }
        }
      }
    }
  }
}

.user-profile {
  .fields {
    .field {
      select {
        &.react-phone-number-input__country-select {
          box-sizing: border-box;
          width: 340px;
          padding: 0 42px;
          color: #b0b0b0;
          height: 34px;
          border-radius: 9.3px;
          border: 0;
          box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
          background-color: #f0f0f0;
        }
      }

      .react-phone-number-input__input {
        height: 50px;
        line-height: 50px;
        margin-bottom: 0;
        box-sizing: border-box;
        width: 340px;
        padding: 0 25px;
        color: #b0b0b0;
        height: 34px;
        border-radius: 9.3px;
        border: 0;
        margin: 0;
        box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: #f0f0f0;
      }
    }
  }
}

.react-phone-number-input__input {
  display: block;
  width: 100%;
  border-radius: 13px;
  margin-bottom: to-em(15);
  line-height: to-em(50);
  border: 0;
  padding: 0 to-em(15);
  font-size: to-em(17);
  background: transparent;
  box-shadow: none;

  &::placeholder {
    color: #b0b0b0;
  }

  &::-webkit-input-placeholder {
    color: #b0b0b0;
  }

  &::-moz-placeholder {
    color: #b0b0b0;
  }

  &:-ms-input-placeholder {
    color: #b0b0b0;
  }
  &:-moz-placeholder {
    color: #b0b0b0;
  }

  @include input-focus-animation(to-em(10));
}

.react-phone-number-input__icon {
  position: relative;
}

.react-phone-number-input__icon-image {
  width: 25px;
  display: inline-block;
  position: absolute;
  left: 11px;
  top: 8px;
}

.react-phone-number-input__country-select {
  text-overflow: clip;
  margin: 0;
  display: inline-block;
  background: $color-background-light;
  margin-bottom: 20px;
  width: 100%;
  background: $color-background-light url("../../images/small-arrow-bottom.svg")
    no-repeat right to-em(18) center;
  background-size: to-em(12) to-em(8);
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 3.125em;
  line-height: 3.125em;
  border: 1px solid $color-border-input;
  padding: 0 45px;
}

select {
  &.react-phone-number-input__country-select {
    &::-ms-expand {
      display: none;
    }
  }
}
