
.select-timezone {
    position: relative;

    .select-image {
        width: 83%;
        height: 45px;
        margin-left: 5px;
    }

    select {
        position: absolute;
        font-family: GillSans;
        font-size: 18px;
        overflow: hidden;
        padding-left: 30px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        border: 0;
        background: transparent;
        background: url("../../../../images/rx/medicationSelect.svg") no-repeat;
        background-position: left;
        background-size: 43px;
        width: 100%;
        appearance: none;
    
        option {
            font-size: 18px;
        }
    
        &.right {
            padding: 0 55px 0 30px;
            background-position: right;
            height: 44px;
            left: 0;
            text-align: center;
            color: #14568d;
            text-align: center;
            text-align-last: center;
            padding-left: 5px;
        }
    }
}

.play-video {
    background: url("../../../../images/rx/play.svg") no-repeat;
    width: 27px;
    height: 20px;
    position: absolute;
    margin-left: -18px;
    position: absolute;
    top: 50%;
    height: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.video-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    z-index: 1000;
    top: 0;
}

.close-btn {
    cursor: pointer;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    z-index: 1001;
    background: url("../../../../images/rx/close.svg") center center no-repeat;
    background-size: 100% 100%;
}

.iconTick {
    float: right;
    vertical-align: middle;
    margin-right: 8px;
    width: 28px;
}

.errors-block-cap {
color:#d73b64;
}

