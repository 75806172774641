@import "../../style/_variables";
$new-scale-koef: 0.75;
$scale-koef: 0.75;
// $scale-koef: 0.875;
$font-color: #4f4f4f;
$font-secondary-color: #272727;
$active-color: #b1d590;

.modal-content form {

  .download-medications{

    &.content-block {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      row-gap: 20px;
      padding: 0;
      margin-bottom: 20px;

      #totalPills {
        margin:0;
      }
    }

      
    .buttons {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      margin-top: auto;    
      button {
        flex-grow: 1;
      }
    }

    .dose-remain-box{
      padding: 17px 15px 15px 24px;
      object-fit: contain;
      border-radius: 12px;
      border: solid 1px #d9d9d9;
      background-color: #ffffff;
      display: flex;

      .big-label {
        font-size: 20px;
        color: #4f4f4f;
      }
      
      .light-label {
        color: #929292;
      }

      .blue-label {
        color: #2492cf;
      }
      .dose-count {
        width: 125px;
    height: 64px;
    border-radius: 8px;
    background-color: #e6819f;
    margin-right: 20px;
    line-height: 64px;
    font-size: 27px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    text-align: center;


      &.high {
        background: #92DA7B;
      }
  
      &.medium {
        background: #EAAC6C;
      }
  
      &.low {
        background: #E6819F;
      }

      &.inactive {
        color: #dfdfdf;
       
        &.block {
          background-color: #dfdfdf;
          color: white;
        }
       }
       
      }
    }

    .row {
      &.title {
        display: flex;
        align-items: center;

        span {
          font-size: 18px;
          color: #4f4f4f;
        }

        .input-content {
          margin-left: auto;
          height:42px;
          position: relative;
          margin-right: 30px;
        }

        input {
          border-radius: 13px;
          box-shadow: none;
          border: solid 1px #d9d9d9;
          background-color: #f7f7f7;
          max-width: 70px;
          text-align: center;
          padding: 0;
          color: #4a4a4a;
          height: 40px;
          
        }

        input[type="checkbox"]{
          opacity:0;
          height: 27px;
          width: 27px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          margin-top:10px;
          cursor: pointer;
        }

        input[type="checkbox"] + label{
          background: url("../../images/rx/uncheckedBox.svg") no-repeat right;
          height: 27px;
          width: 27px;
          display:inline-block;
          padding: 0 0 0 0px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          margin-top:10px;
      }

        input[type="checkbox"]:checked + label{
          background:url('../../images/rx/checkedBox.svg') no-repeat;
          height: 27px;
          width: 27px;
          display:inline-block;
          padding: 0 0 0 0px;
          margin-top:10px;
         
        }

        #totalPills {     
          min-width: 121px;
          margin-right: 2px;
        }

        #addPills {          
        }

        button {
          text-align: center;
          min-width: auto;
          max-width: 70px;
          height: 38px;
          margin-top: -6px;
          margin-right: 0;
          }
      }
    }
          
  } 
}