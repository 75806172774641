.reset-enrollment-and-unattach-devices {
  .signal-alert {
    margin-left: unset;
    -webkit-mask-size: 100%;
    mask-size: 100%;
    min-width: 20px;
  }
  .row {
    align-items: center;
  }
  .reminders-row:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.5;
  }
}