/* -----------------------------------------------------------------------------

  CARBON AD COMPONENT
  
----------------------------------------------------------------------------- */

#carbonads {
  position: fixed;
  bottom: 12px; right: 12px;
  z-index: 1000;
  padding: 24px 12px 12px 12px;
  width: 154px;
  background-color: #fff;
  line-height: 1.1;
  border: solid 1px $color--neutral-xxlight;
}

.carbon-wrap {
  display: block;
  margin: 0 0 4px 0;
}

.carbon-img {
  display: block;
  margin: 0 0 4px 0;
  padding: 0;
  width: 130px;
  height: 100px;
}

.carbon-text {
  color: rgb(129,129,129);
  font-size: 12px;
}

.carbon-poweredby {
  font-size: 10px;
  font-style: italic;
}

.carbonad__close {
  display: block;
  position: absolute;
  top: 0;
  left: 12px;
  height: 24px;
  font-size: 11px;
  line-height: 24px;
}

@media all and ( max-width: 660px ) {
  
  #carbonads {
    display: none;
  }

}