@import "../../style/variables";

.slider-container {
  flex: 419px 1 0;
  .slider {
    height: 30px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    > .background {
      flex: auto 1 1;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      position: relative;
      height: 8px;
      border-radius: 4px;
      box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.13);
      background-color: #f7f7f7;

      .months {
        position: absolute;
        top: 18px;
        width: calc(100% - 128px);

        .month {
          display: none;
          position: absolute;
          color: $color-border-dark;
          width: 40px;
          line-height: 25px;
          height: 25px;
          text-align: center;

          &::before {
            display: block;
            content: " ";

            width: 2px;
            height: 6px;
            background: $color-border-base;

            position: absolute;
            top: -1px;
            left: 19px;
          }

          @media (max-width: 1280px) {
            &:nth-child(2n) {
              display: none;
            }
          }

          @media (max-width: 1080px) {
            &:nth-child(4n),
            &:nth-child(4n + 3) {
              display: none;
            }
          }
        }
      }

      .line {
        height: 8px;
        transition: width $animation-swift-out;

        &.red {
          background-color: $color-brand-red;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
          box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.13);
        }
        &.gray {
          background-color: $color-brand-gray;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
          box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.13);
        }
        &.green {
          background: var($--slider-color);
          display: flex;
          justify-content: space-between;
          box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.13);
        }

        &:first-child {
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }
        &:last-child {
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }

      .tick {
        position: relative;
        flex: auto 0 0;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
        background-color: #ffffff;
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }
    }
  }
}
