@import "../../../style/variables";

.draft-msg {
    width: 40%;
    padding: 36px 20px;
}

.brand-blue.right {
float: right;
}

.success-msg {
    padding: 10px 20px;
    border: 10px;
    background: #4ab3eb;
    color: white;
    border-radius: 15px;
}

.error-msg {    
    padding: 10px 20px;
    border: 10px;
    background: #ee173b;
    color: white;
    border-radius: 15px;
}

.message-text-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #D9D9D9;
    font-size: 15.75px;
    color: #4f4f4f;
    background: #F7F7F7;
    margin-bottom: 5px;
    box-shadow: inset 0 2px 5px 0 rgb(0 0 0 / 10%);
    border-radius: 10px;
    &::placeholder {
        color: #b0b0b0;
      }
}

.text-limit {
    text-align: right;
    margin: 0;
}

.pb20 {
    padding-bottom: 20px;
}

.page-header {
    .right {
        button {
            &.refresh-button {
                width: 42px !important;
                height: 42px !important;
                padding: 0 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 13px;
                border: solid 1px #bababa;
                min-width: 46px;
            }

            &.refresh-button img {
                width: 26px;
                height: 26px;
            }
        }
    }
}

.rowDirection {
    align-items: center;
    button {
        &.refresh-button {
            width: 42px !important;
            height: 42px !important;
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 13px;
            border: solid 1px #bababa;
            min-width: 46px;
            background: #fff;
        }

        &.refresh-button img {
            width: 26px;
            height: 26px;
        }

        &.refresh-button2 {
            width: 46px;
            min-width: 46px;
            height: 46px;
            padding: 0 !important;
            background: url("../../../images/refresh2.svg") no-repeat;
        }
    }
}

.unread-count {
    color: #000;
}

table.table {
    &.message-table {
        tbody {
            tr.table-row.unread {
                td.column {
                    font-weight: bold;
                }
            }
        }
    }
}

table.table {
    &.message-table {
        tbody {
            tr.table-row {
                td.column {
                    &.edit-messages {
                        padding: 9px 17px;
                    }
                }
            }
        }
    }
}

.message-container {
    .infoHeader {
        background-color: #fff;
        .feildValue {
            padding-left: 0;
            font-size: 23px;
            color: #000;

            .user-name {
                font-size: 23px;
                color: #4f4f4f;
                padding-left: 15px;
            }
        }
    }
    .page-header {
        .right {
            font-size: 18px * $scale-koef;
        }
    }
}

hr {
    &.vhr {
        width: calc(100% - 52px);
        margin: 0 20px 26px;
        border: 1px solid #d9d9d9;
    }
}

.date-container {
    display: flex;
    align-items: center;

    .date-box {
        padding: 9px 20px 10px;
        border-radius: 20px;
        border: solid 1px #efefef;
        background-color: #f5f5f5;
        width: 230px;
        font-size: 18px;
        color: #4f4f4f;

        &.auto-width {
            width: auto;
        }

        &.date-header {
            margin-left: 6px;
        }
    }

    .v-line {
        flex: 1;
        width: 100%;
        border-bottom: solid 1px #d9d9d9;
    }
}

.charge-custom-tooltip {
    display: none;
}

.chat-box {
    border-radius: 13px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 60px * $scale-koef auto 60px * $scale-koef 60px * $scale-koef;
    align-items: flex-start;
    column-gap: 23px * $scale-koef;
    row-gap: 8px * $scale-koef;

    .image-box {
        grid-column-start: 1;
        grid-row-start: 2;

        .user-image {
            width: 60px * $scale-koef;
            height: 60px * $scale-koef;
            filter: invert(22%) sepia(77%) saturate(7347%) hue-rotate(194deg) brightness(90%) contrast(106%);
        }
        .avatar {
            width: 60px * $scale-koef;
            height: 60px * $scale-koef;
            font-size: 22.5px * $scale-koef;
            text-align: center;
            line-height: 60px * $scale-koef;
            color: var($--main-theme-color);
            border: 1px solid #D9D9D9;
            border-radius: 30px;
        }
    }

    .date-title {
        font-size: 14px;
        color: #4f4f4f;
        float: left;
    }
    .message-with-button {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 15px;
        align-items: center;
        justify-content: start;
        grid-column-start: 2;
        grid-row-start: 2;
        min-width: 0;
        max-width: 100%;
        position: relative;
    }
    .message {
        font-size: 18px;
        color: #000;
        padding: 14px * $scale-koef 23px * $scale-koef;
        background-color: #F5F5F5;
        border: 1px solid #EFEFEF;
        border-radius: 10px;
        width: fit-content;
        white-space: pre-line;
        word-wrap: break-word;
        min-width: 0;

        span {
            font-size: 18px;
            color: #000;
        }
    }

    .message-time {
        grid-column-start: 2;
        grid-row-start: 1;
        font-size: 16px * $scale-koef;
        color: #505050;
    }

    &.reversed {
        justify-items: end;
        grid-template-columns: 60px * $scale-koef 60px * $scale-koef auto 60px * $scale-koef;
        .message-with-button {
            justify-content: end;
            grid-column-start: 3;
            grid-row-start: 2;
        }
        .image-box {
            grid-column-start: 4;
            grid-row-start: 2;
        }
        .message {
            background-color: var($--opaque-main-color-7);
            border: unset;
        }
        .message-time {
            grid-column-start: 3;
            grid-row-start: 1;
        }
        .add-prefab-button {
            position: absolute;
            left: -55px;
            display: none;
            height: 46px * $scale-koef;
            width: 46px * $scale-koef;
            min-width: unset;
            top: 0;
            bottom: 0;
            margin: auto 0;
            padding: 0;
        }
    }
    &:hover {
        .add-prefab-button {
            display: block;
        }
    }
}

.app-root .app-main .app-content {
    .message-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        &.with-header {
            height: 100%;    
        }
        .details-container {
            position: relative;
            // height: calc(100% - 91px);
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 40px * $scale-koef;
            .messages {
                display: flex;
                flex-direction: column;
                row-gap: 34px * $scale-koef;
            }
        }

        .sub-header {
            padding: 20px;

            .date-container {
                display: inline-block;
            }

            .search {
                width: 365px;
                height: 46px;
                margin: 0 0 0 20px;
                padding: 12px 127px 13px 22px;
                border-radius: 13px;
                box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
                border: solid 1px #bababa;
                background-color: #f7f7f7;
                color: #000;
                &::placeholder{
                    color: #000;
                }
            }
            &.flex {
                display: flex;
            }
        }
    }
}
.unread-message {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: crimson;
    display: inline-block;
}

.message-patient-container {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: row;

    .flex-left {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        border-right: 1px solid #d8d8d8;
        .flex1 {
            flex: 1 1 auto;
            min-height: 0;
        }
        .details-container {
            height: 100%;
        }
        .bottom-box {
            flex: 0 1 auto;
            margin-right: 40px * $scale-koef;
            margin-bottom: 27px * $scale-koef;
            .send-button {
                position: absolute;
                bottom: 57px;
                right: 39px;
                width: fit-content;
                min-width: fit-content;
                height: 46px* $scale-koef;
            }
            textarea {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.11);
            }
        }
    }
    .flex-right {
        width: 40%;
        height: 100%;
        overflow: auto;
        margin-right: -$margin-main;
        position: relative;
        right: -$margin-main;
        padding-right: $margin-main;

        .panels {
            margin-top: 0;

            .panel {
                border: 0;
                background-color: #fff;
                border-radius: 0;

                .title {
                    background: linear-gradient(
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 0) 96%,
                        rgba(192, 192, 192, 0.8) 100%
                    );
                    padding-left: 40px;
                    padding-top: 0;
                    font-size: 20px;
                }
            }

            .panel-content {
                max-height: unset;
                margin: 0;
                padding: 0;
                position: relative;

                .center-line {
                    display: none;
                }

                .rowContent {
                    margin: 0;
                    padding: 15px 40px;
                    min-height: 50px;
                }

                .rowContent:nth-child(even) {
                    background: #f5f5f5;
                }
            }

            .row {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                & > * {
                    margin-right: 0px;
                    width: 100%;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .simplebar-track.simplebar-vertical {
        right: 8px;
    }
}

.PrefabReplies {
    .full_txt {
        max-width: 50vw;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

button.prefab-button {
    position: absolute;
    bottom: 57px;
    right: 120px;
    height: 46px* $scale-koef;
    width: 46px* $scale-koef;
    min-width: 46px* $scale-koef;
    padding: 0;
    background-color: #fff;
    color: var($--main-theme-color);
    border: 1px solid #BABABA;
    &.clicked,
    &:hover {
        color: #fff;
        background-color: var($--main-theme-color);
        border: none;
    }
}

.prefab-window {
    position: absolute;
    transform: translateX(100%);
    bottom: 80px;
    right: 165px;
    z-index: 1;
    max-width: 30vw;
    background-color: #fff;
    border: #bababa solid 1px;
    border-radius: 16px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
    .page-header {
        padding-right: 27px * $scale-koef;
        padding-left: 27px * $scale-koef;
        .plus-button {
            background-color: var($--main-theme-color);
            float: left;
            height: 46px* $scale-koef;
            width: 46px* $scale-koef;
        }
        .button-container {
            height: 46px* $scale-koef;
            width: 46px* $scale-koef;
            background-color: #fff;
            border: 1px solid #D9D9D9;
            border-radius: 13px;
          }
    }

    .tab-buttons-row {
        margin-left: unset;
        margin-right: unset;
        padding: 20px * $scale-koef 27px * $scale-koef;
    }
    .message-textbox__suggestions {
        border: none;
        padding-top: 20px;
        // padding-bottom: 15px;
        .scrollarea-fade-in-out {
            position: absolute;
            width: 100%;
            height: 15px;
            z-index: 1;
            content: "";
          }
        .simplebar-mask {
            &:before {
              @extend .scrollarea-fade-in-out;
              background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 15px);
              top: 0;
            }
            &:after {
              @extend .scrollarea-fade-in-out;
              background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 15px);
              bottom: 0;
            }
          }
        .suggestions-scrolled-container {
            width: unset;
            max-width: unset;
            padding-right: 27px * $scale-koef;
            padding-left: 27px * $scale-koef;
            margin-top: 27px * $scale-koef;
            margin-bottom: 27px * $scale-koef;
            .message-textbox__suggestions__item {
                border: #D9D9D9 solid 1px;
                .tag-suggestion {
                    .tag-suggestion-header {
                        display: flex;
                        justify-content: space-between;
                        .tag-suggestion-header-shortcut {
                            color: var($--main-theme-color);
                        }
                    }
                }
            }

        }
        input {
            margin-right: 27px * $scale-koef;
            margin-left: 27px * $scale-koef;
            width: calc(100% - 27px * $scale-koef * 2);
            box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.22);
            border: solid 1px #bababa;
            background-color: #F7F7F7;
        }
    }
}

.tab-buttons-row {
    background-color: #F7F7F7;
    padding: 20px * $scale-koef $margin-main;
    display: flex;
    flex-direction: row;
    column-gap: 20px * $scale-koef;
    margin-left: -$margin-main;
    margin-right: -$margin-main;
    margin-right: -32px;
    .tabButton.tab-label {
        background-color: unset;
        color: #000;
        margin: 0;
        height: 2em;
        padding: 7px 20px;
        margin: 0;
        height: unset;
        line-height: unset;
        border-radius: 18px;
        &.active-tab-label {
            color: #fff;
            background-color: var($--main-theme-color);
        }
        &:hover {
            box-shadow: none;
        }
    }
}

.chars-remaining {
    &.low {
        color: #F9A353;
    }
}
.chars-overflow {
    color: $color-error;
}