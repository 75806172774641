.wizard.onboard-patient {
  .scroll-content {
    padding-left: 42px;
  }
  .stage-title {
    margin-left: -32px;
  }
  .page-info-chip {
    margin-left: -32px;
    margin-top: 15px;
  }
}