$new-scale-koef: 0.75;
$scale-koef: 0.75;
$font-color: #4F4F4F;
$font-secondary-color: #272727;
$active-color: #B1D590;

.infoBox {
  font-size: 24px * $scale-koef;
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 28px * $scale-koef 50px * $scale-koef;
  color: $font-color;
  border: 1px solid #D9D9D9;
  border-radius: 20px * $scale-koef;
  background-color: $color-background-shaded;

  .total-bubble {
    margin: 0 20px 0 0;
    padding: 7px 15px;
    color: #000;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    border-radius: 25px * $scale-koef;
    // background-color: #B1D590;
    border: 2px solid #74b236;

    &.poor {
      background-color: $color-poor;
    }

    &.average {
      background-color: $color-average;
    }

    &.good {
      background-color: $color-good;

    }
  }

  .nickName {
    display: inline;
    color: $font-color;
  }

  .vl {
    height: 36px;
    margin: 0 25px;
    border-left: 2px solid #DDDDDD;
  }

  span {
    font-size: 16px;
    color: $font-color;
  }

  .title {
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: center;
    color: $color-background-darkest;
  }

  .legend {
    margin-left: auto;
    color: $font-color;

    div {
      position: relative;

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        content: "";
      }

      &:before {
        left: 120px * $scale-koef;
        width: 36px * $scale-koef;
        height: 36px * $scale-koef;
        margin-top: -18px * $scale-koef;
        border: 1px solid #DADADA;
        border-radius: 9px;
        background: white;
      }

      &:after {
        left: 130px * $scale-koef;
        width: 16px * $scale-koef;
        height: 16px * $scale-koef;
        margin-top: -8px * $scale-koef;
        border-radius: 5px;
      }

      &.not-available:before {
        width: 0;
        height: 0;
      }

      &.high:after {
        background: #92DA7B;
      }

      &.medium:after {
        background: #EAAC6C;
      }

      &.low:after {
        background: #E6819F;
      }
    }
  }
}

.patient-dashboard {
  font-size: 24px * $scale-koef;
  color: $font-color;


  .view-history-btn {
    margin-top: auto;
    margin-bottom: 9px;
  }

  .calendar-btn {
    margin: 0;
    margin-top: auto;
    margin-bottom: 9px;
  }

  .grid2x2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 356px;
  }

  .grid2x2 > .box-section {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    min-width: 520px * $scale-koef;
    margin: 20px;
    padding: 24px;
    border: 1px solid #D9D9D9;
    border-radius: 20px * $scale-koef;
    background: #FBFBFB;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .grid2x2 > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.heading {
      justify-content: center;
      margin-bottom: 38px * $scale-koef;
    }
  }

  .box-section {
    justify-content: space-between;
    margin: 0;

    .no-data {
      align-items: center;
      justify-content: center;
      height: 100px;

      .content {
        font-size: 15.3px;
        line-height: 39.1px;
        width: 185px;
        height: 39.1px;
        padding: 0 34px;
        color: #9C9C9C;
        border: 1px solid #DADADA;
        border-radius: 13px;
        background: white;
      }

      .image {
        display: none;
      }
    }
  }

  .column {
    display: flex;
    height: 100%;

    .item-container {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0 0 0 20px;

      &.big-counts {
        width: 60%;

        @media (max-width: $width-laptop-high-res) {
          width: auto;
        }
      }

      &.chart-box {
        width: 40%;
        padding: 0;

        @media (max-width: $width-laptop-high-res) {
          width: auto;
        }

        & > div {
          margin: 0 !important;
        }

        div.box {
          svg.plot {
            .doughnutCenterText {
              font-size: 24px * $scale-koef;
              font-weight: normal;
              color: $font-color;

              &.top {
                font-size: 60px * $scale-koef;
                font-weight: 600;
              }

              &.bottom {
                margin-top: 10px;
              }
            }

            path {
              &.new-green {
                fill: $active-color;
              }
            }
          }
        }

        svg.plot {
          height: auto;
        }
      }
    }
  }

  .big-card {
    display: flex;
    width: 100%;
    height: 186px * $scale-koef;
    margin: 0;
    padding: 20px * $scale-koef 30px * $scale-koef;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    background: #FFFFFF;

    @media (max-width: $width-laptop-high-res) {
      width: 300px * $scale-koef;
      margin-bottom: 10px;
    }

    .item {
      display: flex;
      align-items: center;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      padding: 10px;

      .first {
        margin-right: 20px;
      }
    }

    .row {
      display: flex;
      flex-direction: row;

      img {
        width: 24px * $scale-koef;
      }

      span {
        font-size: 24px * $scale-koef;
        margin-left: 5px;
      }
    }

    .values {
      font-size: 66px * $scale-koef;
      line-height: 1;
      color: #000000;
    }
  }

  .card-container {
    margin-bottom: 20px;

    &.clickable {
      cursor: pointer;
    }

    .card {
      font-size: 24px * $scale-koef;
      font-weight: 600;
      position: relative;
      width: 70%;
      padding: 20px * $scale-koef;
      color: $font-secondary-color;
      border: 1px solid #D9D9D9;
      border-radius: 10px;
      background: #FFFFFF;

      &.noData {
        width: 100%;
        text-align: center;
      }

      &.active-medication-card {
        width: 100%;
      }

      .navigation-icon {
        float: right;
        width: 15px;
        height: 28px;
        transform: rotateZ(90deg);
      }

      .medicine-name {
        display: inline-block;
        overflow: hidden;
        max-width: 89%;
        white-space: nowrap;
        text-indent: 15px;
        text-overflow: ellipsis;
      }
    }

    .time-block {
      flex: 25;
      margin-left: 15px;
      text-align: center;

      .day {
        font-size: 24px * $scale-koef;
        margin: 2px 0 4px;
      }

      .time {
        font-size: 39px * $scale-koef;
        font-weight: 600;
        line-height: 1.2;
      }
    }

    .dose-block {
      align-self: center;
      flex: 25;
      margin-left: 15px;
      text-align: center;

      .dose {
        font-size: 24px * $scale-koef;
        display: inline-block;
        width: 80px * $scale-koef;
        padding: 15px * $scale-koef;
        color: white;
        border-radius: 25px * $scale-koef;

        &.high {
          background: #92DA7B;
        }

        &.medium {
          background-color: #FDB646;
        }

        &.low {
          background-color: #E6819F;
        }
      }

      .dose-text {
        font-size: 24px * $scale-koef;
        margin-left: 10px;
        color: $font-color;
      }
    }
  }

  button {
    font-size: 18px * $scale-koef;
    align-self: flex-end;
    width: 130px;
    height: 42px;
    margin-top: auto;
    margin-left: auto;
    text-align: center;
    border-radius: 13px;

    &.blue {
      color: white;
      // background: linear-gradient(#3097E1, #2080C3);
      background: var($--main-theme-color);
    }

    &.white {
      border: #D9D9D9 1px solid;
    }
  }

  .table-row-spinner {
    position: absolute;
    right: 0;
    left: 0;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    padding: 10px 30px;
    border: 1px solid #DEDEDE;
    border-radius: 7px;
    background: #E6E6E6;

    .spinner-row {
      margin-top: -3px;
    }
  }

  .inner-section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
  }

  .select-box-dashboard {
    margin-bottom: 20px;

    &.right-select {
      display: flex;
      justify-content: flex-end;
    }

    &.left-select {
      display: flex;
      justify-content: flex-start;
    }

    select {
      font-family: GillSans;
      font-size: 22px * $scale-koef;
      overflow: hidden;
      padding-left: 30px;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $font-color;
      border: 0;
      background: transparent;
      background: url("../images/rx/selected-down.svg") no-repeat;
      background-position: left;
      background-size: 50px;
      appearance: none;

      &.left {
        padding: 8px 12px 13px 55px;
        background-position: left;
      }

      &.right {
        max-width: 150px;
        padding: 8px 55px 13px 30px;
        background-position: right;
      }
    }
  }

  .dashboard-icons {
    width: 26px;
    height: 26px;
  }
}
