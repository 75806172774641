svg.plot {
  .doughnutCenterText {
    font-size: $font-size-h1;
    fill: $color-background-darkest;
    transition: y $animation-swift-out;
    font-weight: 600;
    
    &.top {
      transform: translateY(-10px);
    }
    &.bottom {
      transform: translateY(20px);
      font-size: $font-size-small;
    }
  }
  .user-dashboard{
    fill: #000;
    font-weight: normal;
  }
}
