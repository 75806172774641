@import 'variables';

$theme-blue: (
    $--main-theme-color: #0460A9, // backgroungs, buttons
    $--secondary-theme-color: #0460A9, // tabs, nav menu icons
    $--left-nav-color: #0460A9,
    $--left-nav-child-color: #005091,
    $--left-nav-hover-color: #0A71C7,
    $--left-nav-active-color: #fff,
    $--left-nav-active-label-color: #0460A9,
    $--left-nav-opaque-icon-color: rgba(255, 255, 255, 0.6),
    $--left-nav-toggle-icon: url('../images/rx/right.svg'),
    $--left-nav-active-border-radius: 0,
    $--left-nav-hover-offset: 0px,
    $--controls-color: #14568D, // outlines, some controls
    $--right-arrow-icon: url('../images/rx/blue-theme/blue_rightArrow.svg'),
    $--arrow-down-icon: url('../images/rx/blue-theme/blue_arrow_down.svg'),
    $--download-icon: url('../images/rx/blue-theme/blue_download.svg'),
    $--sideNav-arrow-icon: url('../images/rx/blue-theme/blue_sideNav_arrow.svg'),
    $--bigRoundPlus-icon: url('../images/rx/blue-theme/blue_bigRoundPlus.svg'),
    $--select-icon: url('../images/rx/blue-theme/blue_selectDark.svg'),
    $--plus-icon: url('../images/rx/blue-theme/blue_plus.svg'),    
    $--svg-filter: invert(21%) sepia(100%) saturate(1577%) hue-rotate(189deg) brightness(99%) contrast(97%),
    $--opaque-main-color-7: rgba(4, 96, 169, 0.07),
    $--opaque-main-color-44: rgba(4, 96, 169, 0.44),
    $--opaque-main-color-55: rgba(4, 96, 169, 0.55),
    $--opaque-main-color-66: rgba(4, 96, 169, 0.66),
    $--opaque-main-color-30: rgba(4, 96, 169, 0.3),
    $--slider-color: #0460A9,
    $--table-hover-color-odd: #E4EBF0,
    $--table-hover-color-even: #EDF4F9,
);

$theme-red: (
    $--main-theme-color: #AB3439,
    $--secondary-theme-color: #4f4f4f,
    $--left-nav-color: #AB3439,
    $--left-nav-child-color: #92252A,
    $--left-nav-hover-color: #C4464C,
    $--left-nav-active-color: #fff,
    $--left-nav-active-label-color: #4f4f4f,
    $--left-nav-opaque-icon-color: rgba(255, 255, 255, 0.6),
    $--left-nav-toggle-icon: url('../images/rx/right.svg'),
    $--left-nav-active-border-radius: 0,
    $--left-nav-hover-offset: 0px,
    $--controls-color: #AB3439,
    $--right-arrow-icon: url('../images/rx/red-theme/red_rightArrow.svg'),
    $--arrow-down-icon: url('../images/rx/red-theme/red_arrow_down.svg'),
    $--download-icon: url('../images/rx/red-theme/red_download.svg'),
    $--sideNav-arrow-icon: url('../images/rx/red-theme/red_sideNav_arrow.svg'),
    $--bigRoundPlus-icon: url('../images/rx/red-theme/red_bigRoundPlus.svg'),
    $--select-icon: url('../images/rx/red-theme/red_selectDark.svg'),
    $--plus-icon: url('../images/rx/red-theme/red_plus.svg'),
    $--svg-filter: brightness(0) saturate(100%) invert(20%) sepia(99%) saturate(1268%) hue-rotate(330deg) brightness(101%) contrast(89%),
    $--opaque-main-color-7: rgba(171, 52, 57, 0.07),
    $--opaque-main-color-44: rgba(171, 52, 57, 0.44),
    $--opaque-main-color-55: rgba(171, 52, 57, 0.55),
    $--opaque-main-color-66: rgba(171, 52, 57, 0.66),
    $--opaque-main-color-30: rgba(171, 52, 57, 0.3),
    $--slider-color: #AB3439,
    $--table-hover-color-odd: #F0E8E8,
    $--table-hover-color-even: #F9F1F1,
);

$theme-white: (
    $--main-theme-color: #0060A8, // backgroungs, buttons
    $--secondary-theme-color: #0060A8, // tabs, nav menu icons
    $--left-nav-color: #fff,
    $--left-nav-child-color: #fff,
    $--left-nav-hover-color: #fff,
    $--left-nav-active-color: #14568D,
    $--left-nav-active-label-color: #fff,
    $--left-nav-opaque-icon-color: rgba(0, 96, 168, 1),
    $--left-nav-toggle-icon: url('../images/arrowRight.svg'),
    $--left-nav-active-border-radius: 15px * $scale-koef,
    $--left-nav-hover-offset: 15px,
    $--controls-color: #14568D, // outlines, some controls
    $--right-arrow-icon: url('../images/rx/blue-theme/blue_rightArrow.svg'),
    $--arrow-down-icon: url('../images/rx/blue-theme/blue_arrow_down.svg'),
    $--download-icon: url('../images/rx/blue-theme/blue_download.svg'),
    $--sideNav-arrow-icon: url('../images/rx/blue-theme/blue_sideNav_arrow.svg'),
    $--bigRoundPlus-icon: url('../images/rx/blue-theme/blue_bigRoundPlus.svg'),
    $--select-icon: url('../images/rx/blue-theme/blue_selectDark.svg'),
    $--svg-filter: invert(21%) sepia(100%) saturate(1577%) hue-rotate(189deg) brightness(99%) contrast(97%),
    $--opaque-main-color-7: rgba(0, 96, 168, 0.07),
    $--opaque-main-color-44: rgba(4, 96, 169, 0.44),
    $--opaque-main-color-55: rgba(4, 96, 169, 0.55),
    $--opaque-main-color-66: rgba(4, 96, 169, 0.66),
    $--opaque-main-color-30: rgba(0, 96, 168, 0.3),
    $--slider-color: #75B333,
    $--table-hover-color-odd: #E4EBF0,
    $--table-hover-color-even: #EDF4F9,
);

$theme-petrol: (
    $--main-theme-color: #003E52, // backgroungs, buttons
    $--secondary-theme-color: #003E52, // tabs, nav menu icons
    $--left-nav-color: #003E52,
    $--left-nav-child-color: #003040,   //take main color HSL and decrease value by ~10 and increase saturation by ~10
    $--left-nav-hover-color: #145266, //take main color HSL and increase value by ~10 and decrease saturation by ~10    
    $--left-nav-active-color: #fff,
    $--left-nav-active-label-color: #003E52,
    $--left-nav-opaque-icon-color: rgba(255, 255, 255, 0.6),
    $--left-nav-toggle-icon: url('../images/rx/right.svg'),
    $--left-nav-active-border-radius: 0,
    $--left-nav-hover-offset: 0px,
    $--controls-color: #14568D, // outlines, some controls
    $--right-arrow-icon: url('../images/rx/petrol-theme/petrol_rightArrow.svg'),
    $--arrow-down-icon: url('../images/rx/petrol-theme/petrol_arrow_down.svg'),
    $--download-icon: url('../images/rx/petrol-theme/petrol_download.svg'),
    $--sideNav-arrow-icon: url('../images/rx/petrol-theme/petrol_sideNav_arrow.svg'),   // these icons have to be copied and color changed in the svgs
    $--bigRoundPlus-icon: url('../images/rx/petrol-theme/petrol_bigRoundPlus.svg'),
    $--select-icon: url('../images/rx/petrol-theme/petrol_selectDark.svg'),
    $--plus-icon: url('../images/rx/petrol-theme/petrol_plus.svg'),    
    $--svg-filter: invert(16%) sepia(80%) saturate(1267%) hue-rotate(164deg) brightness(94%) contrast(101%), // go to https://codepen.io/sosuke/pen/Pjoqqp and generate filter for main color
    $--opaque-main-color-7: rgba(0, 62, 82, 0.07),
    $--opaque-main-color-44: rgba(0, 62, 82, 0.44),
    $--opaque-main-color-55: rgba(0, 62, 82, 0.55),
    $--opaque-main-color-66: rgba(0, 62, 82, 0.66),
    $--opaque-main-color-30: rgba(0, 62, 82, 0.3),
    $--slider-color: #003E52,
    $--table-hover-color-odd: #E4E8EA,  // go to any table with hover buttons and check
    $--table-hover-color-even: #EDF1F3, // color of the buttons background
);

@mixin spread-map($map: ()) {
    @each $key, $value in $map {
        #{$key}: $value;
    }
}
