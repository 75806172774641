@import "./color_themes";
@import "./brands";
@import "../fonts/gillsans";
@import "./variables";
@import "./common";
@import "./normalize";
@import "./layout";
@import "./inputs";
@import "./buttons";
@import "./hamburger/style";
@import "../components/components";
@import "./table";
@import "./invites";
@import "./notifications";
@import "./settings";
@import "./adherence-table";
@import "./dashboard";
@import "./auth";
@import "./study";
@import "./patient";
@import "./my-studies-page";
@import "./toast-message";
@import "./activated-page";
@import "patient-portal";
@import "patient-medications-page";
@import "patient-settings-page";
@import "oauth2-authorize";
@import "tablet";
@import "PatientDashboardView";

[data-theme='blue'] {
  @include spread-map($theme-blue);
}
[data-theme='red'] {
  @include spread-map($theme-red);
}
[data-theme='white'] {
  @include spread-map($theme-white);
}
[data-theme='petrol'] {
  @include spread-map($theme-petrol);
}
  
.rxcap {
  @include spread-map($rxcap);
}
.caredx {
  @include spread-map($caredx);
}
.esi {
  @include spread-map($esi);
}
.gxcap {
  @include spread-map($gxcap);
}

:root {
  overflow: hidden;
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}