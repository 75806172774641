@import "../../../../style/_variables";

$new-scale-koef: 0.75;
$scale-koef: 0.75;
// $scale-koef: 0.875;
$font-color: #4f4f4f;
$font-secondary-color: #272727;
$active-color: #b1d590;

.schedule-details {

  .trash-icon{
    display: block;
    height: 22px;
    width: 22px;
    background: url("../../../../images/trash.svg") no-repeat;
    background-size: 20px 20px;
  }

  .table-row-spinner {
    width: 40px;
    height: 40px;
    right: 0;
    left: 0;
    position: absolute;
    padding: 10px 30px;
    margin: 0 auto;

    .spinner-row {
      margin-top: -3px;
    }
  }


  .mt35 {
    margin-top: 35px;
  }

  .infoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top:32px;
  }

  .rightContainer {
    flex: 1;

    .labelValue {
      text-align: left;
      font-size: 22px * $scale-koef;
      color: #4f4f4f;
      margin-right: 10px;

      &.cap {
        text-transform: capitalize;
      }
    }

    .labelHW {
      text-align: right;
      border: 0;
      margin-right: 10px;
    }

    .rowRightContent {
      font-size: 22px * $scale-koef;
      text-align: center;
      color: #4f4f4f;

      label {
        font-size: 22px * $scale-koef;
        color: #4f4f4f;
      }
    }
  }

  .two-row {
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  .infoBox {
    margin: 20px;
    border-radius: 20px;
    background-color: $color-background-shaded;
    padding: 40px * $scale-koef 50px * $scale-koef;
    border: 1px solid #d9d9d9;
    flex: 1;

    &.first {
      margin-left:0px;
    }

    &.second {
      margin-right: 0px;
    }

    .info-label {
      margin-bottom: 20px;
      display: block;
    }

    button {
      font-size: 18px * $scale-koef;
      width: 130px;
      height: 42px;
      border-radius: 13px;
      text-align: center;
      margin-right: auto;
      margin-top: auto;


      &:disabled {
        opacity: 0.3;
      }

      &.blue {
        // background: linear-gradient(#3097e1, #2080c3);
        background: var($--main-theme-color);
        color: white;
      }

      &.white {
        border: #d9d9d9 1px solid;
      }

      &.bottom {
        margin-top: auto;
      }
    }

    &.spinner-container {
      justify-content: center;
      min-height: 300px;
      position: relative;
    }


    &.center {
      flex-direction: column;
      margin-top: 0;
    }

    &.two-column {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &.mt-0 {
        margin-top: 0;
      }

      img {
        width: auto;
        margin-right: 30px;

        &.hwImg {
          height: 250px;
        }
      }

      .link {
        color: #0094db;
      }
    }

    .title {
      color: $color-background-darkest;
      text-align: center;
      font-size: 24px * $scale-koef;
      margin: 0;
      padding: 0;
      font-weight: 400;
    }

    .data {
      width: 100%;
      margin-bottom: 20px;

      .rowContent {
        display: flex;
        justify-content: space-between;
        font-size: 22px * $scale-koef;
        padding-top: 24px * $scale-koef;
        padding-bottom: 7px * $scale-koef;
        border-bottom: 1px solid #e7e7e7;
        color: #4f4f4f;

        &:first-child {
          padding-top: 45px * $scale-koef;
        }

        & > * {
          // flex: 1;
          // width: 50%;
          display: block;
          font-size: inherit;
        }

        label {
          color: #000;
          margin-right: 10px;
          }

        .timeLabelValue {
          display: flex;
          flex-wrap: wrap;
          text-align: right;
          color: $color-profile-name;
          word-break: break-all;
          justify-content: flex-end;

          .timeText {
            padding: 0 0 10px 10px;
          }
        }

        .labelValue {
          text-align: right;
          color: #4f4f4f;
          word-break: break-all;

          span {
            font-size: inherit;
            color: inherit;
          }


      &.cap {
        text-transform: capitalize;
      }
        }
      }
    }
  }

  .right-section {
    display: flex;
    align-items: center;
    margin-left: auto;

    .switch-text {
      margin-right: 10px;
    }
  }
}

.tooltipSchedule {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltipSchedule .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: #fbfbfb;
  color: #7f7f7f;
  text-align: justify;
  border-radius: 6px;
  padding: 20px;
  border: 1px solid #d9d9d9;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.tooltipSchedule:hover .tooltiptext {
  visibility: visible;
}
