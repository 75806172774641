@import "../../style/_variables";

.column 
{
  &.special {
    .rowContent {
      &:first-child {
        padding-top: 0;
      }
    }
  }
}

.rowContent {
    display: flex;
    justify-content: space-between;
    font-size: 22px * $scale-koef;
    padding-top: 24px * $scale-koef;
    padding-bottom: 7px * $scale-koef;
    border-bottom: 1px solid #e7e7e7;
    color: #4f4f4f;

    &.border0 {
      border: 0;
    }


    
    & > * {
      // flex: 1;
      // width: 50%;
      display: block;
      font-size: inherit;
    }

    .timeLabelValue {
      display: flex;
      flex-wrap: wrap;
      text-align: right;
      color: $color-profile-name;
      word-break: break-all;
      justify-content: flex-end;

      .timeText {
        padding: 0 0 10px 10px;
      }
    }


  }



.dashboard-plot-row {
  .column {
    flex: 1;
    @media (max-width: 1450px) {
    .title {
      font-size: 22px;
    }
  }
    &.map {
      flex: 34%;
      margin: 20px 20px 0 0;
      position: relative;
      border: 0;

      .middle-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        h1 {
          color: #000;
        }
      }
    }
    &.map-details {
      // margin: 20px 20px 0 0;
      // &:last-child {
      //   margin: 20px 0px 0 20px;
      // }
    }
  }

  .box{
    position: relative;
    .middle-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
        color: #000;
      }
    }
    
  }
}

.boxGraphWithTitle {
  padding: 30px;
}
.boxGraphWithTitle-title {
  font-size: 25px * $scale-koef;
  text-align: center;
  padding-bottom: 30px;
  color: #000;
}

.widgetContainer {
  div.box1 {
    border-radius: 20px;
    background-color: #fbfbfb;
    padding: 30px 32px;
    margin: 0;
    height: 100%;
    width: 100%;
    .data {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .data1 {
    display: flex;
    justify-content: space-between;
  }

  .stats-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    height: 100%;
    background-color: #fbfbfb;
    padding: 20px;
    &.missed-doses {
      align-items: center;
      grid-template-columns: 6fr 1fr;
      grid-row-gap: 5px;
    }

    label {
      color: #4f4f4f;
      display: block;
      width: 100%;
      line-height: 1.5;
      font-size: 22px * $scale-koef;
    }

    .labelValue {
      text-align: right;
      color: $color-profile-name;
      word-break: break-all;
      font-size: 22px * $scale-koef;
    
      span {
        font-size: inherit;
        color: inherit;
      }
    
      &.blue {
        color: #2492cf;
      }
    
      &.red {
        color: #e71d5b
      }
    
      &.green {
        color: #65a623;
      }
    }
    .sub-box {
      display: block;
      font-size: 22px * $scale-koef;
      .labelValue {
        padding: 11px 10px 10px;
        border-radius: 10px;
        border: solid 1px #d9d9d9;
        background-color: #fff;
        color: var($--main-theme-color);
        text-align: center;
        font-weight: 600;
      }
    }
  }
}

