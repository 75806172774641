@import "../../../style/variables";

.PhoneInput {
  width: 100%;
}

.iti--separate-dial-code .iti__selected-flag {
  border-radius: 7px;
}

.iti__country-list {
  position: fixed;
  overflow: auto;
}