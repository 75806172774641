.bkg-brand-blue {
  background: $color-gradient-blue;
  color: white;
}

#description {
  width: 100%;
}

.switch-mockup-true,
.switch-mockup-false {
  width: 40px;
  height: 67px;
  background: transparent url(../images/admin_switch_mockup.png) center center
    no-repeat;
}

.switch-mockup-false {
  background-image: url(../images/admin_switch_mockup_red.png);
}

.adherence-7day {
  background: url(../images/adherence-7-day.png) center center no-repeat;
  width: 100%;
  height: 260px;
}

.download-button,
[role="button"].download-button {
  margin-right: 34px;
  padding-right: 36px;
  padding-left: 36px;
  cursor: pointer;
}

.Chart {
  margin: 20px 0 0;
  position: relative;
}

.adherence-time-plot {
  .Chart {
    padding: 0;
    margin-top: 0;
    position: absolute;
    width: calc(100% - 60px);
    z-index: 9999;
    margin-left: 53px;
    overflow: hidden;
  }
}

.dashboard-plot-row {
  .column {
    flex: 1;
  }
}




.plot-box {
  height: 350px;

  svg.plot {
    height: 235px;
    width: 100%;

    .doughnutCenterText {
      font-size: $font-size-h2;
      fill: $font-color-dark;
      transition: y $animation-swift-out;

      &.top {
        transform: translateY(-10px);
      }
      &.bottom {
        transform: translateY(20px);
        font-size: $font-size-small;
      }
    }

    g.grid g.tick line {
      stroke: $color-border-light;
    }

    g.series,
    path {
      rect {
        transition: fill $animation-swift-out, stroke $animation-swift-out;
      }
      &.complied {
        fill: transparentize($color-plot-complied-secondary, $transparency);
      }

      &.skipped {
        fill: transparentize($color-plot-skipped, $transparency);
      }

      &.missed {
        fill: transparentize($color-plot-missed, $transparency2);
      }

      &.pending {
        fill: transparentize($color-brand-light, $transparency);
      }
    }

    .empty {
      fill: $color-background-inactive;
      //fill-opacity: 0.5;
    }

    g.axis {
      font-size: $font-size-base;

      path.domain,
      line {
        stroke: $color-border;
      }
      .tick > text {
        fill: $font-color-light;
        font-size: 12px;
      }

      &.no-domain {
        path.domain {
          stroke: transparent;
        }
      }

      &.middle {
        g.tick text {
          fill: $font-color-base;
        }
      }
    }
  }
}

.box.info-box {
  color: $font-color-dark;
  text-align: center;

  > header {
    color: $font-color-dark;
    margin-left: 0;
    margin-right: 0;
  }

  button {
    margin-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
}

.loader,
.loader:before,
.loader:after {
  background: $color-brand-light;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: $color-brand-light;
  text-indent: -9999em;
  margin: 0 auto;
  position: relative;
  width: 1em;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.loader-fixed {
  position: absolute;
  margin-top: 10vh;
  z-index: 1111;
}

.loader-small,
.loader-small:before,
.loader-small:after {
  background: $color-brand-light;
  -webkit-animation: load1-small 1s infinite ease-in-out;
  animation: load1-small 1s infinite ease-in-out;
  width: 0.1em;
  height: 1em;
}

.loader-small {
  color: $color-brand-light;
  text-indent: -9999em;
  margin: 0 auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader-small:before,
.loader-small:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader-small:before {
  left: -0.6em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-small:after {
  left: 0.6em;
}

@-webkit-keyframes load1-small {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 1em;
  }
  40% {
    box-shadow: 0 -0.8em;
    height: 1.25em;
  }
}

@keyframes load1-small {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 1em;
  }
  40% {
    box-shadow: 0 -0.8em;
    height: 1.25em;
  }
}

.hidden {
  display: none;
}

.validation-message {
  display: flex;
  align-self: flex-start;
  color: #e32a59;
  font-size: $font-size-base;
  text-align: left;
  padding-left: 20px;
  margin-top: 5px;
}

.reverse {
  display: flex;
  flex-direction: row-reverse;
}

.spinner-container {
  min-height: 110px;
  position: relative;
  border: 1px solid #d9d9d9;
  background-color: #fbfbfb;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.spinner-white-box{
  width: 162px;
  height: 46px;
  margin: auto;
  background-color: #fff;
  border-radius: 13px;
  border: solid 1px #dadada;
}

.box.dialog.form-modal {
  input[type="text"], input[type="email"] {
    border-radius: 11.3px !important;
    color: #4F4F4F  !important;
    border: 0  !important;
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1)  !important;

    &::placeholder {
      color: #929292;
    }
  }

  .button-bar {
    button {
      border-radius: 10.6px !important;
    }
  }
}

.edit-profile {
  &.with-loader {
    .spinner-container {
      min-height: auto;
    }
  }
}

.no-data-image-container {
  height: 100%;
  display: flex;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  
  > .no-data-image {
    margin: auto;
    /* height: 84px; */
    /* width: 84px; */
    /* display: inline-block; */
    /* background-image: url(/react/1e63ba8….png); */
    background-color: white;
    width: 101px;
    height: 30px;
    opacity: 0.5;
    font-family: GillSans;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #929292;
    border: 1px solid #929292;
    border-radius: 7px;
  }
}

.spinner {
  background: url("../images/rx/pendingLoader.png") no-repeat;
  background-size: 20px;
  animation: rotating 3s linear infinite;
  fill: transparentize(#91d0ff, $transparency);
  z-index: 99;
  width: 20px;
  height: 20px;
  margin: auto;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;

  &.download {
    left: -24px;
    right: auto;
  }
}

.table-spinner-container {
  position: absolute;
  width: 24px;
  height: 24px;
  .spinner {
    position: absolute;
    top: -13px;
    right: 46px;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    &.slider-spin {
      position: absolute;
      top: 3px;
      right: -3px;
      width: 24px;
      height: 24px;
    }
  }
}

tr.empty-row {
  &:hover {
    td:after {
      display: none !important;
    }
  }

  td {
    padding: 0 !important;
    cursor: default !important;

  }
}

.empty-row-container {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  height: 78px*0.85;
  background: #F7F7F7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  height: 276px;
  &.bottom-margin {
    margin: 0;
    margin-bottom: 20px;
  }

  .empty-row-inner {
    height: 46px*0.85;
    line-height: 46px*0.85;
    font-size: 18px*0.85;
    color: #9C9C9C;
    padding: 0 40px*0.85;
    background: white;
    border: 1px solid #dadada;
    border-radius: 13px;
    position: sticky;
    right: 50%;
    transform: translateX(50%);
  }
}

.table-row-spinner{
  display: inline-block;

  &.adherence {
      position: relative;
      .spinner-row {
        right:-25px;
      }
  }

  .spinner-row {
    background: url("../images/rx/pendingLoader.png") no-repeat;
    background-size: 20px;
    animation: rotating 3s linear infinite;
    fill: transparentize(#91d0ff, $transparency);
    z-index: 99;
    width: 20px;
    height: 20px;
    margin: 0;
    position: absolute;
    right: 25px;
    margin-top: -16px;
  }
}

div.tooltip-time-delta-custom {	
  position: absolute;			
  text-align: center;			
  width: 60px;					
  height: 28px;					
  padding: 2px;				
  font: 12px sans-serif;		
  background: lightsteelblue;	
  border: 0px;		
  border-radius: 8px;			
  pointer-events: none;			
}

.battery_charge {
  display: flex;
  align-items: center;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 36px*$scale-koef;
    height: 36px*$scale-koef;
    border: 1px solid #D9D9D9;
    margin-right: 17px*$scale-koef;
    border-radius: 10px*$scale-koef;
    background: white;
  }

  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 16px*$scale-koef;
    height: 16px*$scale-koef;
    border-radius: 4px*$scale-koef;
    bottom: 10px*$scale-koef;
    left: 10px*$scale-koef;
  }

  &.battery_charge_low:after {
    background-color: #E6819F;
    height: 8px*$scale-koef;
  }

  &.battery_charge_medium:after {
    height: 12px*$scale-koef;
    background-color: #EAAC6C;
  }

  &.battery_charge_high:after {
    background-color: #92DA7B;
  }
}

.battery_charge-2 {
  display: inline-flex;
  align-items: center;
  position: relative;

  &.ml-10 {
    margin-left: 10px;
  }
}

.battery_charge-2 span{
  height: 14px;
  width: 12px;
  margin-right: 2px;
  border-radius: 2px;
}

.battery_charge_high-2{
  background-color: #74b236;
}

.battery_charge_medium-2{
  background-color: #EAAC6C;
}

.battery_charge_low-2{
  background-color: #da547d;
}

.battery_charge_finish-2{
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  background-color: #dedede;
}