@import "../../style/variables";

:root {
  --bg:  #ffffff;
  --bg-accent: #a8a8a8;
  --text-color: #000000;
  --nav-size: 60px;
  --border: 1px solid #b9b9b9;
  --border-radius: 8px;
}


/* <li> */
.dropdown-container {
  width: calc(var(--nav-size) * 0.8);
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  top: 38px;
  z-index: 10;
}

.filtervisibility-container {
  height: 34px;
  width: 34px;
  border-radius: 7px;
  border: 1px solid #d9d9d9;
  position: absolute;
  background-color: #F7F7F7;
}

.filtervisibility-icon-generic {
    display: block;
    height: 34px;
    width: 34px;
    background-size: 100%;
    background-color: var($--main-theme-color);
    border-radius: 7px;
    transform: translate(-1px, -1px)
  }

.filtervisibility-icon {
  background-color: #4f4f4f;
  -webkit-mask: url("../../images/visibilityIcon.svg") no-repeat center/contain;
  mask: url("../../images/visibilityIcon.svg") no-repeat center/contain;
  @extend .filtervisibility-icon-generic;
}

.filtervisibility-icon-on {
  @extend .filtervisibility-icon-generic;
  -webkit-mask: url("../../images/visibilityIcon.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
  mask: url("../../images/visibilityIcon.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  border: unset;
}

.filtervisibility-icon:hover {
  @extend .filtervisibility-icon-on;
}

/* Dropdown Menu */

.dropdown {
  position: absolute;
  min-width: 310px;
  max-width: 60vw;
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: visible;
  z-index: 10;
  transform: translate(calc(-50% + 17px), calc(50% + 18px));
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.13);
}

.top-label {
  font-size: 1.1rem;
  padding-bottom: 20px;
  text-align: left;
}


.form-control {
  font-size: 1.1rem;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1.15em;
  min-width: 1.15em;
  height: 1.15em;
  display: grid;
  place-content: center;
  background-size: 100%;
  background-color: #F7F7F7;
  border: 1px solid #bababa;
  border-radius: 7px;
  -webkit-box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.filter {
  margin: 10px;
  white-space: nowrap;
  &.grouped {
    padding-left: 20px;
  }
  .filter-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

input[type="checkbox"]:checked {
  background: url("../../images/checkbox.svg") no-repeat;
  background-color: #74b236;
  background-size: 100%;
  border: 1px solid #74b236;
}

.form-control + .form-control {
  margin-top: 1em;
}

.reset-button {
  float: left;
  margin-top: 15px;
}
