button {
    &.refreshTimeDelta {
        border: 0;
        box-shadow: none;
        
        position: absolute;
        top: -200px;
        right: 0px;
        z-index: 99;
        background: url('../../../images/refresh2.svg') no-repeat;
        width: 46px;
        min-width: 46px;
        height: 46px;
        margin-right: 0px;
    }
}
