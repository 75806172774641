@import "../../style/variables";

.enroll-patient {
  .react-autosuggest__container {
    input:not([type=checkbox]) {
      width: 100%;
      min-width: 400px;
    }
  }

  .kit-location {
    border-radius: 13px;
    box-shadow: 0px 0px 12px 3px rgb(0 0 0 / 12%);
    padding: 15px;
    padding-top: 0;
    background-color: #F5F5F5;
    img {
      max-width: 95%;
    }
  }
}

.checkbox-container {
  &.border {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #000;
      label {
        color: #000;
      }
    }
  }

  &.invalid-control-background {
    background-color: $color-required-background;
  }

  .program-name {
    color: var($--main-theme-color);
  }

  input[type=checkbox] {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }

  .file-input-label {
    position: relative;
    input[type=file] {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      outline: none;
      opacity: 0;
      cursor: pointer;
    }
    p {
      margin: 0;
      text-align: center;
      color: var($--main-theme-color);
    }
  }
}

.control-with-top-right-text {
  position: relative;
  .right-top-text {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    .label {
      color: #4F4F4F;
    }
    .icon {
      display: block;
      color: #fff;
      background-color: var($--main-theme-color);
      border-radius: 100px;
      text-align: center;
      width: 18px;
      height: 18px;
      font-size: 15px;
    }
  }
}

.enroll-notes-textarea {
  grid-row-start: 2;
  grid-row-end: 4;
}