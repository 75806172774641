.edit-facilities {
  ::placeholder {
    color: #b0b0b0;
    opacity: 1;
  }

  ::-ms-input-placeholder {
    color: #b0b0b0;
    opacity: 1;
  }

  ::-moz-placeholder {
    color: #b0b0b0;
    opacity: 1;
  }
}