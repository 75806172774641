@import '../../style/variables';

.send-message {
  textarea {
    width: 100%;
    background-color: #f7f7f7;
    box-shadow: inset 0 3px 5px 0 rgb(0 0 0 / 22%);
    padding: 12px;
    border-radius: 13px;
    border: solid 1px #bababa;
    &.invalid-control-background {
      background-color: $color-required-background;
    }
  }
  .prefab-button {
    bottom: 20px;
    left: 100px;
  }
  .prefab-window {
    transform: unset;
    bottom: 70px;
    left: 100px;
    min-width: 420px;
  }
  .message-textbox__control > textarea {
    background-color: #F7F7F7;
    border-radius: 16px;
  }
  .message-textbox.invalid-control-background {
    border-radius: 16px;
  }
}