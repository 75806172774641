.wizard.eula {
  .buttons {
    justify-content: center;
    .bottom-button-container:first-child {
      display: none;
    }
  }
  .content-block {
    white-space: pre-line;
  }
}