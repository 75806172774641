@import '../../style/variables';

$new-scale-koef: 0.90;
.react-select-container {
  font-size: 18px * $scale-koef;
  line-height: 46px * $new-scale-koef;
  width: auto;
  min-width: auto;
  height: 46px* $new-scale-koef;
  margin: 0;
  border-radius: 13px;
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.22);
  border: solid 1px #bababa;
  background-color: #F7F7F7;
  color: #000000;

  &:hover {
    box-shadow: none;
  }
  .react-select__control {
    border: none;
    background: unset;
    font-size: 18px * $scale-koef;
    line-height: 46px * $new-scale-koef;
    width: auto;
    min-width: auto;
    height: 46px* $new-scale-koef;
    min-height: unset;
    padding-left: 22px;

    .react-select__value-container {
      font-size: 18px * $scale-koef;
      height: 46px * $new-scale-koef;
      padding: 0;
      .react-select__placeholder {
        padding: 0;
        margin: 0;
        font-size: 18px * $scale-koef;
        color: #000000;
      }
      .react-select__input-container {
        padding: 0;
        margin: 0;
        .react-select__.input {
          margin: 0;
          padding: 0;
          min-width: 0;
        }
        input {
          box-shadow: none;
        }
      }
    }

    .react-select__indicators {
      padding: 0 8px;
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__dropdown-indicator {
        width: 25px*$new-scale-koef;
        height: 25px*$new-scale-koef;
        background-image: var($--arrow-down-icon);
        background-repeat: no-repeat;
        // background-position: right 10px center;
        background-size: 25px*$new-scale-koef;
        svg {
          display: none;
        }
      }
    }
  
  }

  .react-select__control--is-focused {
    box-shadow: none;
  }
  .react-select__menu-portal {
    z-index: 2;
  }
  .react-select__menu {
    padding: 6px;
    border: #BABABA 1px solid;
    border-radius: 12px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.13);
    background-color: #fff;
    z-index: 2;

    .react-select__option {
        border-radius: 8px;
        padding: 10px;
        font-size: 16px;
        line-height: 16px;
        white-space: nowrap;
        &:hover {
            background-color: var($--opaque-main-color-7);
            color: #000000;
        }
        &.disabled, &.react-select__option--is-disabled {
            cursor: not-allowed;
            pointer-events: none;
            filter: grayscale(100%);
            opacity: .5;
        }
    }
    .react-select__option--is-focused {
      background-color: unset;
    }

    .react-select__option--is-selected {
      background-color: var($--main-theme-color);
      color: #fff;
    }
  }
}

.react-select-container.multi-select {
  .react-select__menu {
    .react-select__option {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .react-select__option--is-selected {
      background-color: unset;
      color: unset;
    }
    .react-select__group:not(:first-child) {
      border-top: 1px solid #D9D9D9;
      .react-select__group-heading {
        padding-left: 5px;
      }
    }
  }
}

.react-select-container.multi-select.checkboxFirst {
  .react-select__menu {
    .react-select__option {
      justify-content: left;
      flex-direction: row-reverse;
      column-gap: 10px;
    }
    .react-select__group {
      .react-select__option {
        padding-left: 20px;
        &.header {
          padding-left: 10px;
        }
      }
    }
  }
}

.react-select--is-disabled {
  cursor: not-allowed;
  filter: grayscale(100%);
  opacity: .5;
}

.multiselect-selected-options {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  &.items-per-row-2 > div {
    flex: calc(50% - 8px);
    min-width: 0;
    max-width: calc(50% - 8px);
  }
  &.items-per-row-1 > div {
    flex: 100%;
    min-width: 0;
    max-width: 100%;
  }
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .checkbox-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px * $new-scale-koef;
    background-color: #fff;
    color: #000;
    font-size: 18px * $new-scale-koef;
    &:hover {
      background-color: unset;
      .remove-button {
        display: block;
      }
    }
  }
  .option {
    position: relative;
    padding: 6px 20px;
    border-radius: 20px;
    border: solid 1px #efefef;
    background-color: #f5f5f5;
    font-size: 15px;
    color: #4f4f4f;
    p {
      margin: 0;
      margin-right: 36px * $new-scale-koef;
    }
    .remove-button {
      display: block;
      right: 1px;
      top: 0px;
    }
  }
  .remove-button {
    position: absolute;
    right: 20px;
    width: 36px * $new-scale-koef;
    min-width: 36px * $new-scale-koef;
    height: 36px * $new-scale-koef;
    padding: unset;
    background-image: url('../../images/closeIcon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-radius: 100px;
    display: none;
    &:hover {
      box-shadow: 2px, 0, 3px, 0, rgba(0,0,0,0.06);
    }
  }
  .round-button {
    height: unset;
    color: #fff;
  }
}