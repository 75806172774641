@import "./variables";

$font-size-landing: 16px;
$small-screen-zoom: 0.8;

@function to-em($pixel) {
  @return ($pixel/16) + em;
}

$color-landing-red: #e71e5b;

@mixin patient-fieldset() {
  fieldset {
    text-align: center;
    border: 1px #e3e3e3 solid;
    padding-bottom: to-em(10);
    margin-bottom: to-em(32);

    legend {
      color: #b0b0b0;
      padding: 0 to-em(10);
    }

    > span {
      font-size: 1.5em;
      line-height: to-em(45);
      color: #4a4a4a;
    }
  }
}

.two-columns-layout {
  height: 100%;
  font-size: $font-size-landing;
  display: flex;
  flex-flow: row nowrap;

  @media (max-width: $iPad) and (orientation: landscape) {
    height: initial;
  }

  @media (max-height: 560px) {
    height: initial;
  }

  > div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    transition: width $animation-swift-out, padding $animation-swift-out;

    &:not(.active) {
      max-width: 0;
      overflow: hidden;
      padding: 0;
    }

    > div.vertical-center-block {
      align-self: center;
      width: 100%;
      margin-top: 16px;

      @media (min-height: 560px) and (max-width: $iPad) and (orientation: portrait) {
        flex: initial;
        max-width: 100%;
        height: 100%;
      }
    }
  }

  a,
  span,
  input,
  button,
  select,
  label {
    font-size: $font-size-landing;
  }

  table.table {
    font-size: 1em;

    > tbody {
      font-size: 1em;

      tr {
        font-size: 1em;
      }
    }
  }

  .h1-wrapper {
    margin-top: 28px;
    margin-bottom: 38px;

    h1 {
      padding: to-em(0);
      font-size: to-em(21);
      line-height: to-em(20);
      color: $color-brand;
      font-weight: 600;
      &.red {
        color: #e71d5b;
      }

      &.center {
        text-align: center;
      }
    }
  }

  .left {
    flex: auto 1 1;
    padding: 0 to-em(50);

    .h1-wrapper {
      margin-top: to-em(25);
      margin-bottom: to-em(18);

      h1 {
        padding: to-em(0);
        font-size: to-em(21);
        line-height: to-em(20);
        color: $color-brand;
        font-weight: 600;
        &.red {
          color: #e71d5b;
        }
      }
    }

    @media (max-width: $iPad) {
      display: none;
    }

    .content {
      margin: auto;
      max-width: to-em(430);
      text-align: center;

      .caption {
        text-align: center;
        line-height: to-em(25);
        margin-bottom: to-em(50);
        color: #808080;
      }
    }

  }

  .right {
    width: 50%;
    margin: 0 auto;
    padding: 0 to-em(50);
    // height: 100%;
    position: relative;
    text-align: center;
    border-left: solid 0.7px #ebebeb;
    background-color: #f7f7f7;
    display: flex;
    flex-flow: column nowrap;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .password-wrapper {
      position: relative;

      .toggle-password {
        position: absolute;
        top: to-em(8);
        right: 8px;
        text-align: right;
        background: url("../images/rx/hidePassword.svg") no-repeat center;
        width: to-em(30);
        height: to-em(30);
        background-size: to-em(30);
        cursor: pointer;

        &:hover {
          background: url("../images/rx/hidePasswordHover.svg") no-repeat center;
          width: to-em(30);
          height: to-em(30);
          background-size: to-em(30);
        }
      }

      .toggle-text {
        position: absolute;
        top: to-em(8);
        right: 8px;
        text-align: right;
        background: url("../images/rx/password.svg") no-repeat center;
        width: to-em(30);
        height: to-em(30);
        background-size: to-em(30);
        cursor: pointer;

        &:hover {
          background: url("../images/rx/showPassword.svg") no-repeat center;
          width: to-em(30);
          height: to-em(30);
          background-size: to-em(30);
        }
      }
    }

    @media (max-width: $iPad) {
      flex: auto 1 1;
      max-width: 100%;
      padding: 38px;
    }

    .right-logo {
      margin: 0 auto;
      width: to-em(70);
      height: to-em(70);
      background: url("../images/rx/rxCapicon.svg") no-repeat center;
      background-size: to-em(70) to-em(70);
    }

    .copyright {
      position: absolute;
      bottom: to-em(24);
      line-height: to-em(24);
      width: 100%;
      text-align: center;
      color: #b0b0b0;
      max-width: to-em(335);
    }

    .fixed-height {
      min-height: to-em(320);

      .input-wrapper {
        .code-label {
          display: block;
          margin-top: to-em(-20);
          line-height: to-em(48);
          color: #4a4a4a;
          text-align: left;
        }

        .verification-code-wrapper {
          display: flex;
          justify-content: space-between;

          input.verification-code {
            width: to-em(55);
            text-align: center;
            padding: 0;
          }
        }
      }
    }

    .buttons.row + .buttons.row {
      border-top: 1px solid $color-border-dark;

      @media (max-width: $iPad) {
        padding: 20px 0;
      }
    }

    // [role="button"] {
    //   width: to-em(185);
    // }
  }

  .clear {
    clear: both;
  }

  .login-page,
  .step-4,
  .reset-password {
    max-width: to-em(370);
    margin: auto;

    &.mt-25 {
      margin-top: 25px;
    }

    input.text,
    select {
      display: block;
      width: 100%;
      // border-radius: 13px;
      margin-bottom: to-em(15);
      line-height: to-em(50);
      border: 0;
      padding: 0 to-em(15);
      font-size: to-em(17);
      background: transparent;
      box-shadow: none;

      &.react-phone-number-input__country-select {
        padding: 0 45px;
      }

      &::placeholder {
        color: #b0b0b0;
      }

      &::-webkit-input-placeholder {
        color: #b0b0b0;
      }

      &::-moz-placeholder {
        color: #b0b0b0;
      }

      &:-ms-input-placeholder {
        color: #b0b0b0;
      }
      &:-moz-placeholder {
        color: #b0b0b0;
      }

      @include input-focus-animation(to-em(10));

      &[required] {
        &::after {
          content: "*";
        }
      }
    }

    select {
      background: #ffffff url("../images/small-arrow-bottom.svg") no-repeat
        right to-em(18) center;
      background-size: to-em(12) to-em(8);

      -moz-appearance: none;
      -webkit-appearance: none;

      &::-ms-expand {
        display: none;
      }
    }

    .one-line {
      display: flex;
      justify-content: space-between;

      input,
      .input-wrapper {
        width: 48%;
      }

      .input-wrapper {
        input {
          width: 100%;
        }
      }
    }

    p {
      text-align: left;
      margin: 0 0 to-em(40) 0;
      color: #4a4a4a;
      line-height: to-em(28);

      &.center {
        text-align: center;
      }

      span {
        &.red {
          color: $color-brand-red;
          font-weight: $font-weight-bold;
        }

        &.blue {
          color: $color-brand;
        }
      }
    }

    div.input-wrapper {
      position: relative;

      &.required {
        &::after {
          position: absolute;
          content: "*";
          color: $color-required-mark;
          top: 3px;
          left: 10px;
        }
      }

      .online-error {
        position: absolute;
        text-align: left;
        top: 0;
        left: 100%;
        height: to-em(50);
        line-height: to-em(50);
        padding-left: to-em(16);

        .tip {
          background: #fbfbfb;
          white-space: nowrap;
          line-height: to-em(36);
          padding: 0 to-em(20);
          border: 1px #dcdcdc solid;
          display: inline-block;

          span {
            font-size: 80%;
            color: $color-landing-red;
          }

          position: relative;
          @include left-arrow(9px, #fbfbfb, #dcdcdc);
        }
      }
    }

    p {
      text-align: left;
      margin: 0 0 to-em(40) 0;
      color: #4a4a4a;
      line-height: to-em(28);

      &.center {
        text-align: center;
      }

      span {
        &.red {
          color: $color-brand-red;
          font-weight: $font-weight-bold;
        }

        &.blue {
          color: $color-brand;
        }
      }
    }

    div.input-wrapper {
      position: relative;

      .online-error {
        position: absolute;
        text-align: left;
        top: 0;
        left: 100%;
        height: to-em(50);
        line-height: to-em(50);
        padding-left: to-em(16);

        .tip {
          background: #fbfbfb;
          white-space: nowrap;
          line-height: to-em(36);
          padding: 0 to-em(20);
          border: 1px #dcdcdc solid;
          display: inline-block;

          span {
            font-size: 80%;
            color: $color-landing-red;
          }

          position: relative;
          @include left-arrow(9px, #fbfbfb, #dcdcdc);
        }
      }
    }

    select {
      background: #ffffff url("../images/small-arrow-bottom.svg") no-repeat
        right to-em(18) center;
      background-size: to-em(12) to-em(8);

      -moz-appearance: none;
      -webkit-appearance: none;

      &::-ms-expand {
        display: none;
      }
    }

    p {
      text-align: left;
      margin: 0 0 to-em(40) 0;
      color: #4a4a4a;
      line-height: to-em(28);

      &.center {
        text-align: center;
      }

      span {
        &.red {
          color: $color-brand-red;
          font-weight: $font-weight-bold;
        }

        &.blue {
          color: $color-brand;
        }
      }
    }

    div.input-wrapper {
      position: relative;

      .online-error {
        position: absolute;
        text-align: left;
        top: 0;
        left: 100%;
        height: to-em(50);
        line-height: to-em(50);
        padding-left: to-em(16);

        .tip {
          background: #fbfbfb;
          white-space: nowrap;
          line-height: to-em(36);
          padding: 0 to-em(20);
          border: 1px #dcdcdc solid;
          display: inline-block;

          span {
            font-size: 80%;
            color: $color-landing-red;
          }

          position: relative;
          @include left-arrow(9px, #fbfbfb, #dcdcdc);
        }
      }
    }

    .error {
      margin: 0 0 to-em(40) 0;
    }

    .buttons.row {
      text-align: left;
      padding: to-em(27) 0;
      overflow: hidden;

      @media (max-width: $iPad) {
        padding: 20px 0;
      }

      a {
        cursor: pointer;
      }

      .left-text {
        line-height: to-em(30);

        &.reactive {
          &:hover {
            color: $color-brand;
          }
        }
      }

      &.dotted-line {
        border-top: 1px dotted #c8c8c8;
      }

      &.line {
        border-top: 1px solid #c8c8c8;
      }

      &.space {
        margin-top: 34px;
      }

      // button,
      // [role="button"] {
      //   position: relative;

      //   &.no-arrow {
      //     &::after {
      //       display: none;
      //     }
      //   }

      //   &.full-width {
      //     float: none;
      //     width: 100%;

      //     &::after {
      //       content: none;
      //     }
      //   }
      // }
    }
  }

  .step-4 {
    .error {
      color: $color-brand-red;
    }

    input.text {
      padding-left: to-em(25);
    }

    // .buttons.row {
    //   margin-top: 5px;

    //   display: flex;
    //   justify-content: space-between;

    //   input[role="button"] {
    //     width: 48%;

    //     &.cancel {
    //       border-color: #7f7f7f;
    //     }
    //   }
    // }
  }

  // TODO - combine it with cleaning up portal changes

  .autocomplete {
    overflow-y: visible;
    margin-bottom: to-em(45);

    text-align: left;

    label {
      display: none;
    }

    .select {
      @include input-hover-animation();
      border: 1px solid #d4d4d4;
      background: #ffffff;

      input {
        background: url("../images/small-arrow-bottom.svg") no-repeat right
          to-em(18) center;
        background-size: to-em(12) to-em(8);
        margin-bottom: 0;
        border: none;
        @include input-focus-animation-reset();
      }

      .items-wrapper {
        display: none;

        position: relative;
        z-index: 1;
        background: #ffffff;
        padding: to-em(5);

        border-bottom: 1px solid #d4d4d4;

        .items {
          padding: 0 to-em(20);

          max-height: to-em(320);
          overflow-y: auto;

          .item {
            line-height: to-em(45);
            cursor: pointer;
          }
        }
      }

      &.opened {
        .line {
          margin: 0 to-em(5);
          border-top: 1px #d4d4d4 dashed;
        }

        border-bottom: none;

        .items-wrapper {
          display: block;
        }
      }
    }

    &.user-activate,
    &.user-mobile-phone-verify {
      input.text {
        background-color: #ffffff;

        &.success {
          color: #44c24d;
          background: #ffffff url("../images/field-success.png") no-repeat right
            to-em(10) center;
          background-size: to-em(24) to-em(24);
        }

        &.has-error {
          color: $color-landing-red;
          background: #ffffff url("../images/field-has-error.png") no-repeat
            right to-em(10) center;
          background-size: to-em(24) to-em(24);
        }
      }

      .input-wrapper {
        &.score {
          &::before {
            content: " ";
            display: block;
            height: 2px;
            border-radius: 1px;
            background: $color-landing-red;
            position: absolute;
            left: 1px;
            bottom: 1px;
          }

          &.score-0::before {
            width: 25%;
          }

          &.score-1::before {
            width: 50%;
          }

          &.score-2::before {
            width: 75%;
          }

          &.score-3::before {
            content: none;
          }

          &.score-4::before {
            content: none;
          }
        }
      }

      // .buttons.row {
      //   padding: to-em(40) 0;

      //   [role="button"] {
      //     &.inactive {
      //       background: #c9cacc;
      //       color: #ffffff;
      //       pointer-events: none;
      //     }
      //   }
      // }
    }
  }

  // TODO - combine it with cleaning up portal changes

  .autocomplete {
    height: to-em(50);
    overflow-y: visible;
    margin-bottom: to-em(45);

    text-align: left;

    label {
      display: none;
    }

    .select {
      @include input-hover-animation();
      border: 1px solid #d4d4d4;
      background: #ffffff;

      input {
        background: url("../images/small-arrow-bottom.svg") no-repeat right
          to-em(18) center;
        background-size: to-em(12) to-em(8);
        margin-bottom: 0;
        border: none;
        @include input-focus-animation-reset();
      }

      .items-wrapper {
        display: none;

        position: relative;
        z-index: 1;
        background: #ffffff;
        padding: to-em(5);

        border-bottom: 1px solid #d4d4d4;

        .items {
          padding: 0 to-em(20);

          max-height: to-em(320);
          overflow-y: auto;

          .item {
            line-height: to-em(45);
            cursor: pointer;
          }
        }
      }

      &.opened {
        .line {
          margin: 0 to-em(5);
          border-top: 1px #d4d4d4 dashed;
        }

        border-bottom: none;

        .items-wrapper {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 940px) {
  .two-columns-layout {
    display: initial;

    .left,
    .right {
      max-width: 100%;
      height: auto;
      background: transparent;
      border: 0;
    }

    .left {
      display: none;
    }

    .right {
      .buttons.row {
        padding: to-em(24) 0;
      }
    }

    .login-page {
      margin: 0 auto;
      width: to-em(350);

      @media screen and (max-width: 400px) {
        margin: 0;
        width: auto;
      }

      .error {
        margin-bottom: to-em(16);
      }

      &.user-mobile-phone-verify {
        .fixed-height {
          min-height: to-em(320);

          .input-wrapper {
            .code-label {
              display: block;
              margin-top: to-em(-20);
              line-height: to-em(48);
              color: #4a4a4a;
              text-align: left;
            }

            .verification-code-wrapper {
              display: flex;
              justify-content: space-between;

              input.verification-code {
                width: to-em(55);
                text-align: center;
              }
            }
          }
        }

        &.code-form {
          .buttons.row {
            display: flex;
            justify-content: space-between;

            // input[role="button"] {
            //   width: 48%;

            //   &.resend {
            //     border-color: #7f7f7f;
            //   }
            // }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 900px), screen and (max-width: 1000px) {
  .two-columns-layout {
    .react-phone-number-input__icon-image {
      top: 10px;
    }
  }

  .two-columns-layout {
    font-size: $font-size-landing * $small-screen-zoom;

    .h1-wrapper {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .right-logo {
      background-size: 40px;
    }

    a,
    span,
    input,
    button,
    select,
    label {
      font-size: $font-size-landing * $small-screen-zoom;
    }

    .login-page {
      div.input-wrapper {
        .online-error {
          .tip {
            @include left-arrow(7px, #fbfbfb, #dcdcdc);
          }
        }
      }
    }
  }
}
