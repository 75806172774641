@import "../../../style/_variables";

$widget-padding: 20px;
$darkGrey: #4F4F4F;
$red: #DA547D;
$green: #4FAF67;
$blue: #0060AF;
$lightGrey: #d9d9d9;
$white: #fff;
$black: #000;
$orange: #F9A452;
$selectedOptionBackgroundColor: rgba(4, 96, 169, 0.14);
$selectedOptionBorderColor: rgba(4, 96, 169, 0.21);

.widget-shadow {
  -webkit-box-shadow:  inset 0 6px 5px -5px #c1c1c1;
  box-shadow:  inset 0 6px 5px -5px #c1c1c1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 6px;
  content: "";
}
.mobile {
  .widget-shadow {
    -webkit-box-shadow:  none;
    box-shadow: none;
  }
}

.box2colsWithVl {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  height: 100%;
  &.v3cols {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &.vncols {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .box2colsWithVl-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: 1px solid lightgrey;
  }
  .box2colsWithVl-valueBox {
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    &.green {
      color: $green;
    }
    &.red {
      color: $red;
    }
    &.blue{
      color: $blue;
    }
    &.black{
      color: $black;
    }
    &.clickable{
      cursor: pointer;
    }
  }
  .box2colsWithVl-labelBox {
    text-align: center;
    color: $darkGrey;
    font-size: 24px * $scale-koef;
    line-height: 1;
    margin-top: 10px;
    &.clickable{
      cursor: pointer;
      &:hover {
        color: var($--main-theme-color);
      }
    }
  }
}

.boxWithVl-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .boxWithVl-vl {
    height: calc(100% + 40px);
    margin-top: -$widget-padding;
    margin-bottom: -$widget-padding;
    width: 2px;
    display: inline-grid;
    border-right: 1px solid lightgrey;
  }
  .boxWithVl-valueBox {
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    &.green {
      color: $green;
    }
    &.red {
      color: $red;
    }
    &.blue{
      color: $blue;
    }
    &.black{
      color: $black;
    }
  }
  .boxWithVl-labelBox {
    text-align: center;
    color: $darkGrey;
    font-size: 24px;
    line-height: 1;
    margin-top: 10px;
    &.clickable{
      cursor: pointer;
      &:hover {
        color: var($--main-theme-color);
      }
    }
  }
}

.boxWithOptions {
  height: calc(100% + #{$widget-padding} + #{$widget-padding});
  width: calc(100% + #{$widget-padding});
  display: flex;
  margin-left: -$widget-padding;
  margin-top: -$widget-padding;
  margin-bottom: -$widget-padding;
  .boxWithOptions-optionsCol {
    height: 100%;
    width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $darkGrey;
    text-align: center;
    font-size: 16px;
    justify-content: start;
  }
  .boxWithOptions-optionsLabel {
    display: grid;
    align-content: center;
    flex: 1 0 0;
    border-right: 1px solid $lightGrey;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    justify-content: start;
  }
  .boxWithOptions-options {
    display: flex;
    background-color: #F0F0F0;
    flex: 4 0 0;
    flex-direction: column;
    border-right: 1px solid $lightGrey;
    border-top: 1px solid $lightGrey;
    padding: 5px 0px;
  }
  .boxWithOptions-option:hover {
    background-color: #ccc;
  }
  .boxWithOptions-option {
    align-content: center;
    flex: 1 0 0;
    margin: 5px;
    border-radius: 5px;
    padding-left: 10px;
    justify-content: start;
    cursor: pointer;
    &.selected {
      border: 1px solid $selectedOptionBorderColor;
      background-color: $selectedOptionBackgroundColor;
      cursor: default;
    }
  }
  .boxWithOptions-valueCol {
    height: 100%;
    width: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .boxWithOptions-valueBox {
    text-align: center;
    color: $red;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
  }
  .boxWithOptions-labelBox {
    text-align: center;
    color: $darkGrey;
    font-size: 24px * $scale-koef;
    line-height: 1;
    margin-top: 10px;
  }
}

.boxStatsWithGraph {
  height: 100%;
  display: flex;
  flex-direction: column;
  .boxStatsWithGraph-title {
    display: flex;
    justify-content: space-between;
    color: #4F4F4F;
    font-size: 18px;
    padding: 12px;
    margin-left: 6px;
    margin-right: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    .boxStatsWithGraph-title-red {
      font-size: 18px;
      color: #B4464A;
      line-height: unset;
    }
  }
  .boxStatsWithGraph-hl {
    width: calc(100% + #{$widget-padding} + #{$widget-padding});
    height: 1px;
    border-bottom: 1px solid $lightGrey;
    margin-left: -$widget-padding;
    margin-right: -$widget-padding;
  }
  .boxStatsWithGraph-stats {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .boxStatsWithGraph-statsBoxOf3 {
    width: calc(33.33% - 1px);
    display: flex;
    flex-direction: column;
    line-height: 1;
    &.ml {
      margin-left: 20px;
    }
    &.mr {
      margin-right: 20px;
    }
  }
  .boxStatsWithGraph-statsBoxOf2 {
    width: calc(50% - 1px);
    display: flex;
    flex-direction: column;
    line-height: 1;
    &.ml {
      margin-left: 20px;
    }
    &.mr {
      margin-right: 20px;
    }
  }
  .boxStatsWithGraph-statsVl {
    height: 100%;
    width: 1px;
    border-left: 1px solid $lightGrey;
  }
  .boxStatsWithGraph-statValue {
    font-size: 24px * $scale-koef;
    margin-top: 10px;
    margin-bottom: 5px;
    &.colorBlue {
      color: $blue;
    }
    &.colorRed {
      color: $red;
    }
    &.colorGreen {
      color: $green;
    }
    &.colorOrange {
      color: $orange;
    }
  }
  .boxStatsWithGraph-statLabel {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .boxStatsWithGraph-graph {
    width: 100%;
    flex: 1;
    min-height: 0;
    &:before {
      @extend .widget-shadow;
      top: 90px;
    }
    &.noStats:before {
      @extend .widget-shadow;
      top: 45px;
    }
    &.no-header:before {
      @extend .widget-shadow;
      top: 74px;
    }
    &.with-padding {
      padding: 10px;
    }
  }
}

.mapGraphHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.mapGraphLabelWithIcon {
  display: flex;
  justify-content: flex-start;
  height: 100%;
}
.mapGraphLabelWithIcon-IconContainer {
  align-content: center;
  display: grid;
}
.mapGraphLabelWithIcon-Icon {
  padding-left: 15px;
  align-items: center;
}
.mapGraphLabel {
  color: #4F4F4F;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
  height: 100%;
  display: flex;
  align-items: center;
}

.legendContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  padding-right: 20px;
  color: #4F4F4F;

  .signal-bars {
    margin-left: 10px;
  }
}

.heightMap {

  // hack for Safari - there is a issue with GoogleMaps and Safari browser issue, where
  // atributes from parent are overwrited. Need to add this attributes to "google" css-es.
  // Might fail if Google will change thier css or they will fix this bug which would be great.
  .gm-style {
    overflow:hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  height: calc(100% - 49px);
  button, [role="button"] {
    min-width: unset;
    padding: unset;
    margin: unset;
    height: unset;
  }
  button:hover, [role="button"]:hover {
    box-shadow: unset;
  }
}


.grid3cols2rows {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .grid3cols2rows-cell {
    height: 50%;
    width: calc(33%);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .grid3cols2rows-doubleCell {
    height: 50%;
    width: calc(66%);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .grid3cols2rows-cell-el {
    height: 100%;
    width: 100%;
    margin-top: -$widget-padding;
    margin-bottom: -$widget-padding;
    width: 2px;
    display: inline-grid;
  }
}

.heightMap:before {
  @extend .widget-shadow;
  top: 50px;
}

.widget-with-table {
  height: 100%;
  display: flex;
  flex-direction: column;
  .table-container {
    max-height: calc(100% - 74px);
    .simplebar-100percent-width {
      height: 100%;
      overflow-y: auto;
    }
  }
  .page-header {
    padding-top: 0px;
  }
}

.widgetContainer {
  container: widget2 / size;
  .widget-with-icon {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    padding: 5cqh 5cqw;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    margin-right: 1px;
    &.selected {
      box-shadow: inset 0 3px 10px 0px rgba(0, 0, 0, 0.22);
      background-color: #F0F0F0;
    }
    
    .icon-container {
      width: min(20cqw, 60cqh);
      min-width: min(20cqw, 60cqh);
      height: min(20cqw, 60cqh);
      border-radius: 100%;
      &.orange{
        background-color: #F4AE47;
      }
      &.green{
        background-color: #74B236;
      }
      &.pink{
        background-color: $color-error;
      }
      .widget-icon {
        width: 100%;
        height: 100%;
        background-color: #fff;
        mask-composite: exclude;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center;
        mask-position: center;

        &.medication {
          -webkit-mask: url("../../../images/rx/vitals/Icon_Pill.svg") no-repeat center/cover;
          mask: url("../../../images/rx/vitals/Icon_Pill.svg") no-repeat center/cover;
        }
        &.alert {
          -webkit-mask: url("../../../images/exclamation_mark.svg") no-repeat center/60%;
          mask: url("../../../images/exclamation_mark.svg") no-repeat center/60%;
        }
        &.pie {
          -webkit-mask: url("../../../images/Icon_pieChart.svg") no-repeat center/60%;
          mask: url("../../../images/Icon_pieChart.svg") no-repeat center/60%;
        }
        &.tick {
          -webkit-mask: url("../../../images/rx/icon_tick.svg") no-repeat center/60%;
          mask: url("../../../images/rx/icon_tick.svg") no-repeat center/60%;
        }
      }
    }

    .data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 5cqh;
      min-width: 0;
      height: 100%;
      white-space: nowrap;
      .title {
        color: #4F4F4F;
        font-size: min(8cqw, 22cqh);
        line-height: min(9cqw, 24cqh);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .value {
        font-size: min(20cqw, 25cqh);
        line-height: min(20cqw, 25cqh);
        font-weight: 600;
        color: black;
      }
    }

    &.grey {
      background-color: #F7F7F7;
      border: none;
      .widget-icon {
        background-color: #4F4F4F;
      }
      &.alerted {
        .icon-container {
          background-color: $color-error;
          border-radius: 10px;
          .widget-icon {
            background-color: #fff;
          }
        }
        .value {
          color: $color-error;
        }
      }
    }

    .collapse-icon {
      margin-left: auto;
    }
  }

}
@container widget2 (max-width: 180px) {
  .widget-with-icon {
    flex-direction: column;
      .data {
        flex-direction: row;
      }
    }
  }