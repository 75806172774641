@import "../../../style/_variables";

$new-scale-koef: 0.75;
$scale-koef: 0.75;
// $scale-koef: 0.875;
$font-color: #4F4F4F;
$font-secondary-color: #272727;
$active-color: #B1D590;

.medication_adherence {
  & > * {
    margin-right: 0;
    margin-left: 0;
  }

  .average {
    margin-left: 0 !important;
  }

  .averageText {
    margin-left: 24px*$scale-koef;
  }
}

.org-cap-manager-view {

  .text_overflow {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name_active {
    color: #14568D !important;
  }

  .stock {
    font-size: 22px*$scale-koef;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px*$scale-koef;
    height: 36px*$scale-koef;
    text-align: center;
    color: #14568D !important;
    border: solid 3px*$scale-koef $color-average;
    border-radius: 10px*$scale-koef;
    background: white;

    &.high {
      border-color: $color-good;
    }

    &.medium {
      border-color: $color-average;
    }

    &.low {
      border-color: $color-poor;
    }

    &.inactive {
      color: white !important;
      border-color: #D8D8D8 !important;
      background: #D8D8D8;
    }
  }

  .table.Medications {
    margin-top: 28px*$scale-koef;

    th {
      &, & * {
        color: black;
      }
    }

    td {
      &, & * {
        color: #4E4E4E;
      }
    }

    th {
      span {
        a {
          font-size: 24px*$scale-koef;
        }
      }
    }

    td {
      font-size: 18px*$scale-koef;
      height: 78px*$scale-koef;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    td.name {
      color: #14568D;
    }

    tr:hover {
      td:after {
        top: 5px !important;
        bottom: 5px !important;
        height: auto !important;
      }
    }

    tfoot {
      span {
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }

  .inactive {
    color: #DFDFDF;

    &.block {
      color: white;
      background-color: #DFDFDF;
    }
  }

  .status-conatiner {
    display: flex;

    .status-text {
      margin-left: 10px;
    }
  }

  .med-status {
    display: flex;
    align-items: center;

    .approval-status {
      width: 36px*$scale-koef;
      height: 36px*$scale-koef;
      background-size: 100% 100%;
    }
  }

  .imgText {
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
    }
  }

  .infoBox {
    .row {
      &.title {
        width: 100px;
        margin-top: 10px;
        text-align: left;
      }
    }
  }


  .checkbox_container {
    margin-left: 41px*$scale-koef;

    .input-content {
      position: relative;
      width: 26px*$scale-koef;
      height: 26px*$scale-koef;
      cursor: pointer;

      input[type="checkbox"] {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;

        &:focus {
          & + label {
            outline: 2px solid  var($--controls-color);
          }
        }
      }

      input[type="checkbox"] + label {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: url("../../../images/rx/uncheckedBox.svg") no-repeat;
        background-size: 100% 100%;

     
      }

      input[type="checkbox"]:checked + label {
        background-image: url("../../../images/rx/checkedBox.svg");
      }
    }

    span {
      font-size: 24px*$scale-koef;
      margin-left: 20px*$scale-koef;
      color: #4E4E4E;
    }
  }

  .right-select {
    display: flex;
    margin-left: auto;

  }

  .select-box-dashboard {

    select {
      font-family: GillSans;
      font-size: 27px * $scale-koef;
      overflow: hidden;
      padding-left: 30px;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $font-color;
      border: 0;
      background: transparent;
      background: url("../../../images/rx/selected-down.svg") no-repeat;
      background-position: left;
      background-size: 50px;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;

      &.left {
        padding: 8px 12px 13px 55px;
        padding-left: 30px;
        background-position: left;
      }

      &.right {
        max-width: 187px;
        padding: 8px 55px 13px 12px;
        padding-left: 30px;
        background-position: right;
      }
    }
  }

  .spinner-container {
    position: fixed;
    right: 0;
    left: 0;
    text-align: center;
  }


  .dose-block {
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;

    .dose {
      font-size: 15px;
      line-height: 2;
      display: inline-block;
      display: inline-block;
      width: 65px;
      height: 31px;
      text-align: center;
      color: white;
      border-radius: 25px * $scale-koef;
      background-color: #E6819F;

      &.high {
        background: #92DA7B;
      }

      &.medium {
        background: #EAAC6C;
      }

      &.low {
        background: #E6819F;
      }

      &.inactive {
        color: #DFDFDF;

        &.block {
          color: white;
          background-color: #DFDFDF;
        }
      }

    }

    .dose-text {
      font-size: 24px * $scale-koef;
      margin-left: 10px;
      color: $font-color;
    }
  }
}