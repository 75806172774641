@import "../style/variables";

.delete-timer-medicine {
  width: 30px;
  height: 30px;
  background: url("../images/rx/closeTimer.svg") no-repeat center;
  cursor: pointer;
  background-size: 30px;
  margin-left: 30px;
}

.delete-placeholder {
  width: 30px;
  height: 30px;
}

.add-timer-medicine {
  width: 30px;
  height: 30px;
  background: var($--bigRoundPlus-icon) no-repeat center;
  background-size: 30px;
  margin-left: 30px;
  cursor: pointer;
}

.add-timer-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.add-medicine {
  .modal-content {
    position: relative;
    background-color: #f7f7f7;
    padding: 60px * $scale-koef;
    height: 50vh;
    overflow: hidden;
    overflow-y: auto;
  }
}

.warning-red-text {
  color: #ff0000;
}

.dialog-form {

  &.country-select,
  &.user-timezone,
  &.select-org {
    // background-color: #f0f0f0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: #f0f0f0 url("../images/small-arrow-bottom.svg") no-repeat right 20px center !important;
    background-size: 10px !important;

    &.disabled {
      cursor: not-allowed;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  background: #f0f0f0;
  background-size: 10px !important;
}

input[type=number] {
  -moz-appearance: textfield;
  background: #f0f0f0;
  background-size: 10px !important;
}

.step1 {
  .label-control {
    margin: 15px 0px 12px;
  }

  &.closed {
    display: none;
  }
}

.add-patient-from-cap-manager {
  .row {
    margin-top: 20px;
  }


  input {
    border-radius: 13px;
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #d9d9d9;
    background-color: #f7f7f7;
  }
}


.step2 {
  .form {
    .label-control {
      flex: 1;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #4a4a4a;
    }

    .label {
      flex: 1;
      font-family: GillSans;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #808080;
    }
  }
}

.form-modal {
  border-radius: $modal-border-radius;
  overflow: hidden;
}

.rounded-borders {
  border-radius: 17.5px;
  overflow: hidden;
}

.display-grid {
  display: grid;
}

.attach-cap-to-org {
  .react-select__menu {
    max-height: 200px;

    .react-select__menu-list {
      max-height: 190px;
    }
  }
}

.attach-cap-to-org,
.edit-conditions,
.edit-facilities {
  .react-autosuggest__suggestions-container {
    max-height: 250px;
  }

  input[type="text"] {
    &.react-autosuggest__input {
      margin-bottom: 0;
    }
  }

  .modal-content {
    overflow-y: hidden;
  }

  .device-id-rows {
    max-height: 40vh;
    overflow-y: auto;
  }

  .header-label {
    margin-bottom: 30px;
    overflow-wrap: break-word;
  }

  label {
    color: #000000;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;

    .mb-20 {
      color: #4f4f4f;
      margin-bottom: 20px;
      overflow-wrap: break-word;
    }
  }


  .form-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    .cap-id-input {
      margin-bottom: 0;
      background: #f0f0f0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    }
  }

  .button-after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px*$scale-koef 20px*$scale-koef;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px*$scale-koef;
    min-width: auto;
    height: 46px*$scale-koef;
    margin: 0 4px 0 0;
    padding: 0;
    border-radius: 13px*$scale-koef;
    // background-image: linear-gradient(to bottom, #3097E1, #2080C3);
    background: var($--main-theme-color);

    &:disabled {
      opacity: 0.4;
    }

    &.plus {
      position: relative;
      margin-left: 10px;

      &:after {
        @extend .button-after;
        background-image: url("../images/plus.svg");
        background-size: 15px*$scale-koef 15px*$scale-koef;
      }

      &.dark {
        background: #14568D;
      }
    }

    &.minus {
      position: relative;
      &:after {
        @extend .button-after;
        background-image: url("../images/minus.svg");
        background-size: 15px*$scale-koef 15px*$scale-koef;
      }
    }

    &.reset {
      position: relative;
      &:after {
        @extend .button-after;
        background-image: url("../images/back-arrow.svg");
      }
    }
  }
}


.select-org {
  margin-bottom: 20px;
  position: relative;
  height: 63px;

  .spinner-container {
    height: 20px;
    width: 20px;
    position: absolute;
    min-height: 20px;
    right: 15px;
    top: 34px;
  }

  .user-timezone {
    color: #666666;
  }
}

.label-text {
  font-size: 17px;
  color: #000000;
  padding-bottom: 10px;
  display: block;
}

.extend-button {
  margin-top: 90px;
}

.dialog-overlay {
  z-index: 100;

  .spinner {
    z-index: 101;
  }

  div.dialog.box.form-modal {
    position: relative;

    .fill-box-container {
      text-align: center;
      position: absolute;
      top: 46px;
      right: 0;
      left: 0;

      .fill-box {
        display: inline-block;
        width: 51.3px;
        height: 3px;
        background-color: #e9e9e9;
        margin-right: 2px;

        &.active {
          background-color: #2492cf;
        }
      }
    }

    &.add-medicine,
    .add-kit {
      .content {
        max-height: 500px;
        overflow-y: scroll;
      }

      .opened {
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
      }

      .closed {
        width: 0;
        height: 0;
        opacity: 0;
        margin: 0;
        padding: 0;
        position: absolute;
      }

      .step2 {
        &.closed {
          display: none;
        }
      }

      .step2 {
        .form {
          .label-control {
            margin: 23px 0 8px;
          }

          .label {
            font-family: GillSans;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0px;
            color: #808080;
          }
        }
      }

      form {
        .errors-block {
          margin-top: 20px;

          div {
            margin-bottom: 10px;
            margin-top: 0;
          }
        }

        .autocomplete {
          height: 50px;
          overflow-y: visible;
          margin-bottom: 0;

          .select {
            @include input-hover-animation();
            border: 0;
            background: #ffffff;
            border-radius: 10px;

            input {
              background: url("../images/small-arrow-bottom.svg") no-repeat right 18px center;
              background-size: 12px 8px;
              margin-bottom: 0;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
              background-color: #f0f0f0;
              border-radius: 9.3px;
              color: #4a4a4a;
              margin-bottom: 0;
              height: 40px;
              line-height: 16px;
              border-radius: 12px;
              text-align: left;
              padding: 11px 20px;
              @include input-focus-animation-reset();

              &:focus,
              &:hover {
                background-color: #fff;
              }
            }

            &.opened {
              input {
                background-color: #fff;
              }
            }

            .items-wrapper {
              display: none;
              position: relative;
              z-index: 1;
              background: #ffffff;
              padding: 5px;
              border-bottom: 1px solid #d4d4d4;
              border-radius: 10px;

              .items {
                padding: 0px;
                max-height: 320px;
                overflow-y: auto;

                .item {
                  cursor: pointer;
                  cursor: pointer;
                  font-family: GillSans;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: 0px;
                  color: #808080;
                  padding: 10px 5px 10px 16px;
                  border-radius: 10px;

                  &:hover {
                    background-color: #ddd;
                  }
                }
              }
            }

            &.opened {
              .line {
                margin: 0 5px;
                border-top: 1px #d4d4d4 dashed;
              }

              border-bottom: none;

              .items-wrapper {
                display: block;
              }
            }
          }
        }

        input[type="text"] {
          &.rc-time-picker-input {
            width: 86px;
            height: 34px;
            text-transform: uppercase;
          }
        }

        .timer-box {
          display: inline-block;

          .add-timer {
            width: 30px;
            height: 30px;
            background: var($--bigRoundPlus-icon) no-repeat center;
            cursor: pointer;
            background-size: 30px 30px;
            display: block;
            float: right;
          }

          .timer-container {
            display: inline-block;
            position: relative;
            margin: 0 20px 20px 0;

            .delete-timer {
              width: 14px;
              height: 14px;
              min-width: 14px;
              padding: 0;
              margin-left: -10px;
              position: absolute;
              margin-top: -5px;
              background: url("../images/rx/closeTimer.svg") no-repeat center;
              position: absolute;
              right: -5px;
              top: -1px;
              cursor: pointer;
            }
          }
        }

        .rc-time-picker-clear-icon {
          margin-top: -9px;
          top: -1px;
          position: absolute;
          right: -3px;
        }

        .rc-time-picker-clear {
          padding: 0;
          margin: 0;
          top: -6px;
          background: #ddd;
          width: 14px;
          height: 14px;
          right: -2px;
          display: none;
        }

        .days-group {
          display: inline-flex;
          margin-right: 30px;

          input[type="text"] {
            width: 86px;
            float: right;
            height: 34px;
          }
        }

        .label-control {
          font-family: GillSans;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0px;
          color: #4a4a4a;
          margin: 23px 0 12px;
        }

        .for-radio {
          margin-bottom: 0;
          display: inline-block;
          margin-top: 2px;

          label {
            display: inline-block;
            width: 30px;
            height: 30px;
          }

          .label {
            font-family: GillSans;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0px;
            color: #808080;
            float: right;
            padding: 8px 12px;
          }
        }

        .checkbox-list {
          .checkbox-container {
            display: inline-block;
            width: 116px;

            .for-radio {
              margin-bottom: 12px;
            }
          }
        }

        .input-container input[type="radio"] {
          display: none;
        }

        .input-container input[type="checkbox"] {
          display: none;
        }

        input[type="radio"] {
          position: absolute;
          // visibility: hidden !important;
        }

        input[type="radio"]+label {

          &:before,
          &:after {
            content: "";
            position: absolute;
            box-sizing: border-box;
          }

          &:before {
            width: 30px;
            height: 30px;
            background: #f7f7f7 url("../images/rx/unCheckRadio.svg") no-repeat center;
          }

          &:after {
            width: 30px;
            height: 30px;
            transition: all 200ms ease-out;
          }
        }

        input[type="checkbox"]+label {

          &:before,
          &:after {
            content: "";
            position: absolute;
            box-sizing: border-box;
            border-radius: 0;
          }

          &:before {
            width: 30px;
            height: 30px;
            border-radius: 0;
            background: #f7f7f7 url("../images/rx/unChecked.svg") no-repeat center;
          }

          &:after {
            width: 30px;
            height: 30px;
            transition: all 200ms ease-out;
            border-radius: 0;
          }
        }

        input[type="radio"]:checked+label {
          &:after {
            background: #f7f7f7 url("../images/rx/checkRadio.svg") no-repeat center;
          }
        }

        input[type="checkbox"]:checked+label {
          &:after {
            border-radius: 0;
            background: #f7f7f7 url("../images/rx/checked.svg") no-repeat center;
          }
        }
      }
    }
  }
}

.outside-label {
  padding: 0 15px;
  color: $color-date-picker-label;
  font-size: $font-size-base;
}

.mb-15 {
  margin-bottom: 15px;
}

.flex-row {
  // display: flex;
  min-height: 200px;
}


.row-item {
  flex-basis: 335px;
  margin-right: 15px;
}

.configure-text {
  color: var($--main-theme-color);
  font-size: 25px;
  margin-bottom: 20px;
}

.row-parallel-view {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 23px 0 8px;
}

.row-vertical-view {
  flex: 1;
  // justify-content: space-between;
}

.new-flex-row {
  margin-top: 40px;
}



.datepickers-wrapper {
  display: flex;
  align-items: center;

  .datepicker-wrapper {
    display: flex;
    align-items: center;
    line-height: 30px;
    padding-left: 0px;

    .react-datepicker-popper {
      z-index: 99;
    }

    .react-datepicker__input-container {
      input {
        border-radius: 0;
        text-align: center;
        height: 36px;
        text-align: center;
        border-radius: 18px;
        text-align: center;
        box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: $color-background-input-new;
        color: $color-background-darkest;

        // @include input-focus-animation();

        &:focus {
          background-color: #fff;
          // box-shadow: 0 0 5px 0 transparentize(#56c8ed, 0.4);
          animation: pulse 2s infinite;
          animation-direction: alternate;
        }
      }

      input[type="text"],
      input[type="email"],
      input[type="password"] {
        &::placeholder {
          color: #b0b0b0;
          font-size: 12px;
        }

        &::-webkit-input-placeholder {
          color: #b0b0b0;
          font-size: 12px;
        }

        &::-moz-placeholder {
          color: #b0b0b0;
          font-size: 12px;
        }

        &:-ms-input-placeholder {
          color: #b0b0b0;
          font-size: 12px;
        }

        &:-moz-placeholder {
          color: #b0b0b0;
          font-size: 12px;
        }
      }
    }

    &.end-date-picker {
      .react-datepicker-popper .react-datepicker {
        left: calc((100% / 2) * (-1));

        >.react-datepicker__triangle {
          left: auto;
          right: 50px;
        }
      }
    }

    .react-datepicker {
      font-family: $font-family-base;
    }
  }
}

.rc-time-picker {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}

.rc-time-picker * {
  box-sizing: border-box;
}

.rc-time-picker-clear {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 3px;
  margin: 0;
  min-width: auto;

  &[role="button"] {
    min-width: auto;
  }
}

.rc-time-picker-clear-icon:after {
  content: "x";
  font-size: 12px;
  font-style: normal;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  height: 20px;
  width: 20px;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.rc-time-picker-clear-icon:hover:after {
  color: #666;
}

.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 28px;
  cursor: text;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rc-time-picker-input[disabled] {
  color: #ccc;
  background: #f7f7f7;
  cursor: not-allowed;
}

.rc-time-picker-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box;
}

.rc-time-picker-panel * {
  box-sizing: border-box;
}

.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}

.rc-time-picker-panel-narrow {
  max-width: 113px;
}

.rc-time-picker-panel-input {
  margin: 0;
  // padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
  padding: 6px 12px;
}

.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9;
}

.rc-time-picker-panel-input-invalid {
  border-color: red;
}

.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative;
}

.rc-time-picker-panel-select-active {
  overflow-y: auto;
}

.rc-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}

.rc-time-picker-panel-select:last-child {
  border-right: 0;
}

.rc-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}

.rc-time-picker-panel-select li {
  list-style: none;
  margin: 0;
  padding: 0 0 0 16px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;
}

.rc-time-picker-panel-select li:hover {
  background: #edfaff;
}

li.rc-time-picker-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold;
}

li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}

li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}

.dialog-overlay {
  z-index: 100;

  .password-wrapper {
    position: relative;

    .toggle-password {
      position: absolute;
      top: to-em(8);
      right: 8px;
      text-align: right;
      background: url("../images/rx/hidePassword.svg") no-repeat center;
      width: to-em(30);
      height: to-em(30);
      background-size: to-em(30);
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-top: 11px;
      margin-right: 8px;

      &:hover {
        background: url("../images/rx/hidePasswordHover.svg") no-repeat center;
        width: to-em(30);
        height: to-em(30);
        background-size: to-em(30);
      }
    }

    .toggle-text {
      position: absolute;
      top: to-em(8);
      right: 8px;
      text-align: right;
      background: url("../images/rx/password.svg") no-repeat center;
      width: to-em(30);
      height: to-em(30);
      background-size: to-em(30);
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-top: 11px;
      margin-right: 8px;

      &:hover {
        background: url("../images/rx/showPassword.svg") no-repeat center;
        width: to-em(30);
        height: to-em(30);
        background-size: to-em(30);
      }
    }
  }
}

.add-medicine-hour {
  .row.title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .taskTimerBox {
    height: 220px;
    padding: 4px;
    border-radius: 13px;
    border: solid 1px #bababa;
    background-color: #fff;
    justify-content: center;
  }

  &.full-width {
    margin-top: 10px;
    width: 100%;
  }

  .taskNoteArea {
    border-width: 0;
    resize: none;
    height: 150px;

    &:focus {
      outline: none;
      background-color: #fff;

    }

  }

  .limitShowDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0
  }



  .feild-lable {
    color: #4a4a4a !important;
    margin-bottom: 10px;
    width: 100%;
  }

  .img-input-container {
    cursor: pointer;
    border-radius: 10px;
    border-radius: 12px;
    position: relative;

    .step {
      display: none;
      position: absolute;
      width: 10px;
      height: 6px;
      margin-left: 14px;
      margin-top: 5px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      &:focus {
        opacity: 1;
      }

      &.up {
        transform: rotate(180deg);
      }

      &.down {
        margin-top: -10px;
        width: 11px;
      }
    }

    &:hover {
      background-color: var($--main-theme-color);
      color: white;

      .step {
        background-image: url("../images/rx/stepDown.svg");
        display: block;
      }

      input {
        color: white;
      }
    }
  }

  .timePicker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .timeStep {
      max-width: 40px;
      padding: 0px;
      text-align: center;
      background: transparent;
      border: 0;
      box-shadow: none;
      margin: 0;
      height: 46px;
      cursor: pointer;
      caret-color: transparent;

      &:hover {
        background-color: var($--main-theme-color);
        color: white;
        caret-color: transparent;
        cursor: pointer;
      }
    }
  }

  .ampmSwitch {
    display: flex;
    padding: 2px;
    border-radius: 13px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
    border: solid 1px #d9d9d9;
    background-color: #f7f7f7;
    width: 80px;
    height: 40px;
    margin: 0 0 0 9px;
    cursor: pointer;

    .block {
      width: 39px;
      height: 38px;
      padding: 10px 8px 11px 10px;
      object-fit: contain;
      border-radius: 11px;
      margin: -2px -1px;
    }

    .active {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: var($--main-theme-color);
      color: white;
    }
  }

  select {
    width: 100%;
  }

  label {
    &.t-lable {
      font-size: 18px;
    }

    &.t-lable-blue {
      font-size: 18px;
      color: var($--main-theme-color);
    }
  }

  .row-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    form {
      .row {
        margin: 28px 12px 28px 0;
      }

      input[type="text"] {
        height: 46px;

        padding: 10px;
        text-align: center;
        border-radius: 13px;
        border: solid 1px #bababa;
        background-color: #f7f7f7;
        box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.22);
        font-size: 18px;
        font-weight: 600;
        color: var($--main-theme-color);

        &.hardware-id {
          width: 100%;
          margin: 0;
          margin-top: 20px;
          margin-right: 20px;
          padding: 20px;
        }
      }
    }
  }
}

.cap-id {
  color: #000000;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  word-break: break-all;
}

.header-h2 {
  color: var($--main-theme-color);
  font-size: 40px;
  font-weight: 400;
}

.edit-conditions,
.edit-facilities {
  .form-row {
    column-gap: 10px;

    .item {
      &.name {
        width: 280px;
        max-width: 295px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 40px;
      }
    }

    button {
      &.plus {
        margin-left: 0;
      }
    }
  }
}

.edit-conditions,
.edit-facilities {
  .form-row {
    span {
      &.row {
        width: 90%;
      }
    }

    input[type="text"] {
      margin-bottom: 0;
    }
  }
}