.study-page-content {
  form {
    .row {
      display: block;
      label {
        display: block;
        margin-bottom: 10px;
      }

      &:first-child {
        margin-bottom: 15px;
      }
    }

    .button-bar {
      margin-top: 20px;
      justify-content: flex-start;

      > *:first-child {
        margin-right: 20px;
      }
    }
  }

  .plot-row {
    div.column {
      min-width: 27%;
    }
  }

  .geo-map-row {
    margin-top: -25px;
  }
}
