$old-scale-koef: $scale-koef;
$new-scale-koef: 0.90;

button.dark_blue, [role="button"].dark_blue {
  min-width: 266px * $scale-koef * $new-scale-koef;
  color: white;
  border: 0;
  // background: #14568D;
  background: var($--main-theme-color);
  box-shadow: none;
  border-radius: 13px;
  &:disabled {
    background: var($--main-theme-color);
    opacity: 0.3;
  }
}

.page-header {
  font-size: 27px * $scale-koef;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 74px;
  color: #4F4F4F;
  margin: 0;
  padding: 16px 0;
  position: relative;

  &.grow-right {
    .left {
      overflow: hidden;
      min-width: 150px !important;
    }

    .right {
      flex-shrink: 0;
    }
  }

  &.black {
    color: black;

    .left {
      font-weight: 400;
      letter-spacing: 0.5px;
    }
  }

  &.centered {
    justify-content: center;
  }

  button {
    font-size: 18px * $scale-koef;
    line-height: 46px * $new-scale-koef;
    width: auto;
    min-width: 95px * $new-scale-koef;
    height: 46px* $new-scale-koef;
    margin: 0;
    border-radius: 13px;
    border: solid 1px #bababa;
    background-color: #F7F7F7;
    color: #000000;

    &.refresh_button {
      width: 42px;
      min-width: 42px;
      height: 42px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("../images/rx/refresh.svg") no-repeat;
      background-size: 34px 34px;
      background-position: center center;
    
      &:disabled {
        background: url("../images/rx/refresh.svg") no-repeat;
        background-size: 34px 34px;
        background-position: center center;
        width: 42px !important;
        height: 42px !important;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.addfilter {
      min-width:  46px * $new-scale-koef;
      color: white;
      box-shadow: none;
      background: transparent;
      border-radius: 13px;
      border: solid 1px #bababa;
      color: #4f4f4f;
      padding-right: 40px;
      background-image: var($--plus-icon);
      background-repeat: no-repeat;
      background-position: right 15px center;
      background-size: 15px 15px;
      text-align: left;
      font-size: 18px *$new-scale-koef;
      font-weight: 600;
      
      &:disabled {
        opacity: 0.3;
      }
    }
  }

  input:not([type="checkbox"]), select {
    font-size: 18px * $scale-koef;
    line-height: 46px * $new-scale-koef;
    width: auto;
    min-width: auto;
    height: 46px* $new-scale-koef;
    margin: 0;
    border-radius: 13px;
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.22);
    border: solid 1px #bababa;
    background-color: #F7F7F7;
    color: #000000;
    padding-left: 22px;

    &:hover {
      box-shadow: none;
    }
    &[type="radio"] {
      width: 20px;
      box-shadow:none;    
      margin-right: 15px;  
      float: left;
      clear: none;
    }
  }

  .settings-button {
    height: 41px;
    width: 41px;
    border-radius: 7px;
    border: 1px solid #d9d9d9;
    .settings-button-icon {
      display: block;
      transform: translate(-1px, -1px);
      background-color: #4f4f4f;
      -webkit-mask: url("../images/rx/settings.svg") no-repeat center/70%;
      mask: url("../images/rx/settings.svg") no-repeat center/70%;
    }
  }
  .radiobox {
    font-size: 15.75px;
    float: left;
    clear: none;
    label {
      float: left;
      clear: none;
      line-height: 41px;
      margin-right: 30px;
      color:#000;
    }
  }

  .optionListContainer{
    line-height: 1.42857143em;
    font-size: 15px;
    color: #7f7f7f;
    width: fit-content;
  }
  
  .left, .right {
    line-height: 46px * $new-scale-koef;
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    align-items: center;

    .react-select-container .react-select__control .react-select__value-container .react-select__input-container {
      width: 150px;
      box-shadow: none;
    }
  }

  .left {
    padding: 0 25px 0 0 * $new-scale-koef;
    white-space: nowrap;
    input {
      &:first-child {
        margin-left: 1px;
      }
    }
  }

  &.no-left-padding {
    .left {
      padding-left: 0;
    }
  }

  button.dark_blue {
    min-width: 266px * $scale-koef * $new-scale-koef;
    color: white;
    border: 0;
    // background: #14568D;
    background: var($--main-theme-color);
    box-shadow: none;
    &:disabled {
      opacity: 0.3;
    }
  }

  select {
    min-width: 266px * $scale-koef * $new-scale-koef;
    background-image: var($--arrow-down-icon);
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 25px*$new-scale-koef;
    &:last-child {
      margin-right: 0px;
    }
  }
  
  .right {
    .spinner {
      position: static;
      display: block;
      width: 20px * $new-scale-koef;
      height: 20px * $new-scale-koef;
      animation: rotating 3s linear infinite;
      background: url("../images/rx/pendingLoader.png") no-repeat;
      background-size: 20px * $new-scale-koef;
      fill: transparentize(#91D0FF, $transparency);
    }

    input:not([type="checkbox"]):not(.searchBox):not(.react-select__input) {
      width: 322px * $scale-koef * $new-scale-koef;
      color: #000000;
      background-color: #F7F7F7;
      -moz-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);

      &::placeholder {
        color: #000;
      }
      padding-left: 22px;

      &.search {
        // background-image: url("../images/rx/searchIcon.svg");
        background-repeat: no-repeat;
        background-position: (400px * $new-scale-koef - 20px*$new-scale-koef - 10px) center;
        background-size: 20px*$new-scale-koef;
      }

      &[type="radio"] {
        width: 20px;
        box-shadow:none;
      }
    }

    .react-select-container .react-select__control .react-select__value-container input:not([type="checkbox"]) {
      width: 150px;
    }

    .multiselect-container .search-wrapper {
      margin: 0;
    }

    button {
      // min-width: 266px * $scale-koef * $new-scale-koef;
      min-width: unset;
      width: fit-content;
      white-space: nowrap;
      border: 0;
      box-shadow: none;

      &.blue {
        color: white;
        // background: linear-gradient(#3097E1, #2080C3);
        background: var($--main-theme-color);

        &:disabled {
          opacity: 0.3;
        }
      }

      &.white {
        border: #D9D9D9 1px solid;
      }

      &.plus {
        border: #D9D9D9 1px solid;
        background: url('../images/plus_blue.svg') no-repeat;
        //background-position: right 10px center;
        //background-size: 15px 15px;
        background-position: (115 - 20px*$new-scale-koef - 10px) center;
        background-size: 15px*$new-scale-koef;
        width: 115px;
        min-width: 115px;
        text-align: left;
      }

      &.refresh {
        background: url('../images/refresh2.svg') no-repeat center/contain;
        width:  46px * $new-scale-koef;
        min-width:  46px * $new-scale-koef;
        height:  46px * $new-scale-koef;
        border-radius: unset;
      }

      &.refreshTop {
        @extend .refresh;
        position: fixed;
        top: 17px;
        right: $margin-main;
        z-index: 99;
      }

      &.download {
        background: var($--download-icon) no-repeat center;
        width:  46px * $new-scale-koef;
        min-width:  46px * $new-scale-koef;
        height:  46px * $new-scale-koef;
      }
      &.next {
        background: var($--right-arrow-icon) no-repeat center;
        width:  46px * $new-scale-koef;
        min-width:  46px * $new-scale-koef;
        height:  46px * $new-scale-koef;
      }
      &.previous {
        background: var($--right-arrow-icon) no-repeat center;
        transform: rotate(180deg);
        width:  46px * $new-scale-koef;
        min-width:  46px * $new-scale-koef;
        height:  46px * $new-scale-koef;
      }
      &.addfilter {
        min-width:  46px * $new-scale-koef;
        border: solid 1px #bababa;
      }
      &.close-button {
        width: 42px;
        min-width: 42px;
        height: 42px;
        border-radius: 18px;
        margin: 0;
        padding: 0;
        -webkit-mask: url("../images/closeIcon.svg") no-repeat center/contain;
        mask: url("../images/closeIcon.svg") no-repeat center/contain;
        background-color: var($--main-theme-color);
      }
    }
  }

  .advanced-filters {
    .horizontal-line {
      display: none;
    }
    .advanced-filters-bar {
      margin-bottom: unset;
    }
  }
}

@media (max-width: $width-laptop-hidpi) {
  .page-header {
    font-size: 21px;
    margin:0;

    input:not([type="checkbox"]):not(.searchBox), button {
      font-size: 14px;
      line-height: 36px;
    }

    .left, .right {
      line-height: 36px;
    }

    .right {
      .react-select-container .react-select__control .react-select__value-container input:not([type="checkbox"]) {
        width: 160px;
      }
      input:not([type="checkbox"]):not(.searchBox) {
        width: 250px;

        &.search {
          background-position: 220px center;
        }

        &[type="radio"] {
          width: 20px;
          box-shadow:none;
        }
      }
      button {
        min-width: unset;
      }
    }
  }
}

.searchBox::placeholder { color: #000; }

.mobile .right button {
  &.refreshTop {
    top: 83px;
    right: 22px;
    width: 35px;
    min-width: 35px;
    height: 35px;
  }
}