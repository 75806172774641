@import "../../../style/_variables";

.config-tab-container {
  padding-bottom: 30px;

  .success-text {
    color: #76ac3d;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    padding: 0 20px;
  }

  .dd-title {
    font-size: 17px;
  }

  .page-header {
    button.brand-blue {
      background: var($--main-theme-color);
      color: white;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    &.revrese {
      flex-direction: row-reverse;
      align-items: center;
      gap: 10px;
    }
    input[type="text"] {
      width: 100%;
    }
    .react-select-container {
      width: 100%;
    }
  }

  .config-sections {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding-top: 20px;
  }

  .buttonContainer {
    width: 34px;
    height: 34px;
    min-width: 34px;
    border-radius: 18px;
    margin: 0;
    padding: 0;
    background-color: #F3F3F3;
  }        
  .minimize-button {
    width: 34px;
    height: 34px;
    border-radius: 18px;
    min-width: 34px;
    margin: 0;
    padding: 0;
    -webkit-mask: url("../../../images/leftArrow.svg") no-repeat center/contain;
    mask: url("../../../images/leftArrow.svg") no-repeat center/contain;
    background-color: var($--main-theme-color);
    transform: rotate(270deg);
    &.collapsed {
      transform: rotate(90deg);
    }
  }

  .config-section-header {
    display: flex;
    align-items: center;
    justify-content: left;
    column-gap: 20px;
  }

  .config-section-subheader {
    border-top: 1px solid #b7b7b7;
    padding-top: 15px;
    margin: 0;
    font-weight: 600;
    color: #4f4f4f;
    label {
      font-weight: 600;
      color: #4f4f4f;
    }
  }

  .inputs-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    &.checkboxes {
      grid-template-columns: 1fr 1fr 1fr 1fr;  
    }
    .config-section-subheader {
      border-top: none;
      padding-top: 0;
      font-weight: 400;
      color: unset;
      label {
        font-weight: 400;
        color: unset;
      }
    }
  }

  button.brand-blue {
    align-self: start;
  }
}