@import '../../../style/variables';

.message-textbox {
    border-radius: 16px;
}
.message-textbox__suggestions{
  border: #bababa solid 1px;
  border-radius: 13px;
  z-index: 2 !important;
  .suggestions-scrolled-container {
      max-width: 400px;
      max-height: 300px;
      padding: 15px;
      overflow-y: auto;
      .os-scrollbar-vertical {
        right: 3px;
        margin-top: 3px;
        margin-bottom: 3px;
    }

      .suggestion-flex {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          font-size: 18px * $scale-koef;
      }
  }
  .message-textbox__suggestions__item {
      border-radius: 8px;
      padding: 12px * $scale-koef;
      &.message-textbox__suggestions__item--focused,
      &:hover {
          background-color: var($--opaque-main-color-7);
          cursor: pointer;
      }
      .tag-suggestion {
          .tag-suggestion-header {
              display: flex;
              justify-content: space-between;
              .tag-suggestion-header-title {
                  font-weight: 600;
                  color: #000;
              }
              .tag-suggestion-header-shortcut {
                  color: #B1B1B1;
              }
          }
      }
  }
}

.mentions__mention {
    position: relative;
    z-index: 1;
    color: var($--main-theme-color);
    pointer-events: none;
  }