.external-api-key-config-container {
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    width: 900px;

    textarea {
        resize: none;
        width: 800px;
        height: 400px;
    }

    .textarea {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.external-api-modal-client-value-label {
    overflow-wrap: break-word;
    font-weight: bold;
}

.external-api-modal-text-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .client-value-label {
        overflow-wrap: break-word;
        font-weight: bold;
    }

    .notice {
        font-weight: bold;
        color: red;
    }
}

.care-provider-api-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .care-provider-enable-row {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
}