@import "../../style/variables";

.tab-view {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;

  .nav-back {
    margin: 0;
  }

  .tab-back {
    display: inline;
    width:100px;
  }
  .carousel {
    display: inline-block;
    align-self: center;
  }

  .swiper {
    margin-left: 0;
    margin-right: 0;
  }
  .swiper-slide {
    width: unset;
    display: flex;
    align-items: center;
  }
  .swiper.swiper-initialized.swiper-horizontal.with-navigation-buttons {
    padding-left: 42px + 15px;
    padding-right: 42px + 15px;
  }
  .swiper-button-next, .swiper-button-prev {
    height: 100%;
    top: 0;
    margin: 0;
    width: 42px + 15px;
    &::after {
      content: "";
      border-radius: 13px;
      border: solid 1px #bababa;
      background-color: #fff;
      width: 42px;
      min-width: 42px;
      height: 42px;
      background-image: var($--sideNav-arrow-icon);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .swiper-button-prev {
    left: 0;
    justify-content: start;
    background: linear-gradient(to left, rgba(245, 245, 245, 0) 0px, $color-background-input-new 15px);
    &::after {
      transform: rotate(180deg);
    }
  }
  .swiper-button-next {
    right: 0;
    justify-content: end;
    background: linear-gradient(to right, rgba(245, 245, 245, 0) 0px, $color-background-input-new 15px);
  }

  &.patients-tab {
    div.tabs {
      display: inline-block;
      line-height: 66px;
      padding: 0;
      font-size: $font-size-base;
      left: -1px;
      height: 66px;
      border-top: solid 0.7px $color-border-base;
      border-bottom: solid 0.7px $color-border-base;
      background-color: $color-background-input-new;
      margin-top: 1px;
    }
  }

  ul.tabs,
  div.tabs {
    flex: 0;
    left: 217px;
    margin: 0 (-$margin-main);
    padding: 0 $margin-main;
    display: flex;
    line-height: $header-height-normal;
    font-size: $font-size-base;
    min-height: $header-height-normal;
    background-color: $color-background-input-new;
    border: solid 1px #ebebeb;
    border-right: 0;
    border-left:0;
    background-color: #f7f7f7;
    z-index: 9;
    li,
    a {
      color: #000000;
      float: left;
      padding: 7px 20px;
      margin: 0;
      vertical-align: middle;
      border-radius: 18px;
      user-select: none;
      text-align: center;
      cursor: default;
      transition: background-color $animation-swift-out,
        color $animation-swift-out;
      &:not(.active):hover {
        cursor: pointer;
      }
      position: relative;
      .carousel & {
        margin: 0;
      }

      &.active {
        color: #FFF;
        position: relative;
        background-color: var(--secondary-theme-color);
      }
    }
  }

  .tab-content {
    flex: 1;
    display: none;
    position: relative;
    // solution for scrollbar drawn 30 px from right screen edge:
    padding-left: $margin-main;
    padding-right: $margin-main;
    margin-left: -$margin-main;
    margin-right: -$margin-main;
    &.active {
      display: flex;
      flex-direction: column;
      padding-bottom: 25px;
    }
  }
}

.mobile {
  .tab-view {
    div.tabs {
      min-height: unset;
    }
    .swiper-slide {
      min-height: 50px;
    }
  }
  .swiper-button-next, .swiper-button-prev {
    width: 35px + 15px;
    &::after {
      width: 35px;
      min-width: 35px;
      height: 35px;
    }
  }
  div.tabs {
    margin: 0 (-$margin-mobile);
    padding: 0 $margin-mobile;
  }
  .tab-content {
    padding-left: $margin-mobile;
    padding-right: $margin-mobile;
    margin-left: -$margin-mobile;
    margin-right: -$margin-mobile;
  }
}