@import '../../style/variables';

.signal-high span{
    background-color: #74b236 !important;
}

.signal-highm span:nth-child(1){
    background-color: #74b236 !important;
}
.signal-highm span:nth-child(2){
    background-color: #74b236 !important;
}
.signal-highm span:nth-child(3){
    background-color: #74b236 !important;
}

.signal-lowm span:nth-child(1){
    background-color: #f9a353 !important;
}
.signal-lowm span:nth-child(2){
    background-color: #f9a353 !important;
}

.signal-low span:nth-child(1){
    background-color: #da547d !important;
}

.signal-bars.signal-unknown span:nth-child(-n+3){
    height: 0px;
}
.signal-bars.signal-unknown span:nth-child(4){
    width: 0px;
}

.signal-bars{
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.signal-bars span{
    margin-right: 2px;
    border-radius: 2px;
    background-color: #dedede;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.22);
    width: 5px;
}

.signal-bars span:first-child{
    height: 6px;
}

.signal-bars span:nth-child(2){
    height: 9px;
}

.signal-bars span:nth-child(3){
    height: 12px;
}

.signal-bars span:nth-child(4){
    height: 15px;
}
// .signal-100 span{
// }

.signal-alert {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-color: $color-error;
    -webkit-mask-size: 75%;
    mask-size: 75%;
    -webkit-mask-image: url('../../images/exclamation_mark.svg');
    mask-image: url('../../images/exclamation_mark.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    &.info {
        background-color: #F7D23A;
    }
}

.status-with-info {
    display: flex;
}

.reminders-container {
    height: 26px;
    width: 26px;
    border-radius: 20px;
    border: 1px solid #bababa;
    //position: absolute;
    background-color: #fff;
    &.no-border {
      border: unset;
    }
    .reminders {
        background-size: 26px 26px;
        background-position: left;
        background-repeat: no-repeat;
        height: 26px;
        transform: translate(0px, -1px);
    
        &.on {
            background-color: #74b236;
            -webkit-mask: url("../../images/actions/reminder.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
            mask: url("../../images/actions/reminder.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
            mask-composite: exclude;
            border-radius: 20px;
        }
        &.off {
            background-color: #bababa;
            -webkit-mask: url("../../images/actions/reminder.svg") no-repeat center/contain;
            mask: url("../../images/actions/reminder.svg") no-repeat center/contain;
        }
    }
}

