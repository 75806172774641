.relative-div {
  position: relative;
}
.timestamp {
  margin-top: 20px;
  .year-navigation {
    display: flex;
    justify-content: space-between;

    .navigation-buttons {
      flex: none;
    }

    .months {
      display: flex;
      flex-wrap: nowrap;
      padding: 3px;
      margin-top: 6px;
      flex-basis: 58%;
      .month {
        flex: 1 1 100%;
        height: 8px;
        background-color: #2492cf;
        border-right: 1px solid #fff;
        box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.13);

        &.active-period {
          background-color: rgba(36, 146, 207, 0.2);
          cursor: pointer;

          &:hover {
            background-color: #2492cf;
            border-radius: 10px;
          }

          &.active-month {
            background-color: #2492cf;
            border-radius: 10px;
          }
        }

        &.disabled {
          background-color: #f7f7f7;
        }

        &:first-of-type {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:nth-last-child(1) {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  .caption {
    &.date {
      width: 65px;
      text-align: center;
      display: inline-block;
    }
  }

  button {
    background: white;
    color: #929292;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.06);
    padding: 0;
    width: 67px;
    height: 24px;
    border-radius: 20px;
    min-width: 67px;
    margin: 0 15px;

    &.right {
      margin-right: 0;
    }

    &.disabled {
      background-color: #f9f9f9;
      pointer-events: none;
      cursor: not-allowed;
    }

    &.next {
      // border-radius: 0 5px 5px 0;
      // margin-left: -1px;
    }

    &.prev {
      // border-radius: 5px 0 0 5px;
    }

    &.adherence-arrow {
      &:after {
        content: " ";
        width: 10px;
        height: 14px;
        background: url("../images/adherence-arrow.png");
        display: block;
        margin: auto;
      }

      &.left:after {
        background-position-y: 14px;
        background-position-x: 10px;
      }

      &.right:after {
        // background-position-y: 14px;
        background-position-x: 10px;
      }

      &.disabled:after {
        background-position-x: 10px;
      }
    }
  }
}
