@import "../../style/variables";

.react-autosuggest__input {
  display: block;
  width: 100%;
  height: 46px*$scale-koef;
  line-height: 46px*$scale-koef;
  border-radius: 13px*$scale-koef;
  text-align: left;
  border: 1px solid #D9D9D9;
  font-size: 18px*$scale-koef;
  color: #4f4f4f;
  background: #F7F7F7;
  margin-bottom: 20px*$scale-koef;
  outline: none;

  -webkit-box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: $width-tablet) {
    height: 46px*0.75*$scale-koef;
    line-height: 46px*0.75*$scale-koef;
    font-size: 18px*0.75*$scale-koef;
    margin-bottom: 20px*0.5*$scale-koef;
  }

  &::placeholder {
    opacity: 0.5;
  }

  @include input-focus-animation();
}

.autosuggest.invalid-control-background {
  background-color: unset;
  .react-autosuggest__input {
    background-color: $color-required-background;
  }
}

.react-autosuggest__suggestions-container {
  max-height: 364px*$scale-koef;
  background-color: #F7F7F7;
  overflow: auto;
  position: absolute;
  display: none;
  margin-top: 5px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  border: 1px solid #D9D9D9;
  -webkit-box-shadow: 0 0 5px 0#0091FF;
  -moz-box-shadow: 0 0 5px 0#0091FF;
  box-shadow: 0 0 5px 0#0091FF;
}

.react-autosuggest__suggestion {
  position: relative;
  list-style-type: none;
  padding: 22px*$scale-koef;
  margin: 0;
  color: #4F4F4F;
  font-size: 18px*$scale-koef;
  background-color: #F7F7F7;
  border-bottom: 1px solid #EAEAEA;
  cursor: pointer;
  z-index: 10;

  &:last-child {
    border-bottom: none;
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(216, 216, 216, 0.25);
}

.autosuggest-error {
  color: $color-brand-red;
  margin: 0 0 13px*$scale-koef 13px*$scale-koef;
  margin-top: -15px;
}