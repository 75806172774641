.wizard.recover-password {
  min-width: unset;
  max-width: unset;
  background-color: #FBFBFB;
  border: none;
  border-radius: unset;
  box-shadow: none;
  .content-block {
    min-height: unset;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #FBFBFB;
  }
  p {
    color: #000;
    margin: 0;
  }
}
