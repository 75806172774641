.tooltip-day {
  color: #2492cf;
}

.tooltip-day-1 {
  color: #000;
}

.timestamp-tool-tips {
  .timestamp-date-value {
    width: 110;
    border-radius: 0;
    border-color: #d5d5d5;
    text-align: center;
    color: $color-plot-timestamp-dot;
    font-size: 12px;
    line-height: 1;
    border-radius: 10px;
    padding: 4px 10;
  }
}

.timestamp-date-value {
  width: 110;
  border-radius: 0;
  border-color: #d5d5d5;
  text-align: center;
  color: $color-plot-timestamp-dot;
  font-size: 12px;
  line-height: 1;
  border-radius: 10px;
  padding: 4px 10;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  font-size: 12px;
  border: 0;
  color: #222;

  .tooltip-day {
    color: #222;
    line-height: 1.5em;
    font-size: 12px;

    .label1 {
      width: 100px;
      text-align: right;
      display: inline-block;
      padding-right: 10px;
    }

    .value1 {
      width: 50px;
      text-align: left;
      display: inline-block;
    }
  }

  &::before {
    border-bottom-color: #f1f1f1;
  }
}

.y-axis-label {
  background: #e5f0f6;
  width: 30px;
  height: 238px;
  position: absolute;
  border-radius: 20px;
  margin-left: 23px;
  margin-top: 34px;
  display: inline-block;

  .y-axis-days {
    width: 20px;
    text-align: center;
    margin: 16px 5px 19px;
    font-size: 13px;
    line-height: 1;
    text-align: center;
    height: 27px;
    padding: 6px 0;

    border-radius: 10px;

    &:hover {
      background: #fff9f9;

      &:after {
        margin-left: 16px;
        margin-top: -12px;
        position: absolute;
        width: 1000px;
        height: 37px;
        z-index: 99999;
        background: #fff;
        content: "";
        border-radius: 22px;
        opacity: 0.4;
      }
    }
  }
}

.y-axis-label-week-end {
  width: 30px;
  height: 84px;
  background-color: #f6f0e5;
  position: absolute;
  margin-top: 274px;
  border-radius: 20px;
  margin-left: 23px;

  .y-axis-days {
    width: 20px;
    margin: 5px 5px 19px;
    font-size: 13px;
    line-height: 1;
    text-align: center;
    height: 27px;
    padding: 6px 0;
    border-radius: 10px;

    &:hover {
      background: #fff9f9;

      &:after {
        margin-left: 16px;
        margin-top: -12px;
        position: absolute;
        width: 1000px;
        height: 37px;
        z-index: 99999;
        background: #fff;
        content: "";
        border-radius: 22px;
        opacity: 0.4;
      }
    }
  }
}

svg.plot.timestamp {
  height: 413px;
  padding: 20px 0;
  circle.dot {
    cursor: pointer;

    //fill: $color-plot-timestamp-dot;

    stroke: #92da7b;
    fill: rgba(146, 218, 123, 0.5);
  }

  .grid {
    &.vertical {
      path.domain {
        stroke: #d5d5d5;
        shape-rendering: crispEdges;
      }
    }
  }

  .axis {
    g.tick {
      line {
        stroke: #f0f0f0;
        // shape-rendering: crispEdges;
      }

      text {
        font-size: 14px; //$font-size-large;
        fill: #929292;
      }
    }

    &.middle {
      g.tick {
        text {
          font-size: 14px;
          color: #929292;
        }
      }
    }

    &.middle-vertical {
      g.tick {
        text {
          text-anchor: middle;
        }
      }
    }

    path.domain {
      stroke: #d5d5d5;
      shape-rendering: crispEdges;
      display: none;
    }
  }

  g.guide {
    line {
      opacity: 0;
      shape-rendering: crispEdges;
    }
  }
  g.guide {
    text.guide-tooltip {
      fill: white;
      font-size: $font-size-large;
      font-weight: bold;
      opacity: 0;
      text-anchor: middle;
    }

    rect.guide-tooltip {
      fill: $color-plot-timestamp-dot;
      shape-rendering: crispEdges;
      opacity: 0;
    }
  }
}

.timestamp-tool-tips {
  .timestamp-value {
    width: 90px;
    border-radius: 0;
    border-color: #d5d5d5;
    text-align: center;
    color: $color-plot-timestamp-dot;
    font-size: 12px;
    z-index: 9999;
    border-radius: 10px;
    padding: 7px 0;

    &::before {
      border: none;
      margin-left: 0;
    }
  }
}
