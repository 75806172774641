@import "../style/variables";

$width: 520px;
$height: 420px;
$paddingVer: 40px;
$paddingHor: 70px;

.dashboard-page-content {

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-left: 18.75px;
  padding-top: 25px;

  .info-box {
    margin: 0;
    border: 1px solid #D9D9D9;
    background: #fbfbfb;
    padding: $paddingVer*$scale-koef $paddingHor*$scale-koef;
    border-radius: 20px*$scale-koef;
    max-width: $width*$scale-koef;
    height: $height*$scale-koef;
    flex: 1;
    display: flex;
    flex-direction: column;

    text-align: left;
    font-size: 18px*$scale-koef;
    line-height: 28px*$scale-koef;
    color: #808080;

    position: relative;

    @media (max-width: 1700px) {
      max-width: $width*$scale-koef*0.95;
      height: $height*$scale-koef*0.95;
      padding: $paddingVer*$scale-koef*0.9 $paddingHor*$scale-koef*0.9;
    }

    @media (max-width: 1600px) {
      max-width: $width*$scale-koef*0.9;
      height: $height*$scale-koef*0.9;
      padding: $paddingVer*$scale-koef*0.8 $paddingHor*$scale-koef*0.8;
    }

    @media (max-width: 1530px) {
      max-width: $width*$scale-koef*0.85;
      height: $height*$scale-koef*0.85;
      padding: $paddingVer*$scale-koef*0.7 $paddingHor*$scale-koef*0.7;
    }

    @media (max-width: 1450px) {
      max-width: $width*$scale-koef*0.8;
      height: $height*$scale-koef*0.8;
      padding: $paddingVer*$scale-koef*0.6 $paddingHor*$scale-koef*0.6;
    }

    @media (max-width: 1439px) {
      max-width: 230px;
      height: 315px;
      padding: $paddingVer*$scale-koef*0.35 $paddingHor*$scale-koef*0.25;
    }

    a {
      color: #0093d5;
      font-weight: 600;
      cursor: pointer;
      font-size: inherit;
      line-height: inherit;
      text-transform: lowercase;
    }

    header {
      text-align: center;
      font-size: 24px*$scale-koef;
      color: #4F4F4F;
      margin-bottom: 35px*$scale-koef;

      @media (max-width: 1439px) {
        margin-bottom: 35px*$scale-koef*0.5;
      }
    }

    .footer {
      margin-top: auto;

      button {
        margin: 0;
        width: auto;
      }
    }
  }
}

.no-data-yet {
  border: 1px solid #D9D9D9;
  color: #4F4F4F;
  margin-left: 18.75px;

  padding-top: 40px*$scale-koef;
  padding-bottom: 40px*$scale-koef;
  padding-left: 107px*$scale-koef;
  font-size: 24px*$scale-koef;
  border-radius: 20px*$scale-koef;
  background: #fbfbfb url("../images/Cap Ready@2x.png") no-repeat 50px*$scale-koef center;
  background-size: 36px*$scale-koef 36px*$scale-koef;
}