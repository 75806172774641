@import "./variables";

.activated-page {
  max-width: 530px;
  margin: auto;
  padding-top: 158px;

  text-align: center;

  .image {
    width: 293px;
    height: 293px;

    background: url("../images/confirmation-tick-icon.png") no-repeat;
    background-size: 293px 293px;

    margin: auto;
  }

  h1 {
    font-size: 28px;
    line-height: 28px;
    color: $color-brand;
    margin: 0;
    padding-top: 66px;
    padding-bottom: 58px;
  }

  .caption {
    font-size: 16px;
    line-height: 28px;
  }

  .download-container {
    display: none;
  }

  &.mobile {
    padding-top: 55px;

    .image {
      width: 147px;
      height: 147px;

      background-size: 147px 147px;
    }

    h1 {
      font-size: 18.5px;
      line-height: 18.5px;
      padding-top: 33px;
      padding-bottom: 24px;
    }

    .caption {
      font-size: 13px;
      line-height: 21px;

      margin: auto;
      margin-bottom: 33px;
      padding: 0 10px;
      max-width: 350px;
    }

    .download-container {
      display: block;

      a {
        display: block;
        padding-bottom: 17px;

        &#ios {
          img {
            width: 176px;
          }
        }
        &#android {
          img {
            width: 200px;
          }
        }
      }
    }
  }
}