@import '../../style/variables';

.update-task {
  flex: auto 1 1;
  max-width: 600px;

  .row.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .taskTimerBox {
    height: 220px;
    padding: 4px;
    border-radius: 13px;
    border: solid 1px #bababa;
    background-color: #fff;
    justify-content: center;
  }
  &.full-width {
    margin-top: 10px;
    width: 100%;
  }

  .taskNoteArea {
    border-width: 0;
    resize: none;
    height: 150px;

    &:focus {
      outline: none;
      background-color: #fff;
    }
  }
  .limitShowDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
  }

  .feild-lable {
    color: #000;
    margin-bottom: 10px;
  }

  .img-input-container {
    cursor: pointer;
    border-radius: 10px;
    border-radius: 12px;
    position: relative;
    &.disabled {
      cursor: unset;
    }
    .step {
      display: none;
      position: absolute;
      width: 10px;
      height: 6px;
      margin-left: 22px;
      margin-top: 5px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      &:focus {
        opacity: 1;
      }

      &.up {
        transform: rotate(180deg);
      }

      &.down {
        margin-top: -10px;
        width: 11px;
      }
    }

    &:hover {
      background-color: var($--main-theme-color);
      color: white;
      .step {
        background-image: url("../../images/rx/stepDown.svg");
        display: block;
      }

      input {
        color: white;
      }
    }
  }

  .timePicker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .timeStep {
      max-width: 50px;
      padding: 0px;
      text-align: center;
      background: transparent;
      border: 0;
      box-shadow: none;
      margin: 0;
      height: 46px;
      cursor: pointer;
      caret-color: transparent;

      &:hover {
        background-color: var($--main-theme-color);
        color: white;
        caret-color: transparent;
        cursor: pointer;
      }
    }
  }

  .ampmSwitch {
    display: flex;
    padding: 2px;
    border-radius: 13px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
    border: solid 1px #d9d9d9;
    background-color: #f7f7f7;
    width: 80px;
    height: 40px;
    margin: 0 0 0 9px;
    cursor: pointer;
    &.disabled {
      cursor: unset;
    }

    .block {
      width: 39px;
      height: 38px;
      padding: 10px 8px 11px 10px;
      object-fit: contain;
      border-radius: 11px;
      margin: -2px -1px;
      &.disabled {
        cursor: unset;
        filter: grayscale(100%);
        opacity: .5;
      }
    }

    .active {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: var($--main-theme-color);
      color: white;
    }
  }

  select {
    width: 100%;
  }

  label {
    &.t-lable {
      font-size: 18px;
    }
    &.t-lable-blue {
      font-size: 18px;
      color: var($--main-theme-color);
    }
  }

  .row-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    overflow: unset;
    form {
      .row {
        margin: 28px 12px 28px 0;

        &.taskName {
          margin-top: 0;
        }
      }

      input[type="text"] {
        height: 46px;
        width: 150px;
        padding: 10px;
        text-align: center;
        border-radius: 13px;
        border: solid 1px #bababa;
        background-color: #f7f7f7;
        box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.22);
        font-size: 18px;
        font-weight: 600;
        color: var($--main-theme-color);

        &.hardware-id {
          width: 100%;
          margin: 0;
          margin-top: 20px;
          margin-right: 20px;
          padding: 20px;
        }
      }
    }
  }

  .edit-task-button-container {
    position: absolute;
    top: -65px;
    right: 52px;
    height: 34px;
    width: 34px;
    border-radius: 7px;
    border: 1px solid #d9d9d9;
    position: absolute;
    background-color: #fff;
    &.edit-mode-on {
      border-color: transparent;
    }
  }
  .edit-task-button {
    display: block;
    height: 34px;
    width: 34px;
    background-size: 100%;
    background-color: var($--main-theme-color);
    border-radius: 7px;
    transform: translate(-1px, -1px);
    min-width: unset;
    padding: 0;
    margin: 0;
    background-color: var($--main-theme-color);
    -webkit-mask: url("../../images/rx/edit.svg") no-repeat center/60%;
    mask: url("../../images/rx/edit.svg") no-repeat center/60%;

    &.edit-mode-on {
      -webkit-mask: url("../../images/rx/edit.svg") no-repeat center/60%, linear-gradient(#fff 0 0);
      mask: url("../../images/rx/edit.svg") no-repeat center/60%, linear-gradient(#fff 0 0);
      mask-composite: exclude;
    }
    }
}
