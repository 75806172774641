@import "./variables";

.breadcrumbs {
  margin: 0;
  padding: 0;
  float: none;
  text-align: left;
  position: absolute;
  top: 22px;
  left: 250px;

  @media (max-width: $width-laptop-hidpi) {
    top: 15px;
  }

  > .breadcrumb {
    margin: 0;
    padding: 0;
    background-color: transparent;
    list-style: none;
    border-radius: 4px;

    li {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 425px;

      > a,
      > button {
        color: #878787;
        font-size: 1em;

        height: auto;
        border: none;
        padding: 0;
        vertical-align: inherit;
        line-height: inherit;
        box-sizing: inherit;
        box-shadow: none;
        transition: all 0.45s cubic-bezier(0.3, 1, 0.4, 1) 0s;
        user-select: none;
        display: inline-block;
        background: none;
        min-width: auto;
      }

      > a:hover,
      > button:hover {
        color: #1f7dc2;
      }

      + li::before {
        padding: 0 20px;
        color: #878787;
        font-weight: bold;
        content: "/\00a0";
      }

      :not(:first-of-type)::before {
        content: ">";
      }
    }

    li:not(:first-of-type)::before {
      content: ">";
    }

    li:last-of-type {
      color: #1f7dc2;
    }

    li:first-of-type {
      color: inherit;
    }
  }

  &.dashboard {
    > .breadcrumb {
      font-size: 27px;
      color: #4f4f4f;

      li {
        overflow: visible;
      }
    }
  }
}

.app-root {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: hidden;

  .app-main {
    display: flex;
    flex-flow: row nowrap;
    flex: auto 1 1;
    min-height: 0; // firefox scroll fix
    overflow: hidden;

    &.mobile {
      flex-direction: column;
      overflow-y: auto;
    }

    .app-content-container {
      flex: auto 1 1;
      overflow: auto;
      overflow-y: hidden;
      .app-content {
        display: flex;
        flex-direction: column;
        min-width: 900px;
        padding: 0 $margin-main;
        height: 100%;
        &.mobile {
          min-width: 0;
          padding: 0 $margin-mobile;
          overflow-y: auto;
        }

        .details-container {
          flex: 1;
          min-height: 0;
          display: flex;
          flex-direction: column;
        }

        .search-input {
          position: absolute;
          left: 50%;
          margin-left: -107px;
          top: 15px;
        }

        .search-input.loop {
          height: 34px;
          line-height: 34px;
          border-radius: $border-radius;
          background: url("../images/rx/searchIcon.svg") 8px 8px no-repeat;
          box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
          background-color: $color-background-input-new;
          background-size: 16px;
          border: 0;
          font-size: $font-size-base;
          padding-left: 40px;
          width: 293px;
          color: #929292;
          @media (max-width: $width-laptop-hidpi) {
            font-size: 12px;
            height: 24px;
            line-height: 24px;
            background-size: 11px;
            width: 150px;
            background: url("../images/rx/searchIcon.svg") 8px 6px no-repeat;
          }
        }

        .content-header {
          background: #ffffff;
          color: $color-table-th;
          height: 74px;
          line-height: 74px;
          position: absolute;
          top: 0;
          // left: $side-menu-width-extended + 50px;
          right: 227px;

          /*@media (max-width: $width-laptop-hidpi) {
            height: 50px;
          }*/

          &.shifted {
            // margin-left: 110px;
          }

          .button-bar {
            margin: 15px 0 0;

            @media (max-width: $width-laptop-hidpi) {
              margin: 13px 0 0;
              height: 24px;
            }

            .brand-blue {
              margin-right: 0px;
              min-width: 165px;
              height: 34px;
              line-height: 34px;
              font-size: $font-size-base;
              text-align: center;
              @media (max-width: $width-laptop-hidpi) {
                font-size: 12px;
                height: 24px;
                line-height: 24px;
              }

              &.next {
                margin-right: 10px;
              }
            }

            span {
              line-height: 30px;
            }
          }
        }

        .button-bar {
          margin-bottom: 25px;
          display: flex;
          flex-flow: row nowrap;
          //justify-content: space-between;
        }

        .first-entity-create {
          form {
            @include new-form();

            margin-left: 220px + 72px;
            max-width: 710px;

            input[type="text"],
            input[type="email"],
            input[type="password"] {
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
              background-color: #ffffff;
              border-radius: 9.3px;
              color: #4a4a4a;
              margin-bottom: 0;
              height: 40px;
              line-height: 16px;
              border-radius: 12px;
              text-align: left;
              padding: 11px 20px;
              background-color: #f0f0f0;

              &.dialog-form {
                background-color: #f0f0f0;
              }

              margin-bottom: 30px;
            }

            textarea {
              border-radius: 9.3px;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
              background-color: #ffffff;
              border: 0;
              color: #4a4a4a;
              margin-bottom: 0;
              background-color: #f0f0f0;

              &.dialog-form {
                background-color: #f0f0f0;
              }

              border: 1px solid #ebebeb;
            }

            .button-bar {
              flex-direction: row-reverse;

              button {
                margin-top: 15px;
                width: 160px;
              }
            }
          }

          &.study {
            margin-top: 50px;

            .left-logo {
              float: left;
              background: url("../images/icon-edit-study.png") no-repeat;
              width: 220px;
              height: 220px;
              background-size: 220px 220px;
              margin-right: 72px;
            }
          }

          .study-hint {
            margin-bottom: 30px;
            font-size: 12px;
            color: $color-brand-red;
          }

          &.admin,
          &.patient {
            &.top-space {
              margin-top: 50px;
            }

            .left-logo {
              float: left;
              background: url("../images/invite-admin.png") no-repeat;
              width: 220px;
              height: 220px;
              background-size: 220px 220px;
              margin-right: 72px;
            }

            .right-content {
              p {
                margin-top: 0;
                line-height: 30px;
              }

              .invite-form-input {
                padding-top: 40px;
                display: flex;

                input[type="email"] {
                  width: calc(100% - 270px);
                  margin-right: 30px;
                  margin-bottom: 40px;
                }

                button {
                  width: 270px;
                }
              }

              .invite-form-messages {
                .errors-block {
                  color: $color-brand-red;
                }
              }
            }
          }
        }
      }
    }
  }
}

.info-box {
  section.column {
    header {
      div {
        font-family: GillSans;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
        color: #4f4f4f;
      }
    }
  }
}

.plot-row {
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  clear: both;

  div.column {
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    background-color: #fbfbfb;
    margin: 20px 20px 0 0;

    &.special {
      border: 0;
      background: transparent;
    }

    &:last-child {
      margin: 20px 0 0 0;
    }

    .no-data-image {
      font-size: 16px;
      width: 138px;
      height: 39px;
      line-height: 39px;
      color: #c9c9c9;
      opacity: 1;
      border: 1px solid #dadada;
      border-radius: 11px;
    }

    header {
      div {
        font-family: GillSans;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0;
        text-align: center;
        color: #4f4f4f;
      }
    }

    &.full-width {
      width: 100%;
    }

    &.flexible {
      flex: auto 1 1;
    }

    div.box {
      margin-top: 40px;
      margin-bottom: 55px;
      height: auto;
    }
  }
}

.patient-portal {
  table.table tfoot tr.hide-paging {
    height: 0;
  }

  .content-block {
    padding-bottom: 50px;
  }
}

.vborder {
  position: absolute;
  width: 13px;
  height: 100%;
  margin: 0px 607px 0 191px;
  border-right: 1px solid #d9d9d9;
}

.setting-page {
  .page-header .left {
    padding: 0;
  }
}

.os-scrollbar {
  --os-handle-bg: var(--opaque-main-color-44);
  --os-handle-bg-hover: var(--opaque-main-color-55);
  --os-handle-bg-active: var(--opaque-main-color-66);
}

.os-theme-sidebar {
  --os-handle-bg: rgba(0, 0, 0, 0.44);
  --os-handle-bg-hover: rgba(0, 0, 0, 0.55);
  --os-handle-bg-active: rgba(0, 0, 0, 0.66);
  box-sizing: border-box;
  --os-size: 10px;
  --os-padding-perpendicular: 2px;
  --os-padding-axis: 2px;
  --os-track-border-radius: 10px;
  --os-handle-interactive-area-offset: 4px;
  --os-handle-border-radius: 10px;
}

.scrollbar-right-margin {
  margin-left: -$margin-main;
  margin-right: -$margin-main;
  padding-left: $margin-main;
  padding-right: $margin-main;
}
.mobile {
  .scrollbar-right-margin {
    margin-left: -$margin-mobile;
    margin-right: -$margin-mobile;
    padding-left: $margin-mobile;
    padding-right: $margin-mobile;
  }
}

#root .react-tooltip {
  --rt-opacity: 1;
  --rt-color-dark: #4F4F4F;
  border-radius: 10px;
  z-index: 101;
  span {
    color: unset;
  }
  max-width: 80vw;
}