@import "../../style/_variables";

.dashboardContainer {
  width: 100%;
  height: auto;
  position: relative;
}


.widgetContainer {
  padding: 20px;
  overflow: hidden;
  background-color: $color-background-light;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  cursor: move;
  .widget-content {
    height: 100%;
    width: 100%;
  }
}

.layout {
  &.readOnly {
    .widgetContainer {
      cursor: default;
    }
  }
  &.editMode {
    .widget-content {
      filter: opacity(70%);
      pointer-events: none;
    }
    .remove-widget {
      position: absolute;
      right: 2px;
      top: 2px;
      cursor: default;
      width: 28px;
      height: 28px;
      border: 1px solid transparent;
      border-radius: 100px;
      background-color: #fff;
      .remove-icon {
        width: 28px;
        height: 28px;
        border-radius: 100px;
        background-color: $color-error;
        transform: translate(-1px, -1px);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
          -webkit-mask: url("../../images/closeIcon.svg") no-repeat center/contain;
          mask: url("../../images/closeIcon.svg") no-repeat center/contain;
          cursor: pointer;
          &:hover {
            box-shadow: unset;
            -webkit-mask: url("../../images/closeIcon.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
            mask: url("../../images/closeIcon.svg") no-repeat center/contain, linear-gradient(#fff 0 0);
            mask-composite: exclude;
          }
      }
    }
    .edit-widget {
      right: 30px;
    }
    // .widgetContainer:nth-child(2n) {
    //   animation-name: keyframes1;
    //   animation-iteration-count: infinite;
    //   transform-origin: 50% 10%;
    //   animation-delay: -.75s;
    //   animation-duration: .25s
    // }

    // .widgetContainer:nth-child(2n-1) {
    //   animation-name: keyframes2;
    //   animation-iteration-count: infinite;
    //   animation-direction: alternate;
    //   transform-origin: 30% 5%;
    //   animation-delay: -.75s;
    //   animation-duration: .25s
    // }
  }

  .edit-widget {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: default;
    display: none;
    border-radius: 100px;

    .edit-icon {
      margin: 0;
      width: 28px;
      height: 28px;
      border-radius: 100px;
      background-position: center;
      background-size: 60%;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
      box-shadow: none;
      &:hover {
        box-shadow: unset;
        background-image: none;
        background-color: var($--main-theme-color);
        -webkit-mask: url("../../images/rx/edit.svg") no-repeat center/60%, linear-gradient(#fff 0 0);
        mask: url("../../images/rx/edit.svg") no-repeat center/60%, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }
  }
  .widgetContainer:hover .edit-widget {
    display: block;
  }
}

.widgetContainerNoPadding {
  padding: 0px;
}

.widgetContainerNoBorder {
  border: unset;
  border-radius: unset;
  .recharts-surface {
    border-radius: unset;
  }
}

.mobile {
  .widgetContainerExtendMargins {
    margin-left: -$margin-mobile;
    margin-right: -$margin-mobile;
  }
}

.widgetContainerGreyBackground {
  background-color: #fbfbfb;
}

.widgetSelectionContainer {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  .dropdown-container {
    display: flex;
    position: unset;
    right: unset;
    transform: unset;
    width: unset;
    .filtervisibility-container {
      height: 41px;
      width: 41px;
      border-radius: 10px;
      background-color: #fff;
      position: unset;
      .filtervisibility-icon, .filtervisibility-icon-on {
        height: 41px;
        width: 41px;
      }
    }
  }
  .dropdown {
    max-width: 40vw;
    .form-control {
      font-size: 1.1rem;
      display: flex;
      grid-template-columns: 1em auto;
      gap: 0.5em;
      .filter-text {
        width: fit-content;
        max-width: 60vw;
      }
    }
  }
}

.edit-mode-generic {
  display: block;
  height: 41px;
  width: 41px;
  background-size: 100%;
  background-color: var($--main-theme-color);
  border-radius: 7px;
  transform: translate(-1px, -1px)
}

.edit-mode-button {
  height: 41px;
  width: 41px;
  border-radius: 10px;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #d9d9d9;
  &.on {
    border-color: transparent;
  }
  .edit-mode-icon {
    background-color: #4f4f4f;
    -webkit-mask: url("../../images/rx/edit.svg") no-repeat center/65%;
    mask: url("../../images/rx/edit.svg") no-repeat center/65%;
    @extend .edit-mode-generic;
  }
    
  .edit-mode-icon-on {
    @extend .edit-mode-generic;
    -webkit-mask: url("../../images/rx/edit.svg") no-repeat center/65%, linear-gradient(#fff 0 0);
    mask: url("../../images/rx/edit.svg") no-repeat center/65%, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    border: unset;
  }
    
  .edit-mode-icon:hover {
    @extend .edit-mode-icon-on;
  }
}


.react-grid-item {
  box-sizing: border-box;
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.react-resizable:hover .react-resizable-handle.react-resizable-handle-se {
  opacity: 1;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  padding: 0 9px 9px 0;
  opacity: 0;
  &:after {
    content: unset;
  }
}

@keyframes keyframes1 {
  0% {
    rotate: -0.05deg;
    animation-timing-function: ease-in;
  }

  50% {
    rotate: 0.04deg;
    animation-timing-function: ease-out;
  }
}

@keyframes keyframes2 {
  0% {
    rotate: 0.05deg;
    animation-timing-function: ease-in;
  }

  50% {
    rotate: -0.04deg;
    animation-timing-function: ease-out;
  }
}