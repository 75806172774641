@media (max-width: $width-laptop-hidpi) {

  header.main {
    height: 50px;

    .header-left {
      height: 50px;

      .header-logo {
        margin-top: 13px;
        width: 23px;
        height: 23px;
        background-size: 23px;
      }

      .side-menu-toggle {
        top: 13px;
        width: 23px;
        height: 23px;
        right: -12px;

        .c-hamburger {
          margin-top: 2px !important;
          width: 12px;
          height: 13px;
        }
      }
    }
  }


}