@import "variables";

.app-root {
  .app-main {
    .app-content {
      .button-bar {
        .search-input.loop {
          // background: url("../images/search-loop.png") 17px 13px no-repeat;
          // background-size: 38px 23px;
          // height: 52px;
          // line-height: 52px;
          // border-radius: 0;
          // border-color: #d4d4d4;

          &::placeholder {
            color: #b0b0b0;
          }

          &::-webkit-input-placeholder {
            color: #b0b0b0;
          }

          &::-moz-placeholder {
            color: #b0b0b0;
          }

          &:-ms-input-placeholder {
            color: #b0b0b0;
          }
          &:-moz-placeholder {
            color: #b0b0b0;
          }

          @include input-focus-animation();
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .invite-page-content {
    .column {
      &.plot-wrapper {
        max-width: 35%;
      }
    }
  }
}

.invite-user-form-block {
  min-height: 320px;

  .plot-row {
    margin-bottom: 0;
  }

  .loader-wrapper {
    margin-top: -14px;
  }
}

.edit-study {
  .name {
    color: $color-brand;
  }

  hr {
    margin-top: 0;
  }

  .title {
    font-size: 18px;
    color: #222;
    width: 100%;
    overflow: hidden;
    padding: 17px 11px;
    margin: 0px;
    line-height: 30px;
  }
  p.tilte {
    line-height: 30px;
  }

  .description {
    margin-top: 36px;
    margin-left: 11px;
  }

  .no-description {
    margin-top: 15px;
    background: #f7f7f7;
    padding: 15px 21px;
    border-radius: 12px;
  }
}
