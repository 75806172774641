@import '../../style/variables';

.edit-rpm-patient, .edit-caregivers, .onboard-patient {
  $plus-top-margin: 21px;
  .button-container {
    background-color: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 13px;
    height: 46px* $scale-koef;
    width: fit-content;
    align-self: end;
    &.disabled {
      opacity: 0.3;
      filter: none;
    }
  }
  .horizontal-flex > .button-container {
    flex: 0;
  }
  .minus-button {
    background-color: var($--main-theme-color);
    -webkit-mask: url("../../images/minus.svg") no-repeat center/20%;
    mask: url("../../images/minus.svg") no-repeat center/20%;
    min-width: 81px * $scale-koef;
    height: 46px* $scale-koef;
    &:disabled {
      cursor: not-allowed;
      filter: grayscale(100%);
      opacity: .5;
    }
  }

}

.plus-button {
  background-color: var($--main-theme-color);
  -webkit-mask: url("../../images/plus.svg") no-repeat center/35%;
  mask: url("../../images/plus.svg") no-repeat center/35%;
  min-width: 40px;
  padding: 0;
  &.dark {
    background-color: var($--main-theme-color);
    -webkit-mask: url("../../images/plus.svg") no-repeat center/45%, linear-gradient(#fff 0 0);
    mask: url("../../images/plus.svg") no-repeat center/45%, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }
}