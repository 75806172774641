.alerts {
  .alerts-priority-widget-container {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-between;
    margin-bottom: 20px;

    --alerts-border-color: #D9D9D9;

    .exclamation-mark-generic {
      -webkit-mask-size: 100%;
      mask-size: 100%;
      mask-image: url('../../images/exclamation_mark.svg');
      --webkit-mask-image: url('../../images/exclamation_mark.svg');
      mask-repeat: no-repeat;
      --webkit-mask-repeat: no-repeat;
    }

    .widget-icon-size {
      width: 30px;
      height: 30px;
    }

    .table-icon-size {
      width: 20px;
      height: 20px;
    }

    .high-priority-color {
      background-color: #DA547D;
    }

    .medium-priority-color {
      background-color: #F9A353;
    }

    .low-priority-color {
      background-color: #F7D23A;
    }

    .high-priority-alerts-icon-widget {
      @extend .exclamation-mark-generic;
      @extend .high-priority-color;
      @extend .widget-icon-size;
    }

    .medium-priority-alerts-icon-widget {
      @extend .exclamation-mark-generic;
      @extend .medium-priority-color;
      @extend .widget-icon-size;
    }

    .low-priority-alerts-icon-widget {
      @extend .exclamation-mark-generic;
      @extend .low-priority-color;
      @extend .widget-icon-size;
    }

    .high-priority-table-icon {
      @extend .exclamation-mark-generic;
      @extend .high-priority-color;
      @extend .table-icon-size;
    }

    .medium-priority-table-icon {
      @extend .exclamation-mark-generic;
      @extend .medium-priority-color;
      @extend .table-icon-size;
    }

    .low-priority-table-icon {
      @extend .exclamation-mark-generic;
      @extend .low-priority-color;
      @extend .table-icon-size;
    }

    .alerts-priority-widget {
      border: var(--alerts-border-color) solid 1px;
      border-radius: 20px;
      width: 100%;
      height: 110px;
      background-color: #FBFBFB;
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      align-items: center;
      justify-content: start;
      font-size: 27px;
      color: black;
      padding-left: 20px;



      .icon-container {
        border: 1px solid var(--alerts-border-color);
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #FFFFFF;
        display: flex;
        justify-items: center;
        align-items: center;
        justify-content: center;
      }
    }
  }
}