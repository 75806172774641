@import "./variables";

.oauth2-authorize {
  background: #f8f8f8;

  .container {
    padding: 16px;
    margin: auto;
    max-width: 375px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .block-center {
      background: #ffffff;
      border-radius: 12px;
      padding: 45px 45px 50px 45px;
      color: #6d6d6d;

      h2, h3 {
        margin: 0;
        padding: 0;

        color: $color-brand;
        font-weight: bold;
        text-align: center;
        font-size: 18px;
      }

      p {
        margin-top: 25px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 13px;
        line-height: 21px;
      }

      ul {
        margin-bottom: 20px;
        text-align: center;
        list-style-type: none;
        padding: 0;
        font-size: 13px;

        li {
          &:before {
            content: "•";
            font-size: 0.75em;
            padding-right: 5px;
            color: $color-brand;
          }
        }
      }

      .control-group {
        .controls {
          [role="button"] {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
