.custom-tooltip {
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 3px #ddd;
  font-size: 15px;
  opacity: 0;
}

.charge-custom-tooltip {
  padding:10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 3px #ddd;
  font-size: 15px;
  opacity: 0;
}

.charge {
  text-align: center;
  color: #0093d5;
}

svg text {
  pointer-events: none;
}


.time-delta-custom-tooltip {
  border-radius: 0;
  border-color: #d5d5d5;
  text-align: center;
  color:  #4ec02b;
  font-size: 12px;
  line-height: 1;
  border-radius: 10px;
  padding: 6px 10px;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  font-size: 12px;
  border: 0;
  color: #222;
  width: 174px;

  .tooltip-day-1 {
    color: #222;
    line-height: 1.5em;
    font-size: 12px;

    .label1 {
      width: 100px;
      text-align: right;
      display: inline-block;
      padding-right: 10px;
    }

    .value1 {
      width: 50px;
      text-align: left;
      display: inline-block;
    }
  }

  &::before {
    border-bottom-color: #f1f1f1;
  }
}


