@import "../../style/_variables";

$new-scale-koef: 0.75;
$scale-koef: 0.75;
// $scale-koef: 0.875;
$font-color: #4F4F4F;
$font-secondary-color: #272727;
$active-color: #B1D590;

.download-history {

  &.content-block {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 20px;
    padding: 0;

    #totalPills {
      margin: 0;
    }
  }

  .dose-remain-box {
    display: flex;
    padding: 17px 15px 15px 24px;
    border: solid 1px #D9D9D9;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
    object-fit: contain;

    .big-label {
      font-size: 20px;
      color: #000000;
    }

    .light-label {
      margin-top: 3px;
      color: #929292;
    }

    .blue-label {
      color: #0060a6;
    }

    .dose-count {
      font-size: 27px;
      font-weight: 600;
      line-height: 64px;
      width: 125px;
      height: 64px;
      margin-right: 20px;
      text-align: center;
      color: #FFFFFF;
      border-radius: 8px;
      background-color: #E6819F;


      &.high {
        background: #92DA7B;
      }

      &.medium {
        background: #EAAC6C;
      }

      &.low {
        background: #E6819F;
      }

      &.inactive {
        color: #DFDFDF;

        &.block {
          color: white;
          background-color: #DFDFDF;
        }
      }

    }
  }

  .row {
    &.title {
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
        color: #4F4F4F;
      }

      .input-content {
        position: relative;
        height: 42px;
        margin-right: 30px;
        margin-left: auto;
      }

      input {
        max-width: 70px;
        height: 40px;
        padding: 0;
        text-align: center;
        color: #4A4A4A;
        border: solid 1px #D9D9D9;
        border-radius: 13px;
        background-color: #F7F7F7;
        box-shadow: none;

      }

      input[type="checkbox"] {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 27px;
        height: 27px;
        margin-top: 10px;
        cursor: pointer;
        opacity: 0;
      }

      input[type="checkbox"] + label {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: inline-block;
        width: 27px;
        height: 27px;
        margin-top: 10px;
        padding: 0 0 0 0;
        background: url("../../images/rx/uncheckedBox.svg") no-repeat right;
      }

      input[type="checkbox"]:checked + label {
        display: inline-block;
        width: 27px;
        height: 27px;
        margin-top: 10px;
        padding: 0 0 0 0;
        background: url("../../images/rx/checkedBox.svg") no-repeat;

      }

      #totalPills {
        min-width: 121px;
        margin-right: 2px;
      }

      #addPills {
      }
    }
  }
}