@import "../../style/_variables";

$new-scale-koef: 0.75;
$scale-koef: 0.75;
// $scale-koef: 0.875;
$font-color: #4F4F4F;
$font-secondary-color: #272727;
$active-color: #B1D590;


.medication_adherence {
  & > * {
    margin-right: 0;
    margin-left: 0;
  }

  .average {
    margin-left: 0 !important;
  }

  .averageText {
    margin-left: 24px*$scale-koef;
  }
}

.patient-sort-element {
  content: "";
  position: absolute;
  width: 27px;
  height: 27px;
  margin-left: 5px;
  cursor: pointer;
  background: url(../../images/rx/medicationSelect.svg) no-repeat center;
  background-size: 27px;
}

.medications-view {
  position: relative;

  .text_overflow {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .stock {
    font-size: 22px*$scale-koef;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px*$scale-koef;
    height: 36px*$scale-koef;
    text-align: center;
    color: #14568D !important;
    border: solid 3px*$scale-koef $color-average;
    border-radius: 10px*$scale-koef;
    background: white;

    &.high {
      border-color: $color-good;
    }

    &.medium {
      border-color: $color-average;
    }

    &.low {
      border-color: $color-poor;
    }

    &.inactive {
      color: white !important;
      border-color: #D8D8D8 !important;
      background: #D8D8D8;
    }
  }


  .inactive {
    color: #DFDFDF;

    &.block {
      color: white;
      background-color: #DFDFDF;
    }
  }

  .status-conatiner {
    display: flex;

    .status-text {
      margin-left: 10px;
    }
  }

  .med-status {
    display: flex;
    align-items: center;

    .approval-status {
      width: 36px*$scale-koef;
      height: 36px*$scale-koef;
      background-size: 100% 100%;
    }
  }

  .imgText {
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
    }
  }

  .infoBox {
    .row {
      &.title {
        width: 100px;
        margin-top: 10px;
        text-align: left;
      }
    }
  }


  .checkbox_container {
    margin-left: 41px*$scale-koef;

    .input-content {
      cursor: pointer;

      input[type="checkbox"] {
        cursor: pointer;

        &:focus {
          & + label {
            outline: 2px solid  var($--controls-color);
          }
        }
      }
    }

    span {
      font-size: 24px*$scale-koef;
      margin-left: 20px*$scale-koef;
      color: #4E4E4E;
    }
  }

  .right-select {
    display: flex;
    margin-left: auto;

  }

  .select-box-dashboard {

    select {
      font-family: GillSans;
      font-size: 27px * $scale-koef;
      overflow: hidden;
      padding-left: 30px;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $font-color;
      border: 0;
      background: transparent;
      background: url("../../images/rx/selected-down.svg") no-repeat;
      background-position: left;
      background-size: 50px;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;

      &.left {
        padding: 8px 12px 13px 55px;
        padding-left: 30px;
        background-position: left;
      }

      &.right {
        max-width: 187px;
        padding: 8px 55px 13px 12px;
        padding-left: 30px;
        background-position: right;
      }
    }
  }

  .spinner-container {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
  }


  .dose-block {
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;

    .dose {
      font-size: 15px;
      line-height: 2;
      display: inline-block;
      display: inline-block;
      width: 65px;
      height: 31px;
      text-align: center;
      color: white;
      border-radius: 25px * $scale-koef;
      background-color: #E6819F;

      &.high {
        background: #92DA7B;
      }

      &.medium {
        background: #EAAC6C;
      }

      &.low {
        background: #E6819F;
      }

      &.inactive {
        color: #DFDFDF;

        &.block {
          color: white;
          background-color: #DFDFDF;
        }
      }

    }

    .dose-text {
      font-size: 24px * $scale-koef;
      margin-left: 10px;
      color: $font-color;
    }
  }
}

$table-medications-selected-background-color: #EFF5FA;
$table-medications-selected-border-color: #B6D2E6;

.patient-simplebar-100percent-width {
  // extra space for horizontal scroller
  padding-bottom: 10px;
  border: none;

  .simplebar-mask {
    border: none;
  }
}

.patient-portal {
  .medications-view {
    .simplebar-100percent-width {
      @extend .patient-simplebar-100percent-width;
    }
  }
  .history-view {
    .simplebar-100percent-width {
      @extend .patient-simplebar-100percent-width;
    }
  }
}

.patient-table {
  margin-top: 28px*$scale-koef;

    &>thead {
      &>tr {
        height: 64px;

        &>th {
          background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 84%, rgba(192,192,192,1) 85%, rgba(255,255,255,1) 85%);
          // background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 78%, rgba(192, 192, 192, 0.8) 84%, rgba(255, 255, 255, 0) 86%, rgba(255, 255, 255, 0) 100%);
          border-right: none;
          border-top: none;
          padding-bottom: 33px;

          &, & * {
            color: black;
          }
    
          &:first-child {
            background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 84%, rgba(192,192,192,1) 85%, rgba(255,255,255,1) 85%);
            // background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 78%, rgba(192, 192, 192, 0.8) 84%, rgba(255, 255, 255, 0) 86%, rgba(255, 255, 255, 0) 100%);
            box-shadow: unset;
            clip-path: unset;
          }

          &:last-child {
            border-right: none;
          }

          span {
            a {
              font-size: 24px*$scale-koef;
              padding-right: 5px;
            }
          }
        }

        .sort-icon {
          display: none;
        }
        th.sortable:hover, th.sortable:hover a:hover{
          color: #0060a6;
        }

        th.sortable:hover {
          &:before {
            content: none;
          }
          &:after {
            @extend .patient-sort-element;
          }
        }
        th.sortable {
          &.ascending {
            &:before {
              content: none;
            }
            &:after {
              @extend .patient-sort-element;
              transform: rotate(180deg);
            }
          }
          &.descending {
            position: relative;
            &:before {
              content: none;
            }
            &:after {
              @extend .patient-sort-element;
            }
          }
        }

      }
    }
    & > thead:after {
      content: none;
    }

    &>tbody {
      border: none;

      &>tr {
        background: none;

        &>td {
          border-right: none;
          font-size: 18px*$scale-koef;
          height: 78px*$scale-koef;

          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;
        
          &, & * {
            color: #4E4E4E;
          }
    
          &:first-child {
            border-left: 1px solid transparent;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            box-shadow: unset;
            clip-path: unset;
          }

          &:last-child {
            border-right: 1px solid transparent;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }

          &.name {
            color: var($--main-theme-color);
          }
          .name_active {
            color: #14568D;
          }
          .name_inactive {
            color: #7F7F7F;
          }
          .medication-name-with-icon {
            display: flex;
            align-items: center;
            max-width: 50vw;
            .medication-name {
              width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }

        &:nth-child(odd) {
          background: none;

          td {
            background: #f5f5f5;
          }
        }

        &:nth-child(even) {
          background: none;

          td {
            background: none;
          }
        }

        &:hover {
          // td:after {
          //   top: 5px !important;
          //   bottom: 5px !important;
          //   height: auto !important;
          // }

          &>td {
            background-color: $table-medications-selected-background-color;
            border-top: 1px solid $table-medications-selected-border-color;
            border-bottom: 1px solid $table-medications-selected-border-color;
            // height: 48px;

            &:first-child {
              border-left: 1px solid $table-medications-selected-border-color;
              background-color: $table-medications-selected-background-color;
              // padding-left: 14px;
            }

            &:last-child {
              border-right: 1px solid $table-medications-selected-border-color;
            }
          }
        }

      }

    }
}
.patient-portal {
  .medications-view {
    .table.Medications {
      @extend .patient-table;
    }
    .simplebar-100percent-width {
      height: calc(100vh - 74px - 109px*#{$scale-koef} - 20px);
      overflow-y: auto;
    }
  }
  .history-view {
    .table.patientMedicationHistoryTable {
      @extend .patient-table;
    }
  }
}