.page-container {
    overflow-y: auto;
    height: 100%;

    .page-inner-container {
        padding: 20px 50px;
        width: 70%;
        margin: 0 auto;
        max-width: 900px;

        .list-style-none {
            list-style-type: none;
        }

        .list-style-lower-alpha {
            list-style-type: lower-alpha;
        }

        .list-style-decimal {
            list-style-type: decimal;
        }
    }
}