.modalWrapper.floating-modal.patient-raw-data {
  max-width: 60vw;
  .modalContent {
    max-height: 80vh;
    .button-bar {
      position: absolute;
      bottom: 20px;
      right: 20px;
      .bottom-button-container {
        display: none;
      }
    }
  }
}