.caregiver-container {
  background: #2b90d8;

  .caregiver-header{
    padding: 44px 44px 0 44px;
    position: fixed;
    width: 100%;
    background: #2b90d8;
    top:0;
    z-index: 1;

    @media (max-width: 768px) {
      padding: 27px 21px 0 21px;
    }

    .caregiver-logo {
      width: 56px;
      height: 56px;
      background: url("../images/rx/caregiver-logo.svg") no-repeat center;
      background-size: cover;
      margin: 0 auto;
      @media (max-width: 768px) {
        margin: 0;
      }
    }

    .success-logo {
      width: 56px;
      height: 56px;
      background: url("../images/rx/checkRadio.svg") no-repeat center;
      background-size: cover;
      margin: 0 auto;
      @media (max-width: 768px) {
        margin: 0;
      }      
    }
  
    .header-progress-bar {
      position: relative;
      height: 4px;
      background-color: #19679c;
      border-radius: 2px;
      max-width: 873px;
      margin: 0 auto;
      &.mt-20 {
        margin-top: 20px;
      }
      .active-progess {
        background-color: #fff;
        border-radius: 2px;
        height: 4px;
        position: absolute;
        width: 0%;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
      }
    }
  }

.body-content {
  padding: 124px 44px 44px 44px;
  @media (max-width: 768px) {
    padding: 107px 0 0 0;

    
  }
  overflow-y: scroll;
  height: calc(100vh);


 


    .content {
      width: 350px;
      margin: 0 auto;
      @media (max-width: 768px) {
        width: auto;
        margin: 0;
        padding: 0 21px 21px 21px;
      }

      &.mt {
        margin-top: 75px;
        @media (max-width: 768px) {
          margin-top: 35px;
        }
      }

      .text-container {
        width: 295px;
        margin: 0 auto;

        @media (max-width: 768px) {
          width: 100%;
        }

        .heading {
          width: 295px;
        
          height: 68px;
          font-size: 28px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          @media (max-width: 768px) {
            width: 100%;
            text-align: left;
          }
        }
        .body {
          width: 295px;         
          height: 76px;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;

          @media (max-width: 768px) {
            width: 100%;
            text-align: left;
          }

          &.mt {
            margin-top: 30px;
          }
        }
      }
    }

    .form-container {
      width: 350px;
      margin: 0 auto;
      background: transparent;
      border: 0;
      box-shadow: none;
      @media (max-width: 768px) {
        width: auto;
      }

      &.mt{
        margin-top:17px;
        padding: 20px;
        @media (max-width: 768px) {
          margin: 0;
          padding: 0;
          padding-right: 12px;
        }
      }

      .cg-error{
        margin: 10px 0;
        text-indent: 15px;
        background: #FFCDD2;
        border: solid #e60017;
        border-width: 0 0 0 6px;
        color: #73000c;
        margin-left: 25px;
      }
    
      .accept-as-caregiver {      
        .input-element {
          position: relative;
          padding: 10px 20px 10px 0px;

          input {
            border-radius: 28px;
            background-color: #18547e;
            border: 0;
            color: #fff;
            height: 50px;
            width: 100%;  
            margin-left: 25px;

            &.phone-number {
              text-indent: 30px;
            }

            &:focus {
              border: solid 3px rgba(255, 255, 255, 0.6);
            }

            &::placeholder {
              color: #FFF;
              opacity: .4 ;
            }
            
            &:-ms-input-placeholder { 
              color: #FFF;
            }
            
            &::-ms-input-placeholder { 
             color: #FFF;
            }

            &:disabled {
              opacity: .4;
              cursor: not-allowed;
            }
          }

          .triangle {
            background: url("../images/rx/triangle.svg") no-repeat center;
            width: 15px;
            height: 19px;
            position: absolute;
            top: 27px;
            left: 0px;
            margin-right: 3px;
            &.first {
              top:26px;
            }
            @media (max-width: 768px) {
              left: -3px;
            }
          }
    
          .verify {
            background: url("../images/rx/verify.svg") no-repeat center;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 22px;
            right: 8px;
          }

          button {
            width: 100%;
            height: 50px;
            object-fit: contain;
            border-radius: 25px;
            box-shadow: 0 5px 7px 1px rgba(0, 0, 0, 0.12);
            background-color: #ffffff;
            border: 0;
            padding: 0;
            margin: 0;
            font-size: 14px;
            color:#000;
            margin-left: 25px;
            font-weight: 600;

            &:hover,
            &:focus {
              box-shadow: 0 5px 7px 1px rgba(0, 0, 0, .2);
            }
          }

          .cg-country-code {
            position: absolute;
            top: 25px;
            left: 47px;
            color: #FFF;
            opacity: .4;
          }

          .caregiver-spinner {
            background: url("../images/rx/pendingLoader.png") no-repeat center;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 22px;
            right: 8px;
            animation: rotating 3s linear infinite;
            background-size: 24px;
          }

        }
      }
    }
}
}
