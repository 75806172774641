@import '../../style/variables';


@mixin menuIconsNew($width, $height, $columns...) {
    @each $c in $columns {
    &.#{nth($c, 1)} {
      .icon {
        mask-position: 5px -#{(nth($c, 2)-1) * ($height*2)};
        -webkit-mask-position: 5px -#{(nth($c, 2)-1) * ($height*2)};
        transition-delay: 0s;
        transition-duration: 0s;
        transition-property: background-color;
      }

      .active {
        &.icon {
          background-color: $color-menu-active;
          transition-delay: $animation-duration;
          transition-property: background-color;
        }
      }
      .mobile {
        &.icon {
          margin-left: -15px;
          background-color: #fff;
        }
        &.child {
          &.icon {
            margin-left: -15px;
            background-color: var($--left-nav-active-label-color);
          }
        }
  
      }
    }
  }
}

.item {
  .icon {
    display: inline-block;
    height: 50px;
    width: 60px;
    background-size: 100px 850px;
    -webkit-mask-image: url("../../images/rx/sprite.svg");
    mask-image: url("../../images/rx/sprite.svg");
  }
  .collapsed {
    background-color: var($--left-nav-opaque-icon-color);
    transition-delay: $animation-duration - 0.2s;
    transition-duration: $animation-duration;
    transition-property: background-color;
  }  
    
  @include menuIconsNew(
    22px,
    25px,
    "admins" 1,
    "device-management" 11,
    "caps-manager" 2,
    "caps" 2,
    "dashboard" 1,
    "super-user-dashboard" 1,
    "patients" 3,
    "monitoring" 3,
    "patient-list" 18,
    "cohorts" 18,
    "readings" 18,
    "settings" 9,
    "studies" 6,
    "support" 8,
    "search-new-cap" 8,
    "doctor" 9,
    "download" 10,
    "history" 14,
    "medications" 12,
    "logout" 7,
    "caps-schedules" 2,
    "devices" 11,
    "provisioning" 4,
    "report" 4,
    "organization" 1,
    "calendar" 13,
    "billing" 15,
    "messages" 16,
    "alerts" 10,
    "OrganizationManagerAdmin" 1,
    "OrganizationManagerCapManager" 2,
    "device-management-ble" 18,
    "device-management-lte" 18,
    "device-management-lsc" 18,
    "device-management-hub" 18,
    'patients-active' 18,
    'patients-pending' 18,
    'patients-termed' 18,
    'messages-archived' 18,
    'messages-inbox' 18,
    "provisioning-hub" 18,
    "provisioning-lte" 18,
    "provisioning-lsc" 18,
    "provisioning-injectable-tracker" 18,
    "provisioning-mdp" 18,
    "provisioning-lmo" 18,
    "devices-ble" 18,
    "devices-lte" 18,
    "devices-lsc" 18,
    "devices-injectable-tracker" 18,
    "devices-mdp" 18,
    "devices-hub" 18,
    "devices-lmo" 18,
    "devices-bpm" 18,
    "devices-weight-scales" 18,
    "devices-wb" 18,
    "devices-glucometer" 18,
    "devices-air-quality-monitor" 18,
    "billing-tasks" 18,
    "billing-device-events" 18,
    "devices-kit" 18,
    "alerts-current" 18,
    "alerts-configurations" 18,
    "messages-prefabs" 18,
    "devices-hailie-inhaler" 18,
    "devices-pt_inr" 18,
    "devices-po" 18,
    "devices-spirometer" 18,
    "devices-thermometer" 18,
    "health-center" 1,
    "system-configuration" 9,
    "analytics" 1,
    "care-providers" 18,
    "org-groups" 2,
  );
}