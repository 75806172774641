@import "./variables";

$button-height: 30px;

@function to-em($pixel) {
  @return ($pixel/16) + em;
}

.button-group {
  width: 100px;
}

button,
[role="button"] {
  height: to-em(40);
  // line-height: to-em(50);
  border: none;
  border-radius: $border-radius;
  padding: 0 to-em(20);
  vertical-align: middle;
  line-height: $button-height;
  box-sizing: border-box;
  transition: all $animation-swift-out;
  user-select: none;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  min-width: 118px;

  &.intercom-lightweight-app-launcher {
    min-width: 20px;
  }

  &.header-btn {
    height: to-em(20);
    line-height: 26px;
  }

  &.profile {
    &:disabled {
      background: $color-border-base;
      color: $font-color-base;
    }
  }

  &.brand-blue {
    // background: $color-gradient-blue;
    background: var($--main-theme-color);
    color: white;
    border-width: 0;

    &.dark {
      background: #14568D;

      &:hover:not([disabled]) {
        background-image: linear-gradient(to bottom, #14568D, #14568D);
        box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.1);
        
      }

    }

    &.fl-r {
      float: right;
    }

    &.full-width {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    // &:disabled {
    //   // background: white;
    //   font-weight: 600;
    //   box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.06);
    //   color: #929292;

    //   &:hover {
    //     background: white;
    //     box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.06);
    //   }
    // }


    &:disabled {
      background-color: var($--opaque-main-color-30);
      opacity: unset;
    }

    &:hover:not([disabled]) {
      // background-image: linear-gradient(to bottom, #3097e1, #2080c3);
      background: var($--main-theme-color);
      box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.1);

    }
  }

  &.brand-plain-blue{
    background: var($--main-theme-color);
    color: white;
    border-width: 0;
  }

  &.brand-white-gray{
    color: #4f4f4f;
    background: white;
    border: solid 1px #bababa;
  }

  &.white {
    background: white;
    color: #929292;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.06);

    &:hover {
      color: #2a93d5;
      // color: #ffffff;
      box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.06);
    }

    &.width-40 {
      width: 40%;

      &.fl-l {
        float: left;
        text-align: center;
      }
    }

    &.gray-text {
      color: $color-background-darkest;
    }

    &.link {
      line-height: 37px;
    }
  }

  &.delete {
    $delete-button-height: 36px;

    width: $delete-button-height;
    height: $delete-button-height;
    padding: 0;
    border-radius: $delete-button-height / 2;

    > div {
      width: 12px;
      height: 12px;
      background-size: 12px 12px;
      margin: auto;
    }

    .delete-img {
      width: 17px;
      height: 21px;
      background: url("../images/delete.png") no-repeat;
      background-size: 10px 10px;
      margin: auto;
      background-position: 3px 6px;
      background-color: white;
    }

    .edit-img {
      width: 17px;
      height: 21px;
      background: url("../images/rx/edit.svg") no-repeat;
      background-size: 10px 10px;
      margin: auto;
      background-position: 3px 6px;
      background-color: white;
    }

    .refresh-img {
      width: 17px;
      height: 21px;
      background: url("../images/refresh-reset.svg") no-repeat;
      background-size: 10px 10px;
      margin: auto;
      background-position: 3px 6px;
      background-color: white;
    }

    .chart-img {
      width: 17px;
      height: 21px;
      background: url("../images/graph.svg") no-repeat;
      background-size: 16px 18px;
      margin: auto;
      background-position: 0px 0px;
      background-color: white;
    }
  }

  &.big {
    width: 200px;
    height: 50px;
    line-height: 50px;
    border-radius: 25px;
    position: relative;

    &::after {
      display: inline-block;
      position: absolute;
      float: right;
      content: "";
      width: 8px;
      height: 12px;
      top: 19px;
      bottom: 19px;
      background: url("../images/smallArrow.svg") no-repeat center;
      background-size: 8px 12px;
      right: 20px;
    }
  }

  &:hover {
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background: $color-background-light;
    cursor: not-allowed;
    user-input: none;
    &:hover {
      box-shadow: initial;
    }
  }

  &.stop-button {
    background: $color-error;
    color: #fff;
    padding-left: 45px;
    position: relative;
    &::after {
      content: " ";
      display: block;
      position: absolute;
      left: 15px;
      top: 11px;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      background-color: #fff;
    }
  }

  &.start-button {
    background: $color-good;
    color: #fff;
    padding-left: 45px;
    position: relative;
    &::after {
      content: " ";
      display: block;
      position: absolute;
      left: 15px;
      top: 10px;
      width: 16px;
      height: 16px;
      background-color: #fff;
      // generated using https://codepen.io/shkarlet/pen/yLLRGmG
      clip-path: polygon(92.5% 37.00962%, 96.49067% 40.35819%, 99.09539% 44.8697%, 100% 50%, 99.09539% 55.1303%, 96.49067% 59.64181%, 92.5% 62.99038%, 40% 93.30127%, 35.10472% 95.08301%, 29.89528% 95.08301%, 25% 93.30127%, 21.00933% 89.9527%, 18.40461% 85.44119%, 17.5% 80.31089%, 17.5% 19.68911%, 18.40461% 14.55881%, 21.00933% 10.0473%, 25% 6.69873%, 29.89528% 4.91699%, 35.10472% 4.91699%, 40% 6.69873%);
    }
  }
}
