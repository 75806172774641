@import "./variables";
$new-scale-koef: 0.90;
$input-shadow: inset 0 1px 0px 0px rgba(181, 181, 181, 0.1);
$input-height: 2.7em;
.inputs {
  font-size: 18px * $scale-koef;
  line-height: 46px * $new-scale-koef;
  width: auto;
  min-width: auto;
  height: 46px* $new-scale-koef;
  margin: 0;
  margin-right: 15px;
  border-radius: 13px;
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.22);
  border: solid 1px #BABABA;
  background-color: #F7F7F7;
  color: #000000;
  padding: 0;
  padding-left: 22px;
}
label.error {
  color: red;
}
input {
  height: $input-height;
  border: 1px solid $color-border-base;
  border-radius: $border-radius;
  padding: 0 $input-height/2;
  box-shadow: $input-shadow;
  box-sizing: border-box;
  background-color: $color-background-input;
  color: $font-color-base;
  font-family: $font-family-base;
  &.error {
    color: #E71D5B;
    caret-color: #E71D5B;
  }
}
input[type="color"] {
  padding: 0;
}
select {
  font-family: $font-family-base;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.disabled-fieldset {
  opacity: 0.7;
}
// multiselect
.multiselect-container {
  width: fit-content;
  width: moz-fit-content;
  .search-wrapper {
    @extend .inputs;
    background-image: var($--arrow-down-icon);
    background-size: 23px;
    background-position: right 10px center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    column-gap: 5px;
    input.searchBox {
      width: auto;
      box-shadow: none;
      background: none;
      border: none;
      padding: 0;
      color: #000;
    }
    .chip {
      background: var($--main-theme-color);
      margin: 0;
    }
  }
}
.optionListContainer {
  .optionContainer {
    border-radius: 12px;
    border: 1px solid #BABABA;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.13);
    color: #000;
    .option {
      padding: 10px 10px;
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      font-size: 18px * $scale-koef;
      border-radius: 8px;
    }
    li:hover, .highlight {
      background: var($--opaque-main-color-7);
      color: #000;
    }
  }
}
::placeholder {
  color: #000;
  opacity: 1;
}
input:focus::placeholder {
  color: transparent;
}
input:disabled {
  cursor: not-allowed;
  filter: grayscale(100%);
  opacity: .5;
  pointer-events: none;
}

.required-asterisk::after {
  content: " *";
  color: $color-required;
}

.input-error {
    width: 21px * $scale-koef;
    height: 21px * $scale-koef;
    background: url("../images/iconWarning.svg") center center no-repeat;
    background-size: 100% 100%;
}

.invalid-control-background {
  background-color: $color-required-background;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.react-time-picker,
.react-date-picker {
  width: 100%;
  border-radius: 13px;
}
.react-time-picker__wrapper,
.react-date-picker__wrapper {
  @extend .inputs;
  margin-right: 0;
  .react-time-picker__inputGroup,
  .react-date-picker__inputGroup {
    padding: 0;
    .react-time-picker__inputGroup__input,
    .react-date-picker__inputGroup__input {
      background-color: unset;
      border: unset;
      box-shadow: unset;
      outline: none;
      color: #000;
    }
    .react-time-picker__inputGroup__leadingZero,
    .react-date-picker__inputGroup__leadingZero {
      color: #000;
      font-size: inherit;
      height: 100%;
      line-height: unset;
      padding-left: 1px;
    }
    .react-time-picker__inputGroup__divider {
      font-size: inherit;
      height: 100%;
      line-height: unset;
      color: #000;
    }
  }
}

.react-time-picker--disabled,
.react-date-picker--disabled {
  .react-time-picker__wrapper,
  .react-date-picker__wrapper {
    .react-time-picker__inputGroup,
    .react-date-picker__inputGroup {
      .react-time-picker__inputGroup__leadingZero,
      .react-date-picker__inputGroup__leadingZero,
      .react-time-picker__inputGroup__divider {
        cursor: not-allowed;
        filter: grayscale(100%);
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .react-date-picker__clear-button {
      background-color: unset;
    }
  }
}

.react-time-picker.invalid-control-background,
.react-date-picker.invalid-control-background {
  background-color: unset;
  .react-time-picker__wrapper,
  .react-date-picker__wrapper {
    background-color: $color-required-background;
  }
}

.react-date-picker__clear-button {
  min-width: unset;
  height: unset;
  padding: 0 10px;
  &:hover {
    box-shadow: unset;
  }
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: var($--main-theme-color);
}

textarea.message-textbox__input {
  border-radius: 16px;
  min-height: 200px * $scale-koef;
  background-color: #fff;
  padding: 22px * $scale-koef;
  font-size: 18px * $scale-koef;
  color: #000;
  overflow-y: auto;
}
.message-textbox.rtl {
  .message-textbox__highlighter {
    direction: rtl;
  }
}
.message-textbox__highlighter {
  min-height: 200px * $scale-koef;
  padding: 22px * $scale-koef;
  .message-textbox__highlighter__substring {
    color: transparent;
  }
  .message-textbox__highlighter__caret {
    color: transparent;
    font-size: 400;
  }
}