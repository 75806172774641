@import "../../style/_variables";
$new-scale-koef: 0.75;
$scale-koef: 0.75;

.patient-error-cell {
  background-color: RGBA(255, 0, 0, 0.11);
  margin: -12px -50px -12px -15px;
  padding: 12px 50px 12px 15px;
}
.patient-added-cell {
  background-color: RGBA(0, 255, 0, 0.11);
  margin: -12px -50px -12px -15px;
  padding: 12px 50px 12px 15px;
}
.patient-exists-cell {
  background-color: RGBA(249, 163, 83, 0.11);
  margin: -12px -50px -12px -15px;
  padding: 12px 50px 12px 15px;
}

.wizard.bulk-add-patients {
  max-width: 50vw;
  .upload-documents-drag-area {
    background-color: #FBFBFB;
    border: solid #D9D9D9 1px;
    border-radius: 20px;
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;

    p {
        width: 100%;
        text-align: center;
        font-size: 22px * $scale-koef;
        //line-height: 170px;
        color: #4F4F4F;
    }
    input {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
    }
    button {
        width: fit-content;
    }
    @property --progress {
      syntax: '<percentage>';
      inherits: false;
      initial-value: 0%;
    }

    &.condensed {
      flex-direction: row;
      width: 100%;
      height: unset;
      padding-bottom: 0;
      // progress
      background: linear-gradient(90deg, rgba(116, 178, 54, 0.2) var(--progress), transparent 0);
      transition: .3s --progress;

      .progress-border {
        width: unset;
        height: unset;
        background-color: unset;
        border-radius: unset;
        border: unset;
        box-shadow: unset;
      }
      .progress-border {
        margin-left: 23px;
        .upload-icon {
          width: 16px;
          height: 20px;
          background-color: var($--main-theme-color);
          -webkit-mask: url("../../images/uploadArrow.svg") no-repeat center/contain;
          mask: url("../../images/uploadArrow.svg") no-repeat center/contain;
      }
    }
    button {
      margin-right: 10px;
    }
  }
  .row {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
  ul {
    margin: 0;
  }
  label.header {
    color: #000;
    font-size: 16px;
  }
}


