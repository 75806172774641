.carousel {
  display: flex;
  position: relative;

  .carousel-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &.shrink-width {
      width: 84%;
    }

    .swiper-container {
      padding-right: 42px;
    }
  }

  a {
    margin: 0 !important;
    width: auto !important;
  }


  .next,
  .prev {
    padding: 0 15px;
    cursor: pointer;
  }
}

.gradient-container {
  content: " ";
  position: absolute;
  height: 53px;
  width: 50px;
  top: 0;
  z-index: 20;
}

