@import "../../style/variables";

.modalWrapper {
  position: absolute;
  right: 20px;
  bottom: 20px;
  box-shadow: 1px 5px 12px 1px rgba(0, 0, 0, 0.06);
  min-width: 600px * $scale-koef;
  max-width: 40vw;
  border-radius: 20px;
  border: 1px solid $color-border-base;
  background-color: #fff;
  z-index: 10;
  font-size: 18px * $scale-koef;
  &.minimized {
    border-radius: 100px;
    min-width: unset;
  }
  &.top-modal {
    z-index: 11;
  }
  &.mobile {
    max-width: 70vw;
  }

  button {
    margin: unset;
    min-width: unset;
    box-shadow: unset;
    &:hover {
      box-shadow: unset;
    }
  }

  .captionSmall {
    color: #E7D3D3;
    font-size: 18px * $scale-koef;
    font-weight: 400;
  }
  .captionBig {
    color: #fff;
    font-size: 25px * $scale-koef;
    font-weight: 600;
  }

  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
    color: #000;
    background-color: #fff;
    font-size: 27px * $scale-koef;
    letter-spacing: 0px;
    text-align: left;
    padding: 25px * $scale-koef;
    font-weight: bold;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-color: var($--main-theme-color);
    cursor: move;

    .steps {
      display: flex;
      column-gap: 10px;
      align-items: center;
  
      .step {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: var($--left-nav-child-color);
        &.completed {
          background-image: url("../../images/checkbox.svg");
          background-size: 100%;
        }
      }
    }
  }

  .modalTabs {
    display: flex;
    column-gap: 20px;
    background-color: #f7f7f7;
    padding: 20px;
    .tab {
      color: #000000;
      background-color: #f7f7f7;
      padding: 10px  * $scale-koef 30px  * $scale-koef;
      border-radius: 18px;
      font-size: 18px * $scale-koef;
      line-height: 18px * $scale-koef;
      white-space: nowrap;
      user-select: none;
      text-align: center;
      vertical-align: middle;
      cursor: default;
      //&:not(.active):hover,
      &.clickable:hover {
        cursor: pointer;
      }

      &.active {
        color: #FFF;
        background-color: var($--main-theme-color);
      }

      &:not(.clickable) {
        pointer-events: none;
      }
    }
  }

  .modalContent {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    background-color: #fff;
    padding: 25px * $scale-koef;
    padding-top: 0;
    height: auto;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    .hr {
      color: #D9D9D9;
      background-color: #D9D9D9;
      height: 1px;
      width: 100%;
      border-color : #D9D9D9;
    }
  }

  .modalContent, .modalContentMinimized {
    .button-bar {
      display: flex;
      flex-direction: row;
      column-gap: 30px;
      align-items: center;
      justify-content: space-between;
      .right {
        display: flex;
        align-items: center;
        column-gap: 10px;
        .buttonContainer {
          width: 34px;
          height: 34px;
          min-width: 34px;
          border-radius: 18px;
          margin: 0;
          padding: 0;
          background-color: #F3F3F3;
        }        
        .close-button {
          width: 34px;
          height: 34px;
          border-radius: 18px;
          min-width: 34px;
          margin: 0;
          padding: 0;
          -webkit-mask: url("../../images/closeIcon.svg") no-repeat center/contain;
          mask: url("../../images/closeIcon.svg") no-repeat center/contain;
          background-color: var($--main-theme-color);
        }
        .minimize-button {
          width: 34px;
          height: 34px;
          border-radius: 18px;
          min-width: 34px;
          margin: 0;
          padding: 0;
          -webkit-mask: url("../../images/leftArrow.svg") no-repeat center/contain;
          mask: url("../../images/leftArrow.svg") no-repeat center/contain;
          background-color: var($--main-theme-color);
          transform: rotate(270deg);
          &.collapsed {
            transform: rotate(90deg);
          }
        }
      }
    }    
  }
}

.modalContentMinimized {
  padding: 25px * $scale-koef;
  border-radius: 100px;
  background-color: #fff;
  cursor: move;
  .captionSmall {
    color: #4F4F4F;
  }
  .captionBig {
    color: var($--main-theme-color);
  }
}
