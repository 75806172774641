@import '../../style/variables';

.floating-modal.timer {
  .button-bar > .right {
    .buttonContainer:first-child {
      display: none;
    }
  }
  .task-note-container {
    position: relative;
    .task-notes-char-left {
      position: absolute;
      bottom: 0;
      right: 15px;
    }
  }

  .timer-header-expanded {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 30px;
    width: 100%;
    &.labels {
      width: unset;
    }
  }
  .label-with-timer {
    font-weight: 400;
    &.horizontal {
      display: flex;
      column-gap: 15px;
      align-items: center;
    }
    .timer-label {
      font-size: 18px * $scale-koef;
      color: #000;
      &.big {
        font-size: 25px * $scale-koef;  
      }
    }
    .timer {
      font-size: 25px * $scale-koef;
      &.blue {
        color: var($--main-theme-color);
      }
      &.pink {
        color: $color-error;
      }
      &.green {
        color: $color-good;
      }
    }
  }

  .react-select__menu-list {
    max-height: 245px;
  }
}