@import "variables";

@mixin schedule-box() {
  border: 1px #c8c8c8 solid;

  padding: 0 25px;
  margin-bottom: 30px;

  div.row {
    min-height: 70px;
    border-bottom: 1px #d7d7d7 dashed;

    .label {
      float: left;
      color: $color-brand;
    }

    &.title {
      color: $color-brand;
    }

    &.title,
    .label,
    .value {
      line-height: 70px;
    }

    .value {
      float: right;
    }

    &.times {
      .value {
        max-width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .week-days {
      clear: both;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-bottom: 30px;

      .week-day {
        text-align: center;
        width: 40px;
        line-height: 40px;
        border-radius: 20px;
        font-weight: bold;
        background: #e3e3e3;

        box-shadow: inset 0 1px 2px 0 $font-color-base;

        &.active {
          background: $color-gradient-blue;
          color: white;
          box-shadow: none;
        }
      }
    }
  }

  .buttons {
    margin: 30px 0;

    display: flex;
    justify-content: space-between;

    button {
      width: 49%;
    }
  }
}

.app-content {
  .medications {
    padding-bottom: 50px;

    .week-medications {
      border: 1px #c8c8c8 solid;

      margin-bottom: 25px;

      padding: 24px 32px;

      .numbers {
        float: left;
        width: 60px;

        .schedule-number {
          height: 51px;
          line-height: 51px;
        }
      }

      .week-parts {
        margin-left: 60px;

        .week-part {
          display: inline-block;
          width: calc(100% / 7);

          .week-content {
            .schedule {
              padding: 12px 0;
              border-bottom: 1px #d7d7d7 dashed;
              //cursor: pointer;

              &.empty {
                padding: 0;
                height: 51px;
              }

              .status-icon {
                margin: auto;
              }
            }
          }

          .week-footer {
            text-align: center;
            vertical-align: top;

            border-left: 1px #dcdcdc solid;
            border-right: 1px #dcdcdc solid;

            &.first {
              border-left-width: 2px;
            }

            &.last {
              border-right-width: 2px;
            }

            margin-top: 32px;

            .week-part-name {
              display: inline-block;
              line-height: 40px;
              vertical-align: top;

              span.short {
                display: none;
              }

              @media (max-width: 1080px) {
                span.short {
                  display: inline;
                }

                span.long {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .schedules-list {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
      margin-bottom: -30px;

      .schedule {
        width: 32%;

        @media (max-width: 1600px) {
          width: 49%;
        }

        @media (max-width: 1080px) {
          width: 100%;
        }

        @include schedule-box();
      }
    }

    .__react_component_tooltip {
      border-radius: 0;
      border-color: #d5d5d5;
      text-align: center;
      font-size: 12px;
      line-height: 1;
      border-radius: 10px;
      padding: 4px 10;
      border-radius: 10px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
      background-color: #ffffff;
      font-size: 12px;
      border: 0;
      color: #222;

      .line {
        height: 1px;
        border-bottom: 1px #ffffff dashed;
        margin: 8px 0;
      }

      &::before,
      &::after {
        content: none;
      }

      &::before {
        border-bottom-color: #f1f1f1;
      }
    }
  }
}

.patient-portal {
  div.dialog.edit-schedule {
    padding: 0;
    border: none;

    > header {
      display: none;
    }

    .schedule {
      @include schedule-box();

      width: 450px;
      margin-bottom: 0;

      input {
        height: 70px;
        border: none;
        padding: 0;
        color: #b9b9b9;

        &::placeholder {
          color: #b9b9b9;
        }

        &::-webkit-input-placeholder {
          color: #b9b9b9;
        }

        &::-moz-placeholder {
          color: #b9b9b9;
        }

        &:-ms-input-placeholder {
          color: #b9b9b9;
        }
        &:-moz-placeholder {
          color: #b9b9b9;
        }
      }

      .week-day {
        cursor: pointer;
      }

      .times {
        margin: 0 -5px;
        padding-top: 30px;
        width: 400px;

        .time {
          background: #b9b9b9;
          float: left;
          color: #ffffff;
          width: 90px;
          margin: 0 5px 5px;
          padding: 0 10px;
          line-height: 30px;
          border-radius: 15px;

          .delete {
            background: url("../images/time-picker-delete-time.png") no-repeat
              center;
            float: right;
            width: 10px;
            height: 10px;
            margin: 10px 0;
            cursor: pointer;
          }
        }
      }

      .clear {
        clear: both;
      }

      .errors-block {
        margin-top: 30px;
        color: $color-brand-red;
      }
    }
  }

  div.dialog.schedule-time-picker {
    padding: 0;
    border: none;

    > header {
      display: none;
    }

    .picker {
      padding: 0 25px;
      width: 450px;

      .top {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        line-height: 64px;

        .am-pm {
          text-align: center;
          width: 40px;
          height: 40px;
          margin-top: 12px;
          line-height: 40px;
          border-radius: 20px;
          font-weight: bold;
          background: #acacac;
          color: white;

          &.active {
            background: $color-gradient-blue;
          }

          cursor: pointer;
        }

        .time {
          font-size: 150%;
          font-weight: bold;
          display: flex;

          div {
            font-size: 150%;
            text-align: center;

            &.hours,
            &.minutes {
              width: 64px;
            }

            &.separator {
              margin-top: -5px;
              width: 20px;
            }
          }

          .active {
            color: $color-brand-red;
            border-radius: 32px;
            background: #ececec;
          }
        }
      }

      .clock-wrapper {
        border-top: 1px #d7d7d7 dashed;
        border-bottom: 1px #d7d7d7 dashed;

        padding: 30px 0;
      }

      .clock {
        margin: auto;
        padding: 22px;
        background: #ececec;
        border-radius: 50%;

        user-select: none;

        .numbers {
          position: relative;

          .value {
            position: absolute;
            width: 36px;
            line-height: 36px;
            text-align: center;
            font-weight: bold;
            cursor: pointer;
            border-radius: 18px;

            &.active,
            &:hover {
              background: $color-brand;
              color: #ffffff;
            }
          }

          .arrow {
            position: absolute;
            width: 14px;
            height: 14px;
            border-radius: 7px;
            background: $color-brand;
            left: calc(50% - 7px);
            top: calc(50% - 7px);

            &:after {
              content: " ";
              display: block;
              width: 2px;
              height: 84px;
              background: $color-brand;
              left: 6px;
              position: relative;
            }
          }
        }
      }

      .buttons {
        margin: 30px 0;

        display: flex;
        justify-content: space-between;

        button {
          width: 49%;
        }
      }
    }
  }
}
