@import "../../style/variables";

.modal-content {
  display: flex;
  flex-direction: column;

  form {
    flex: 1;

    @include new-form();

    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea {
      box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.04);
      color: #666666;
      height: 46px * $scale-koef;
      line-height: 16px;
      border-radius: 12px;
      text-align: left;
      padding: 11px 20px;

      &.dialog-form {
        background-color: #f0f0f0;

        &.disabled {
          cursor: not-allowed;
        }
        &select {
          -moz-appearance:none;
        }
      }

      & .react-datepicker-ignore-onclickoutside {
        width: 122px;
      }

      & .first-name {
        width: 122px;
      }

      @include input-focus-animation();
    }

    & .input-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: to-em(15);
    
      .label {
        font-size: to-em(16);
      }
    
      label {
        position: relative;
        display: inline-flex;
        align-items: center;
      }
    
      &.for-radio {
        .label {
          color: #4a4a4a;
        }
      }
    }

    textarea {
      height: 150px;
      width: 100%;
    }

    & .mb-15 {
      margin-bottom: 15px;
    }

    & .react-datepicker__input-container {
      input[type="text"] {
        width: 122px;
        background: #f0f0f0;
        margin: 0;

        & .react-datepicker-ignore-onclickoutside {
          background: #fff;
        }
      }
    }

    & .required {
      &::after {
        position: absolute;
        content: "*";
        color: $color-required-mark;
        top: 3px;
        left: 10px;
      }
    }

    & .study-hint {
      margin-bottom: 30px;
      font-size: 12px;
      color: $color-brand-red;
    }

    & .two-column-layout {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & .dialog-input:first-of-type,
        .user-timezone:first-of-type,
        .react-datepicker__input-container:first-of-type {
        margin-right: 10px;
      }
    }

    & .dialog-input {
      height: 50px;
    }

    & .autocomplete {
      height: 50px;
      overflow-y: visible;
      margin-bottom: 15px;

      .select {
        @include input-hover-animation();
        border: 0;
        background: #ffffff;
        border-radius: 10px;

        input {
          background: url("~/images/small-arrow-bottom.svg") no-repeat
            right 18px center;
          background-size: 12px 8px;
          margin-bottom: 0;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
          background-color: #f0f0f0;
          border-radius: 9.3px;
          color: #4a4a4a;
          margin-bottom: 0;
          height: 40px;
          line-height: 16px;
          border-radius: 12px;
          text-align: left;
          padding: 11px 20px;
          @include input-focus-animation-reset();

          &:focus,
          &:hover {
            background-color: #fff;
          }
        }

        &.opened {
          input {
            background-color: #fff;
          }
        }

        .items-wrapper {
          display: none;
          position: relative;
          z-index: 1;
          background: #ffffff;
          padding: 5px;
          border-bottom: 1px solid #d4d4d4;
          border-radius: 10px;

          .items {
            padding: 0px;
            max-height: 176px;
            overflow-y: auto;

            .item {
              cursor: pointer;
              cursor: pointer;
              font-family: GillSans;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0px;
              color: #808080;
              padding: 10px 5px 10px 16px;
              border-radius: 10px;

              &:hover {
                background-color: #ddd;
              }
            }
          }
        }

        &.opened {
          .line {
            margin: 0 5px;
            border-top: 1px #d4d4d4 dashed;
          }

          border-bottom: none;

          .items-wrapper {
            display: block;
          }
        }
      }
    }

    #description {
      resize: none;
    }
  }

  & .button-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 85px;

    & button {
      width: 100%;

      & .upload-btn {
        position: relative;
        div {
          & .upload-icon {
            background: url("~/images/rx/upload.png") no-repeat;
            background-size: 20px;
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            left: 12px;
            top: 9px;
          }
        }
      }
    }
  }
}


// .modal-content.add-medicine-modal form .autocomplete .select.opened .items-wrapper {
//   max-width: 278px;
// }

