@import '../../style/variables';

.config-modal {
  height: 100vh;
  width: 340px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;
  padding: 20px;

  h2 {
    margin: 0;
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
  }
  .buttons {
    display: flex;
    column-gap: 20px;
    padding: 10px 0 0;
    justify-content: flex-end;
    button {
      min-width: unset;
    }
  }

  .requiredFieldsError {
    color: $color-required;
    font-size: 18px * $scale-koef;
    line-height: 18px * $scale-koef;
    font-weight: 400;
    text-align: right;
  }

  .color-select {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    .colors {
      display: flex;
      height: 1em;
      .color-sample {
        width: 10px;
        display: block;
      }
    }
  }
}