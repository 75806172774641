/* -----------------------------------------------------------------------------

  BOX SIZING RESET
  
----------------------------------------------------------------------------- */

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  overflow: hidden;
}
