@import "variables";

.toast {
  visibility: hidden;
    max-width: 250px;
    margin-left: -125px;
    background-color: #222;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 10px 20px;
    position: fixed;
    font-size: 15px;
    z-index: 99;
    right: 16px;
    top: 70px;
    background: #FFCDD2;
    border: solid #e60017;
    border-width: 0 0 0 6px;
    color: #73000c;
    border-radius: 0;
    
  &.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 70px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    top: 70px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}


.toast-mobile {
  visibility: hidden;
    max-width: 136px;
    margin-left: -125px;
    background-color: #222;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 10px 20px;
    position: fixed;
    font-size: 15px;
    z-index: 1;
    right: 16px;
    top: 40px;
    background: #FFCDD2;
    border: solid #e60017;
    border-width: 0 0 0 6px;
    color: #73000c;
    border-radius: 0;
    
  &.show {
    visibility: visible;
  }
}


