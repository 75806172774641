@import '../../style/variables';

.advanced-filters {
  --af-border-radius: 12px;
  --af-list-width: 222px;
  --af-border-color: #bababa;
  --af-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.13);
  --af-hover-bg-color: var(--opaque-main-color-7);
  --af-bg-color: white;
  --af-font-color: #4f4f4f;
  --af-font-accent-color: var(--main-theme-color);
  --af-list-entry-height: 42px;
  --af-font-size: 18px;
  --af-white-font-color: white;

  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  justify-content: center;

  .borders-generic {
    border-radius: var(--af-border-radius);
    margin: 3px;
  }

  .list-generic {
    border: 1px solid;
    box-shadow: var(--af-box-shadow);
    border-color: var(--af-border-color);
    border-radius: var(--af-border-radius);
    background-color: var(--af-bg-color);
  }

  .arrow-generic {
    background: var($--right-arrow-icon) no-repeat;
    transform: rotate(180deg);
    background-position: center;
    background-size: 25%;
    width: 38px;
    height: 38px;
  }

  .list-item {
    @extend .borders-generic;
    display: flex;
    flex-direction: row;
    font-size: var(--af-font-size);
    height: var(--af-list-entry-height);
    padding-left: 18px;
    padding-right: 18px;

    .label-selector {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 10px;
      width: 100%;

      .selector-generic {
        flex: 0;
        width: 21px;
        min-width: 21px;
        height: 21px;
        display: grid;
        place-content: center;
        background-size: 100%;
        background-color: #F7F7F7;
        border: 1px solid #bababa;
        border-radius: 7px;
        -webkit-box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      }

      .label-list-item {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: var(--af-font-size);
        white-space: nowrap;
      }

      .checkbox-checked {
        @extend .selector-generic;
        background: url("../../images/checkbox.svg") no-repeat;
        background-color: #74b236;
        background-size: 100%;
        border: unset;
      }

      .checkbox-checked-disabled {
        @extend .checkbox-checked;
        filter: grayscale(1);
        opacity: 0.8;
        cursor: not-allowed;
      }

      .checkbox-unchecked {
        @extend .selector-generic;
      }
    }
  }

  .list-item:hover {
    background-color: var(--af-hover-bg-color);
  }

  .dropdown-list {
    @extend .list-generic;
    position: absolute;
    z-index: 10;
    width: var(--af-list-width);
    right: 0;
    top: 100%;
  }

  .side-list-container {
    position: relative;
  }

  .side-list {
    @extend .list-generic;
    position: absolute;
    z-index: 10;
    min-width: 200px;

    top: 0;
    right: 100%;
  }

  .side-list-main-header {
    @extend .borders-generic;
    height: var(--af-list-entry-height);

    .active {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: var(--af-hover-bg-color);
      border-radius: var(--af-border-radius);

      .arrow {
        @extend .arrow-generic;
      }

      .title {
        vertical-align: middle;
        font-size: var(--af-font-size);
        color: var(--af-font-accent-color);
      }
    }

    .non-active {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: var(--af-border-radius);

      .arrow {
        @extend .arrow-generic;
        filter: grayscale(100%);
      }

      .title {
        vertical-align: middle;
        font-size: var(--af-font-size);
        color: var(--af-font-color);
      }
    }
  }

  .side-list-main-header:hover {
    background-color: var(--af-hover-bg-color);
  }

  .horizontal-line {
    width: calc(100% + 32px);
    height: 1px;
    background-color: #d9d9d9;
    position: relative;
    right: -32px;
    margin-left: -32px;
  }

  .advanced-filters-bar {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
  }

  &.right-expanding {
    .dropdown-list {
      right: unset;
      left: 0;
    }
    .active {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .non-active {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .side-list-main-header .arrow {
      transform: none;
    }
    .title {
      padding-left: 10px;
    }
    .side-list {
      right: unset;
      left: 100%;
    }
  }
}

.round-button {
  width: 90px;
  height: 40px;
  background-color: var($--main-theme-color);
  border-radius: 20px;
  color: var(--af-white-font-color);
  font-size: 15px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  cursor: pointer;
}


.bar-item {
  display: flex;
  height: 40px;
  padding-left: 18px;
  padding-right: 1px;
  border-radius: 20px;
  border: solid 1px #efefef;
  background-color: #f5f5f5;
  font-size: 15px;
  color: black;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  cursor: pointer;

  .item-label {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    vertical-align: middle;
    align-items: center;
    justify-content: center;

    .pictograph {
      display: flex;
      align-items: center;
    }
  }

  .remove-icon {
    width: 36px;
    height: 36px;
    background-image: url('../../images/deleteImg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-left: 20px;
  }
}

.bar-item:hover {
    border: solid 1px #e4e4e4;
    background-color: #e4e4e4;
  }