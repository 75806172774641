@import "variables";

.user-profile {
  .container {
    overflow: hidden;
    width: 100%;
    background: #f7f7f7;
    margin-top: 20px;
  }
  .caption {
    padding: 0 48px 1px;
    border-bottom: dashed 1px $color-background-darker;
    line-height: 54px;
    margin-bottom: 20px;
  }
  div:nth-child(1) .caption {
    padding-left: 0;
  }
  .fields {
    max-width: 740px;

    .label {
      label {
        float: left;
        line-height: 30px;
        width: 200px;
      }
    }

    .field {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;
      line-height: 30px;

      input[type="text"],
      input[type="email"],
      input[type="password"] {
        box-sizing: border-box;
        width: 340px;
        padding: 0 25px;
        color: $font-color-placeholder;
        height: 34px;
        border-radius: 9.3px;
        border: 0;
        margin: 0;
        box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: #f0f0f0;

        &::placeholder {
          color: $font-color-placeholder;
        }

        &::-webkit-input-placeholder {
          color: $font-color-placeholder;
        }

        &::-moz-placeholder {
          color: $font-color-placeholder;
        }

        &:-ms-input-placeholder {
          color: $font-color-placeholder;
        }
        &:-moz-placeholder {
          color: $font-color-placeholder;
        }

        @include input-focus-animation();
      }

      select {
        box-sizing: border-box;
        width: 340px;
        height: 31px;
        line-height: 30px;
        border: 0;
        border-radius: 10px;
        background: #f0f0f0;
        padding: 0 25px;
        color: #b0b0b0;
        background: #f0f0f0;

        box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);

        &::placeholder {
          color: $font-color-placeholder;
        }
      }

      input:disabled {
        cursor: not-allowed;
      }

      select {
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-ms-expand {
          display: none;
        }

        &:focus::-ms-value {
          color: $font-color-base;
          background: transparent;
        }

        color: #555555;

        background: #f0f0f0 url("../images/small-arrow-bottom.svg") no-repeat
          right 18px center;
        background-size: 12px 8px;
      }
    }

    .date-of-birth {
      border-radius: 10px;
      background: #f0f0f0;
      width: 340px;
      box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.1);
      background-color: #f0f0f0;

      label {
        display: inline-block;
        width: 105px;
        color: #555555;
        margin-left: 25px;
      }

      .wrapper {
        float: right;
        padding-right: 5px;

        input {
          border: none;
          border-left: 1px solid #d4d4d4;

          width: 60px;
          height: 27px;
          line-height: 27px;
          padding: 0;
          text-align: center;

          box-shadow: none;
          &:focus {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
            background-color: #ffffff;
          }
        }
      }
    }

    .delay-select {
      display: inline-block;

      select {
        width: 270px;
        margin-right: 15px;
      }
    }

    .delay-switch {
      width: 50px;
      margin-left: 20px;
      display: inline-block;
    }
  }
  .message {
    font-weight: bold;
    display: inline-block;
    margin-left: 30px;
  }
  .errors {
    color: #f00;
  }
  .success {
    color: rgba(78, 192, 43, 0.75);
  }
  .buttons {
    display: inline-block;
    margin-bottom: 30px;
    margin-top: 20px;

    //   [role="button"] {
    //     width: 340px;
    //     &::after {
    //       display: none;
    //     }
    //   }
  }
}

@media screen and (max-width: 1450px) {
  .user-profile {
    .box {
      > div {
        display: block;
        width: 100%;
      }
      .fields {
        border: none;
      }
      div:nth-child(2) {
        .fields {
          margin-left: 0;
        }
      }
      .caption {
        padding-left: 0;
      }
    }
  }
  .app-root {
    overflow: auto;
  }
}
