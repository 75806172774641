$font-size: 18px * $scale-koef;
$font-size-day: 15px * $scale-koef;
$font-koef: 0.8;
$size-koef: 0.5;

.tab-view.study-page-content {
  height: 100%;

  .tab-content.active {
    height: calc(100% - 67px);
  }
}

.adherence-new-container {
  background: #FBFBFB;
  border: #d9d9d9 1px solid;
  border-radius: 20px * $scale-koef;

  margin-top: 15px * $scale-koef;
  flex: 1;
  padding: 40px * $scale-koef 20px * $scale-koef;
  display: flex;
  flex-flow: column nowrap;

  height: calc(100% - 96px);
  overflow: hidden;
  overflow-y: auto;

  @media (max-width: $width-laptop-hidpi) {
    padding: 40px*$size-koef 20px*$size-koef;
  }

  .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 55px * $scale-koef;
    padding-right: 30px * $scale-koef;

    @media (max-width: $width-laptop-hidpi) {
      margin-bottom: 55px*$size-koef;
    }

    & > * {
      flex: auto;
    }

    .filter {

      .adherence {
        display: inline-block;
        font-size: 21px;
        line-height: 21px;
        color: #4f4f4f;

        @media (max-width: $width-laptop-hidpi) {
          font-size: 17px;
          line-height: 17px;
        }

        span {
          font-size: 21px;
          line-height: 21px;

          &.good {
            color: #92DA7B;
          }

          &.normal {
            color: #EAAC6C;
          }

          &.bad {
            color: #E6819F;
          }
        }
      }

      select {
        @media (max-width: $width-laptop-hidpi) {
          max-width: 140px;
        }
      }

      select + .adherence {
        border-left: 2px solid #D9D9D9;
        padding-left: 15px;
      }
    }

    .legend {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      div {
        font-weight: 400;
        font-size: $font-size;
        color: #929292;
        position: relative;
        margin-left: 105px * $scale-koef;

        &:first-child {
          margin-left: 0;
        }

        @media (max-width: $width-laptop-hidpi) {
          font-size: $font-size*$font-koef;
        }

        &:before, &:after {
          position: absolute;
          content: "";
          top: 50%;
        }

        &:before {
          width: 36px * $scale-koef;
          height: 36px * $scale-koef;
          border: 1px solid #DADADA;
          border-radius: 36px * $scale-koef;
          background: white;
          margin-top: -18px * $scale-koef;
          left: -53px * $scale-koef;
        }

        &:after {
          width: 16px * $scale-koef;
          height: 16px * $scale-koef;
          border-radius: 16px * $scale-koef;
          margin-top: -8px * $scale-koef;
          left: -43px * $scale-koef;
        }


        &.taken:after {
          background: #92DA7B;
        }

        &.skipped:after {
          background: #EAAC6C;
        }

        &.missed:after {
          background: #E6819F;
        }
      }
    }

  }

  .data-table {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    height: calc(100% - 76px);
    position: relative;

    #trackHorizontal {
      visibility: hidden !important;
    }

    .HorizontalScrollContainer {
      position: relative;
      margin-top: 25px;
      margin-bottom: -25px;
      margin-left: 214px;
      margin-right: 92px;

      @media (max-width: $width-laptop-hidpi) {
        margin-top: 25px*$size-koef;
        margin-bottom: -25px*$size-koef;
        margin-left: 214px*$size-koef;
        margin-right: 96px*$size-koef;
      }
    }

    .col-name {
      flex-shrink: 0;
      width: 214px * $scale-koef;
      font-weight: 400;
      font-size: $font-size;
      padding-left: 30px;

      @media (max-width: $width-laptop-hidpi) {
        font-size: $font-size*$font-koef;
        width: 214px*$size-koef;
      }
    }

    .col-data {
      position: relative;

      .shadow {
        top: 0;
        bottom: 0;
        left: auto;
        right: auto;
        width: 20px;
        height: auto;
        background: linear-gradient(to right, rgba(251, 251, 251, 1) 0%, rgba(251, 251, 251, 0) 100%);

        &.left {
          left: 0;
        }

        &.right {
          right: 0;
          background: linear-gradient(to left, rgba(251, 251, 251, 1) 0%, rgba(251, 251, 251, 0) 100%);
        }
      }


      .day {
        position: relative;
        height: 100%;

        width: 38px * $scale-koef; /*24 +7 + 7*/
        padding-left: 7px * $scale-koef;
        padding-right: 7px * $scale-koef;
        flex-shrink: 0;

        &.start, &:first-child {
          padding-left: 10px * $scale-koef;
          width: 41px * $scale-koef;
        }

        &.end, &:last-child {
          padding-right: 10px * $scale-koef;
          width: 41px * $scale-koef;
          margin-right: 3px * $scale-koef;
        }

        @media (max-width: $width-laptop-hidpi) {
          width: 38px*$font-koef;
          padding-left: 7px*$font-koef;
          padding-right: 7px*$font-koef;

          &.start, &:first-child {
            padding-left: 10px*$font-koef;
            width: 41px*$font-koef;
          }

          &.end, &:last-child {
            padding-right: 10px*$font-koef;
            width: 41px*$font-koef;
            margin-right: 3px*$font-koef;
          }
        }
      }
    }

    .col-total {
      flex-shrink: 0;
      width: 62px * $scale-koef;
      font-weight: 400;
      font-size: $font-size;

      @media (max-width: $width-laptop-hidpi) {
        font-size: $font-size*$font-koef;
      }
    }

    .head, .body {
      display: flex;
    }

    .head {
      padding-right: 30px * $scale-koef;
      margin-bottom: 15px * $scale-koef;

      @media (max-width: $width-laptop-hidpi) {
        margin-bottom: 15px * $size-koef;
        padding-right: 30px * $size-koef;
      }

      & > div {
        height: 34px * $scale-koef;
        line-height: 34px * $scale-koef;

        @media (max-width: $width-laptop-hidpi) {
          height: 34px* $font-koef;
          line-height: 34px* $font-koef;
        }

        &.col-name {
          color: #4f4f4f;
        }

        &.col-data {
          display: flex;
          overflow: hidden;

          .days-container {
            display: flex;
            overflow: hidden;
          }

          .day {
            font-weight: 400;
            font-size: $font-size-day;
            color: #929292;
            background: #E5F0F6;
            text-align: center;
            z-index: 0;

            &.weekend {
              background: RGB(246, 240, 230);
            }

            &.start, &:first-child {
              border-top-left-radius: 17px;
              border-bottom-left-radius: 17px;

              &.hover:before {
                margin-left: -15px * $scale-koef;

                @media (max-width: $width-laptop-hidpi) {
                  margin-left: -15px*$font-koef;
                }
              }
            }

            &.end, &:last-child {
              border-top-right-radius: 17px;
              border-bottom-right-radius: 17px;

              &.hover:before {
                margin-left: -17px * $scale-koef;

                @media (max-width: $width-laptop-hidpi) {
                  margin-left: -17px*$font-koef;
                }
              }
            }

            &.hover:before {
              display: block;
              content: "";
              position: absolute;
              width: 32px * $scale-koef;
              height: 22px * $scale-koef;
              border-radius: 17px;
              background: white;
              top: 50%;
              left: 50%;
              margin-top: -11px * $scale-koef;
              margin-left: -16px * $scale-koef;
              z-index: -1;

              @media (max-width: $width-laptop-hidpi) {
                width: 32px*$font-koef;
                height: 22px*$font-koef;
                margin-top: -11px*$font-koef;
                margin-left: -16px*$font-koef;
              }
            }

            @media (max-width: $width-laptop-hidpi) {
              font-size: $font-size-day*$font-koef;
            }

          }
        }

        &.col-total {
          color: #4f4f4f;
          background: #f0f0f0;
          border-radius: 17px;
          text-align: center;
        }
      }
    }

    .body {
      flex: 1;
      overflow: auto;

      .row {
        margin-top: 10px * $scale-koef;
        margin-bottom: 10px * $scale-koef;
        height: 40px * $scale-koef;
        line-height: 40px * $scale-koef;
        display: flex;

        @media (max-width: $width-laptop-hidpi) {
          margin-top: 10px*$size-koef;
          margin-bottom: 10px*$size-koef;
          height: 40px*$font-koef;
          line-height: 40px*$font-koef;
        }

        &.hover {
          background: white;
        }
      }

      .col-name {
        color: #929292;
        padding-left: 0;

        .row {
          padding-left: 30px * $scale-koef;
          border-radius: 12px 0 0 12px;
          overflow: hidden;
          padding-right: 5px * $scale-koef;

          &.hover {
            color: #2492CF;
          }
        }
      }

      .col-data {

        .row {
          border-radius: 0 12px 12px 0;
        }

        .day {
          display: flex;
          flex-direction: column;
          justify-content: center;


          //pill is a little bit bigger than text in header, so we decrease padding
          padding-left: 3px * $scale-koef !important;
          padding-right: 3px * $scale-koef !important;

          &.start, &:first-child {
            padding-left: 6px * $scale-koef !important;
          }

          &.end, &:last-child {
            padding-right: 6px * $scale-koef !important;
          }

          &.start.end, &:first-child.end, &:last-child.start {
            padding-left: 5px * $scale-koef !important;
            padding-right: 4px * $scale-koef !important;
          }

          @media (max-width: $width-laptop-hidpi) {
            padding-left: 3px*$font-koef !important;
            padding-right: 3px*$font-koef !important;

            &.start, &:first-child {
              padding-left: 6px*$font-koef !important;
            }

            &.end, &:last-child {
              padding-right: 6px*$font-koef !important;
            }

            &.start.end, &:first-child.end, &:last-child.start {
              padding-left: 5px*$font-koef !important;
              padding-right: 4px*$font-koef !important;
            }
          }

          .pill {
            height: 22px * $scale-koef;
            border-radius: 17px * $scale-koef;
            background-color: #f7bfcd;
            position: relative;
            overflow: hidden;

            .green {
              background-color: #CAECBF;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;

              &:after {
                display: block;
                content: "";
                background: white;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 2px;
                right: -1px;
              }

              &.none {
                display: none !important;
              }

              &.full {
                width: 100% !important;

                &:after {
                  display: none !important;
                }
              }
            }

            &:hover {
              background-color: #E6819F;

              .green {
                background-color: #92DA7B;
              }
            }

          }
        }
      }

      .col-total {
        margin-right: 30px * $scale-koef;
        color: #929292;

        @media (max-width: $width-laptop-hidpi) {
          margin-right: 30px * $font-koef;
        }

        .row {
          justify-content: center;

          &.hover {
            background: none;
            color: #2492CF;
          }
        }
      }
    }

    .shadow {
      position: absolute;
      left: 0;
      right: 0;
      height: 20px * $scale-koef;
      background: linear-gradient(to bottom, rgba(251, 251, 251, 1) 0%, rgba(251, 251, 251, 0) 100%);
      opacity: 0;

      &.top {
        top: 49px * $scale-koef;

        @media (max-width: $width-laptop-hidpi) {
          top: 49px * $size-koef;
        }
      }

      &.bottom {
        bottom: 0;
        -webkit-transform: rotateX(180deg);
        transform: rotateX(180deg);
      }
    }
  }
}

.adherence-tooltip {
  .total {
    padding-bottom: 7px * $scale-koef;
    border-bottom: 1px solid #dbdbdb;
    color: #E6819F;

    &.high {
      color: #92DA7B;
    }
  }

  .date {
    padding-top: 7px * $scale-koef;
    padding-bottom: 7px * $scale-koef;
    border-bottom: 1px solid #dbdbdb;
    color: #4f4f4f;
  }

  .content {
    padding-top: 7px * $scale-koef;
    color: #4f4f4f;
  }
}