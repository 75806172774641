@import '../../../style/variables';


.dd-container {
  --af-hover-bg-color: #ecf3f8;
  --af-font-accent-color: #17639e;
  --af-border-radius: 12px;

  position: relative;
  .dd-list {
    margin-top: 5px;
    position: absolute;
    z-index: 999;
    background: white;
    border: 1px solid #DDD;
    padding: 2px;
    border-radius: var(--af-border-radius);
    left: -48%;
    .dd-list-item {
      display: block;
      background: transparent;
      &:hover {
        background-color: var(--af-hover-bg-color);
        color:var(--af-font-accent-color);
      }
    }
  }
}

.billing-container {
  .infoHeader {
    background: #FFF;
    border: 0;
    .rowDirection {
      width: 100%;
  
      .right {
        display: flex;
        flex: auto;
        justify-content: end;
  
        .mr20 {
          margin-right: 20px;
        }
      }
    }


button, [role="button"] {

  &.refreshTop {
    background: url('../../../images/refresh2.svg') no-repeat;
    width: 46px;
    min-width: 46px;
    height: 46px;
    margin-right: 0px;
  }
  &.addfilter {
    width: 145px;
    min-width: 46px;
    color: white;
    margin-right: 0px !important;
    box-shadow: none;
    background: transparent;
    border-radius: 13px;
    border: solid 1px #bababa;
    color: #4f4f4f;
    
    &.action {
      background-image: var($--arrow-down-icon);
      background-repeat: no-repeat;
      background-position: right;
      background-position: 104px -1px;
      background-size: 32px 45px;
    }
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    &:disabled {
      opacity: 0.3;
    }
  }

  &.addfilter {
    background-image: var(--plus-icon);
    background-repeat: no-repeat;
    background-position: right;
    background-position: 110px 12px;
    text-align: left;
  }
}
  
  }
}

.billing-container {
  .page-header .left {
    padding: 0;
  }
}

.bold-lable {
  font-weight: 600;
  color: #000;
}

.light-lable {
  font-weight: 400;
  color: $color-background-darkest;
}

.content-flexbox {
  line-height: 1.2;

  &.header {
    font-weight: 600;
    display: inline-block;
    margin-right: 20px;
    border-right: 1px solid #d9d9d9;
    height: 45px;
    line-height: 2.5;
    padding-right: 20px;

    span {
      &.key {  
        font-size: 17px;
        color: #000;
      }
  
      &.value {
        color: $color-background-darkest;
        font-size: 17px;
      }
    }
 
  }
}




.blue-lable{
  color: var($--main-theme-color);
}

.flex-row-item{

  &:first-child {
    .some-page-wrapper{
    margin-left: 0px;
    }
  }

  &:last-child {
    .some-page-wrapper{
    margin-right: 0;
    }
  }

}

.some-page-wrapper {
  margin: 15px;
  padding: 15px 28px 15px 16px;
  border-radius: 20px;
  border: solid 1px #d9d9d9;
  background-color: #f7f7f7;
  max-width: 800px;

  .row-block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }

  .column-block {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .blue-column {
    width: 150px;
    height: 150px;
    padding: 0 50px;
    margin: 0 21px 0 0;
    border-radius: 10px;
    background: #fff;
    align-items: center;
    display: flex;

    &.hub {
      padding: 0 25px;
    }
    @media (min-width: 1440px) and (max-width: 1639px) {
      width: 180px;
      height: 180px;
      padding: 0 50px;
    }

    @media (min-width: 1919px) {
      width: 250px;
      height: 250px;
      margin: 0 21px 0 0;
      padding: 21px 74px 24px;
    }
  }

  .green-column {
    height: 100px;
    line-height: 1.5;

    .head {
      font-size: 14px;
      text-transform: uppercase;

      @media (min-width: 1440px) and (max-width: 1639px) {
        height: 16px;
      }

      @media (min-width: 1919px) {
        font-size: 28px;
      }
    }

    .value {
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0.01px;
      color: var($--main-theme-color);

      @media (min-width: 1440px) and (max-width: 1639px) {
        font-size: 32px;
      }

      @media (min-width: 1919px) {
        font-size: 65px;
      }
    }

    .line {
      width: 100%;
      height: 1px;
      border-top: dashed 1px #979797;
    }

    .subHead {
      font-size: 14px;
      color: #4f4f4f;
      margin-top: 22px;

      @media (min-width: 1440px) and (max-width: 1639px) {
        height: 26px;
      }

      @media (min-width: 1919px) {
        font-size: 18px;
      }
    }

    .subHeadValue {
      font-size: 18px;
      font-weight: 600;
      color: #4f4f4f;
      @media (min-width: 1440px) and (max-width: 1639px) {
        height: 20px;
      }

      @media (min-width: 1919px) {
        font-size: 37px;
      }
    }
  }

  .img-block {
    width: 50px;
    height: 112px;
    background-size: contain;

    @media (min-width: 1440px) and (max-width: 1639px) {
      width: 56px;
      height: 128px;
    }

    @media (min-width: 1919px) {
      width: 91px;
      height: 205px;
    }
  }

  .lte-img {
    background-image: url("../../../images/rx/billing/ble.png");
    background-repeat: no-repeat;
  }

  .ble-img {
    height: 115px;
    background-image: url("../../../images/rx/billing/lte.png");
    background-repeat: no-repeat;

    @media (min-width: 1919px) {
      height: 191px;
    }
    
  }

  .hub-img {
    background-image: url("../../../images/rx/billing/hub.png");
    background-repeat: no-repeat;
    height: 45px;
    width: 100px;

    @media (min-width: 1440px) and (max-width: 1639px) {
      height: 90px;
      width: 199px;
    }
  }
}

.flex-row-container {
  background: #aaa;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.flex-row-container > .flex-row-item {
  flex: 1 1 30%; /*grow | shrink | basis */
}

.flex-row-item {
  background-color: white;
}
