$color-brand-light: #979797;

$color-good: #92DA7B;
$color-bad: #EAAC6C;
$color-ugly: #92DA7B;
$line-opacity: 0.4;

.average-time-delta {
  display: inline-block;
  float: left;
  margin-left: 25px;
}

.select-box-time-delta {
  display: inline-block;
  margin: 0px 20px auto;
}

svg.plot.timedelta {
  height: 500px;

  circle.time-marker {
    cursor: pointer;

    fill: #2492cf;
    // stroke: #585858;

    &.good {
      fill: $color-good;
    }

    &.bad {
      fill: $color-bad;
    }

    &.ugly {
      fill: $color-ugly;
    }
  }

  line.time-marker {
    stroke: $color-brand-light;
    stroke-width: 1;
  }

  line.notification_axis {
    stroke: #b6b6b6;
    stroke-width: 1;
  }

  line.connector {
    stroke: $color-brand-light;
    stroke-width: 10;
    opacity: $line-opacity;

    &.good {
      stroke: $color-good;
    }

    &.bad {
      stroke: $color-bad;
    }

    &.ugly {
      stroke: $color-ugly;
    }
  }

  g.go-grey {
    circle.time-marker:not(.active) {
      fill: transparentize($color-border-dark, $transparency);
      opacity: 0.5;

      //stroke: transparentize($color-border-dark, $transparency);
    }

    line.time-marker:not(.active),
    line.connector:not(.active) {
      stroke: transparentize($color-border-dark, $transparency);
      opacity: 0.5;
    }
  }
}
