@import '../../../style/variables';

.add-devices-bulk {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    textarea {
        resize: none;
        width: 800px;
        height: 400px;
    }

    input[type="file"] {
        visibility: hidden;
        position: absolute;
    }

    .cd-gray-border {
        display: flex;
        border: #DBDBDB 1px solid;
        width: 900px;
        height: 665px;
        background-color: #FBFBFB;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        .cd-first-upload {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            row-gap: 30px;
            width: auto;
            .cd-first-upload-text {
                font-size: 20px;
            }
            button {
                width: 100%;
            }
        }
    }
    .cd-spinner {
        width: 78px;
        height: 78px;
        background: url("../../../images/rx/pendingLoader.png") no-repeat center;
        animation: rotating 3s linear infinite;
    }

}