.add-edit-hardware-id {
    label {
        &.t-lable {
            font-size: 18px;
            }
        &.t-lable-blue{
            font-size: 18px;
            color: #14568d;
        }
    }

    .row-box {
        display: flex;
    align-items: center;
    justify-content: center;
    }



    .modal-content {
        form {
            input[type="text"]
            {
                height: 46px;
                margin: 28px 12px 28px 0;
                padding: 10px;
                text-align: center;
                border-radius: 13px;
                border: solid 1px #bababa;
                background-color: #f7f7f7;
                box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.22);
                font-size: 18px;
                font-weight: 600;
                color: #14568d;

                &.hardware-id {
                    width: 100%;
                    margin: 0;
                    margin-top:20px;
                    margin-right: 20px;
                    padding: 20px;
                }
            }
        }
    }
}