/* -----------------------------------------------------------------------------

  HEADING ELEMENTS
  
----------------------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color--neutral-dark;
  font-weight: 700;
  line-height: $line-height--small;
}