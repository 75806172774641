@import "../../../../style/variables";

$def-padding: 13px;
$alert-color: $color-error;

.dashboard-chart {
  overflow: hidden;
  background-color: $color-background-light;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  position: relative;
  height: 100%;
  .page-header {
    border-bottom: #D9D9D9 1px solid;
    padding-right: 15px;
    padding-left: 15px;
  }
  span {
    color: unset;
    font-size: unset;
  }

  .simplebar-100percent-width.with-footer {
    border-radius: 0;
    table.table {
      border-radius: 0;
      & > thead {
        & > tr {
          & > th {
            &:last-child {
              border-top-right-radius: 0;
            }
            &:first-child {
              border-top-left-radius: 0;
            }
          }
        }
      }
      & > tbody {
        & > tr {
          &:last-child {
            & > td {
              &:first-child {
                border-bottom-left-radius: 0;
              }
              &:last-child {
                border-bottom-right-radius: 0;
              }
            }
          }
          &.with-buttons {
            td {
              &:nth-last-child(2) {
                border-bottom-right-radius: 0;
              }
            }
          }
        }
      }
    }
  }

  .boxStatsWithGraph-graph {
    display: flex;
    flex-direction: column;
    .graphDiv {
      height: unset;
      flex: 1;
      min-height: 0;
    }
  }
}

.reading-details-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 20px;
  .medication-details-page {
    flex: 1;
    min-height: 0;
  }
  .page-header {
    margin-right: -$margin-mobile;
    margin-left: -$margin-mobile;
    padding: 0 $margin-mobile;
    background-color: #F4F8FC;
    .left {
      min-width: 0;
      padding-right: 0;
      .text-with-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .reading-details-page-graph {
    flex: 1;
  }
}
.dashboard-row {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex: 0 1 auto;
  flex-flow: column;

  span {
    color: unset;
    font-size: unset;
  }
}
.horizontal-rule {
  color: #D9D9D9;
  background-color: #D9D9D9;
  height: 1px;
  width: 100%;
  border-color : #D9D9D9;
  &.thick {
    height: 2px;
  }
}

.mobile .horizontal-rule {
  margin-right: $margin-mobile;
  margin-left: $margin-mobile;
  width: calc(100% - 2*$margin-mobile);
  background-color: #F0F0F0;
}

.section-header {
  font-weight: 600;
  font-size: 27px * $scale-koef;
  line-height:  27px * $scale-koef;
  height: 100%;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;

  .section-header-inputs {
    display: flex;
    flex-direction: row;
    column-gap: 50px * $scale-koef;
    justify-content: flex-end;
    align-items: center;
  }
  .row {
    display: flex;
    align-items: center;

    label {
      margin-right: 15px;
      color: #000;
      font-size: 18px * $scale-koef;
    }
    input:not([type="checkbox"]), select {
      font-size: 18px * $scale-koef;
      line-height: 46px * $scale-koef;
      width: 80px;
      min-width: auto;
      height: 46px* $scale-koef;
      margin: 0;
      border-radius: 13px;
      box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.22);
      border: solid 1px #bababa;
      background-color: #F7F7F7;
      color: #000000;
      padding-left: 22px;
      text-align: center;
  
      &:hover {
        box-shadow: none;
      }
    }
  
    select {
      font-family: $font-family-base;
      background-image: var($--arrow-down-icon);
      background-repeat: no-repeat;
      background-position: right 7px center;
      background-size: 25px*$scale-koef;
    }
  }
  button.dark_blue {
    min-width: unset;
    margin: 0;
  }
  .alert-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}


.grid-readings-cell {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: $def-padding;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #f7f7f7;
  cursor: pointer;
  color: #000;
  container: widget / size;

  &.selected {
    box-shadow: inset 0 3px 10px 0px rgba(0, 0, 0, 0.22);
    background-color: #F0F0F0;
    
    &.alerted {
      box-shadow: inset 0 3px 10px 0px rgba(0, 0, 0, 0.6);
    }
  }

  &.notClickable {
    cursor: default;
    pointer-events: none;
  }

  &.alerted {
    background-color: $alert-color;
    color: #fff;
  }

  &.left-aligned {
    align-items: flex-start;
  }

  .cell-el {
    max-width: calc(100% - $def-padding * 2);
    display: inline-block;

    &.title {
      font-weight: 400;
      font-size: 25cqh;
      line-height: 27cqh;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.value {
      font-weight: 600;
      font-size: 39cqh;
      line-height: 39cqh;
    }
  }
}

    .vertical-layout-widet {
      width: 100%;
      min-width: 0;
      display: inline-grid;
      justify-content: left;
      align-items: center;
      &.clickable {
        cursor: pointer;
      }
      &.info-label {
        justify-content: left;
        padding-left: 30px * $scale-koef;
        font-size: 15px;
        white-space: nowrap;
        min-width: 0;
      }
      &.info-value {
        justify-content: left;
        padding-left: 30px * $scale-koef;
        color: black;
        font-size: 18px * $scale-koef;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
        overflow: hidden;
        width: 100%;
        display: inline-block;
      }

      &.value {
        font-size: 48px * $scale-koef;
        line-height: 48px * $scale-koef;
        color: black;
        font-weight: 600;
        padding-left: 20px;
        width: unset;
      }
      &.descr {
        font-size: 24px * $scale-koef;
        padding-left: 20px;
        width: unset;
      }
  
      .nav-icon {
        width: 26px;
        height: 26px;
        margin-left: auto;
        transform: rotate(-90deg);
        background-color: #BABABA;
        -webkit-mask: var($--arrow-down-icon) no-repeat center/contain;
        mask: var($--arrow-down-icon) no-repeat center/contain;
      }
  

      &.col-sep {
        border-left-style: solid;
        border-left-width: 1px;
        border-left-color: #d9d9d9;
        width: 1px;
        height: calc(100% + 40px);
      }

      &.row {
        height: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: flex-start;
      }
  
      &.col {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
      &.col-1-3 {
        flex: 1;
      }
      &.col-2-3 {
        flex: 2;
      }
    }

.red-badge {
  padding: 2px 5px;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: $alert-color;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.patient-log-filters {
  height: 100%;
  margin-right: -10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  font-size: 18px * $scale-koef;
  font-weight: 400;
}

.add-patient-from-cap-manager,
.patient-log-filters,
.enroll-patient-page,
.edit-cohort > .modal-content > form {
  input:not([type="checkbox"]), select {
    font-size: 18px * $scale-koef;
    width: auto;
    min-width: auto;
    height: 46px* $scale-koef;
    margin: 0;
    border-radius: 13px;
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.22);
    border: solid 1px #bababa;
    background-color: #F7F7F7;
    color: #000000;
    padding-left: 22px;
    &.invalid-control-background {
      background-color: $color-required-background;
    }
  }
  select {
    min-width: 266px * $scale-koef;
    background-image: var($--arrow-down-icon);
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 25px*$scale-koef;
  }
  .addfilter {
    width: 145px;
    margin: 0;
    min-width:  46px * $scale-koef;
    color: white;
    border: 0;
    background: var($--main-theme-color);
    box-shadow: none;
    &:disabled {
      opacity: 0.3;
    }
  }
}

.patient-log-widget {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px;
  height: 100%;
  .section-header {
    height: unset;
  }
  .patient-log {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 20px;
    .patient-log-date {
      background-color: #F5F5F5;
      border-radius: 20px * $scale-koef;
      border: 1px solid #EFEFEF;
      color: #4F4F4F;
      font-size: 18px * $scale-koef;
      padding: 9px * $scale-koef 20px * $scale-koef;
      width: fit-content;
    }

    .patient-log-logitem {
      display: flex;
      column-gap: 10px;
      border-radius: 20px * $scale-koef;
      padding: 5px;
      position: relative;
      left: -5px;
      &:hover {
        background-color: #F7F7F7;
        .edit-button {
          position: absolute;
          right: 0;
          display: block;
          border: 2px solid var($--main-theme-color);
          border-radius: 23px;
          width: 46px*$scale-koef;
          min-width: 46px*$scale-koef;
          height: 46px*$scale-koef;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          .icon {
            width: 15px;
            height: 15px;
            background: url('../../../../images/rx/edit.svg') no-repeat center center;
            filter: var($--svg-filter);
            background-size: contain;
          }
          &:hover {
            box-shadow: none;
          }
        }
      }
      .icon-container {
        flex: 0 0 auto;
        width: 80px * $scale-koef;
        height: 80px * $scale-koef;
        border: 1px solid #D9D9D9;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        .icon {
          width: 24px;
          height: 24px;
          background: url('../../../../images/rx/edit.svg') no-repeat center center;
          filter: brightness(0) saturate(100%) invert(61%) sepia(1%) saturate(0%) hue-rotate(106deg) brightness(99%) contrast(87%);
          background-size: contain;
          &.completed {
            width: 50px;
            height: 50px;
            background: url('../../../../images/checkbox.svg') no-repeat center center;
            filter: brightness(0) saturate(100%) invert(50%) sepia(99%) saturate(393%) hue-rotate(56deg) brightness(92%) contrast(88%);
            background-size: 50px 50px;
          }
          &.open {
            width: 50px;
            height: 50px;
            background: url('../../../../images/checkbox.svg') no-repeat center center;
            filter: brightness(0) saturate(100%) invert(61%) sepia(1%) saturate(0%) hue-rotate(106deg) brightness(99%) contrast(87%);
            background-size: 50px 50px;
          }
        }
      }
      .item {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
      .title {
        color: #000000;
        font-size: 23px * $scale-koef;
        font-weight: 600;
        .date {
          font-size: 23px * $scale-koef;
          font-weight: 400;
          color: #505050;
          margin-left: 5px;
        }

      }
      .content {
        max-width: 60vw;
        font-size: 18px * $scale-koef;
        line-height: 30px * $scale-koef;
        color: #4F4F4F;
        word-wrap: break-word;
      }
      button {
        background-color: #fff;
        border: 1px solid #BABABA;
        border-radius: 23px * $scale-koef;
        color: #4F4F4F;
        box-shadow: none;
        min-width: fit-content;
        width: fit-content;
        margin: 0;
      }
      .edit-button {
        display: none;
      }
    }
  }
}

td.alerts>.alert-icon {
  height: 26px;
  width: 26px;
  min-width: 26px;
  margin-right: 13px;
  background-color: $color-error;
  -webkit-mask: url("../../../../images/exclamation_mark.svg") no-repeat center/80%;
  mask: url("../../../../images/exclamation_mark.svg") no-repeat center/80%;
  mask-composite: exclude;
}
.medication-details-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  .capEventsContainer {
    min-height: 0;
    flex: 1;
  }
}

.patient-dashboard-mobile {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.mobile {
  .dashboard-chart {
    border: none;
    .page-header {
      border-bottom: none;
      padding-right: $margin-mobile;
      padding-left: $margin-mobile;
    }
    .right {
      min-width: 0;
    }
    .react-select-container {
      max-width: calc(100% / 2 - 15px / 2);
    }
  }
}