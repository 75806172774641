.radio-buttons {
    display: flex;
    flex-direction: row;
    color: #000;
    align-items: center;
    column-gap: 20px;
    margin-right: 10px;

    .title-label {
        color: #000;
    }

    .radio-label-selector {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
        
        .radio-label {
            color: #000;
        }

        .checkbox-unchecked {
            background: url("../../images/rx/unCheckRadio.svg") no-repeat;
            background-position: center;
            width: 35px;
            height: 35px;
            display: flex;
            padding: 5px;
        }
        
        .checkbox-checked {
            background: url("../../images/rx/checkRadio.svg") no-repeat;
            background-position: center;
            width: 35px;
            height: 35px;
            display: flex;
            padding: 5px;
        }
    }
}