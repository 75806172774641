@import "../../style/variables";

.slider-picker-wrapper {
  display: flex;
  flex-flow: row nowrap;
  clear: both;
  height: 66px;
  padding: 15px 0;

  .slider-wrapper {
    flex: 1;
    align-items: center;
    // display: flex;
  }
  .outside-label {
    padding-right: 24px*0.85;
    font-size: 18px*0.85;

    color: $color-date-picker-label;
    @media (max-width: 1450px) {
      padding-right: 10px;
    }
  }
  .datepickers-wrapper {
    flex: 1;
    max-width: 525px;
    white-space: nowrap;
    text-align: right;
    justify-content: right;

    &.manualDatePicker {
      max-width: none;
      flex: none;
    }

    .datepicker-wrapper {
      line-height: 30px;
      overflow: hidden;
      float: right;
      & > div {
        /* @todo: find a way to add any class to datepicker component */
        display: inline-block;
        line-height: normal;
      }
      .react-datepicker-popper {
        z-index: 99;
      }
      .react-datepicker__input-container {
        input {
          text-align: center;

          max-width: 164px * 0.85;
          height: 46px * 0.85;
          border-radius: 13px * 0.85;
          font-size: 18px*0.85;

          box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
          border: solid 1px #bababa;
          background-color: #f7f7f7;
          color: #000;
          letter-spacing: 2px;
          padding: 0;

          @include input-focus-animation();
        }
      }

      &.end-date-picker {
        .react-datepicker-popper .react-datepicker {
          left: calc((100% / 2) * (-1));

          > .react-datepicker__triangle {
            left: auto;
            right: 50px;
          }
        }
      }

      .react-datepicker {
        font-family: $font-family-base;
      }
    }

    &.start-date-picker {
      .outside-label {
        padding-left: 50px * 0.85;
      }
    }
  }

  @media (max-width: $width-laptop-hidpi) {
    .outside-label {
      padding-right: 14px;
      color: $color-date-picker-label;
      font-size: 12px;
    }

    .datepickers-wrapper {
      // max-width: 335px;

      .datepicker-wrapper {
        padding-left: 0px;

        .react-datepicker__input-container {
          input {
            max-width: 120px;
          }
        }
      }
    }
  }
}
