
.tab-view  {
    .tab-content  {
        display: block;
    }
}

.tabButton {
    &[role="button"]{
        &:hover {
            box-shadow: none;
        }
    }
    &button{
        &:hover {
            box-shadow: none;  
        }
    }
}

.edit-icon{
    display: block;
    height: 22px;
    width: 22px;
    margin-right: 12px;
    background: url("../../../images/rx/edit.svg") no-repeat;
    background-size: 22px 22px;
}

.tick-icon{
    display: block;
    height: 35px;
    width: 35px;
    margin-right: 12px;
    background: url("../../../images/rx/billing/tick.svg") no-repeat;
    background-size: 35px;
}

.not-submitted-icon {
    display: block;
    height: 35px;
    width: 35px;
    margin-right: 12px;
    // background: url("../../../images/rx/billing/tick.svg") no-repeat;
    background-size: 35px;
}

.hold-icon{
    display: block;
    height: 35px;
    width: 35px;
    margin-right: 12px;
    background: url("../../../images/rx/billing/hold.svg") no-repeat;
    background-size: 35px;
}

.trash-icon{
    display: block;
    height: 22px;
    width: 22px;
    background: url("../../../images/trash.svg") no-repeat;
    background-size: 22px 22px;
}

.close-icon{
    display: block;
    height: 32px;
    width: 32px;
    background: url("../../../images/rx/close.svg") no-repeat;
    background-size: 32px 32px;
}