@import '../../style/variables';

.cloud-documents {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    input[type="file"] {
        visibility: hidden;
        position: absolute;
    }

    .cd-gray-border {
        display: flex;
        margin-top: 80px;
        border: #DBDBDB 1px solid;
        width: 900px;
        height: 665px;
        background-color: #FBFBFB;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        .cd-first-upload {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            row-gap: 30px;
            width: auto;
            .cd-first-upload-text {
                font-size: 20px;
            }
            button {
                width: 100%;
            }
        }
    }
    .cd-spinner {
        width: 78px;
        height: 78px;
        background: url("../../images/rx/pendingLoader.png") no-repeat center;
        animation: rotating 3s linear infinite;
    }

}

.cd-file-table {
    width: 100%;
    margin-top: 15px;

    input[type="file"] {
        visibility: hidden;
        position: absolute;
    }

    .cd-small-drop {
        border: #DBDBDB 1px solid;
        background-color: #FBFBFB;
        font-size: 20px;
        width: 500px;
        text-align: center;
        border-radius: 15px;
        margin-right: 15px;
        &.progressing {
            // progress
            background: linear-gradient(90deg, rgba(116, 178, 54, 0.2) 100%, transparent 0) no-repeat;
            background-size: 50% 100%;
            animation: progressing 2s linear infinite;
        }
    }
}

.upload-documents-drag-area,
.cd-first-upload {
  .progress-border {
    width: 124px * $scale-koef;
    height: 124px * $scale-koef;
    background-color: #FFFFFF;
    border-radius: 100px;
    border: #DADADA 1px solid;
    box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    display: flex;
  
    .progress {
      width: 100px * $scale-koef;
      height: 100px * $scale-koef;
      border: unset;
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 100px;
      &.rotating {
        border: 5px inset #74b236;
        -moz-animation: rotate 5s infinitelinear;
        -webkit-animation: rotate 5s infinite linear;
        animation: rotate 5s infinite linear;
        //box-shadow: 0 0 5px rgba(255,255,255,1);

        @-moz-keyframes rotate{
          0% {-moz-transform: rotate(0deg);}
          100% {-moz-transform: rotate(360deg);}
        }

        @-webkit-keyframes rotate{
          0% {-webkit-transform: rotate(0deg);}
          100% {-webkit-transform: rotate(360deg);}
        }

        @keyframes rotate{
          0% {transform: rotate(0deg);}
          100% {transform: rotate(360deg);}
        }
      }
    }

    .upload-icon {
      width: 30px * $scale-koef;
      height: 40px * $scale-koef;
      background-color: var($--main-theme-color);
      -webkit-mask: url("../../images/uploadArrow.svg") no-repeat center/contain;
      mask: url("../../images/uploadArrow.svg") no-repeat center/contain;

      &.rotating {
        -moz-animation: rotate-rev 5s infinitelinear;
        -webkit-animation: rotate-rev 5s infinite linear;
        animation: rotate-rev 5s infinite linear;
      }
    }
  }
}


@-moz-keyframes rotate-rev{
    0% {-moz-transform: rotate(0deg);}
    100% {-moz-transform: rotate(-360deg);}
}
  
@-webkit-keyframes rotate-rev{
    0% {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(-360deg);}
}
  
@keyframes rotate-rev{
    0% {transform: rotate(0deg);}
    100% {transform: rotate(-360deg);}
}

@-webkit-keyframes progressing {
    0% { background-position: left bottom; }
    100% { background-position: right bottom; }
}

@keyframes progressing {
    0% { background-position: left bottom; }
    100% { background-position: right bottom; }
}


@-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
}
  