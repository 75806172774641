@import "variables";

* {
  // outline: none;
  text-decoration: none;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.full-height {
  height: 100%;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

body,
span,
span.label,
button,
label,
textarea,
select,
a {
  font-size: $font-size-base;
  font-family: $font-family-base;
  font-weight: $font-weight-semibold;
  line-height: 1.42857143em;
  color: $font-color-base;
  transition: background-color $animation-swift-out, color $animation-swift-out;
}

.form-container {
  border-radius: 13.3px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  padding: 12px 12px 0 12px;
  overflow: hidden;

  &.by-email {
    padding: 39px 12px 14px 12px;
  }

  &.mb-30 {
    margin-bottom: 27px;
  }
}

button {
  line-height: initial;
}

button {
  cursor: pointer;
}

header {
  // height: $header-height;
  box-sizing: border-box;
}

.color-brand-red {
  color: $color-brand-red;
}

.color-brand-grey {
  font-size: 12px;
  font-weight: 600;
  color: $color-brand-grey;
}

// .ready-colour {
//   color: $color-plot-pending !important;
// }

// .taken-colour {
//   color: $color-plot-complied !important;
// }

// .missed-colour {
//   color: $color-plot-missed !important;
// }

// .skipped-colour {
//   color: $color-plot-skipped !important;
// }

.add-arrow {
  &:after {
    content: " ";
    width: 9px;
    height: 10px;
    background: url("../images/arrow.png");
    display: inline-block;
  }

  &.left:after {
    background-position-y: -10px;
  }
}

.dialog-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparentize(black, 0.85);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &.side-panel {
    justify-content: flex-end;
  }

  div.dialog {
    border: 1px solid $color-border-base;
    background-color: white;
    min-width: 320px;
    max-width: 720px;
    height: auto;

    > header {
      font-size: $font-size-large;
      font-weight: $font-weight-semibold;
      display: block;
      width: auto;
      height: auto;
      vertical-align: middle;
      margin: 0;
      border: none;
      line-height: normal;
      text-align: center;
      color: #4f4f4f;
      padding: 27px;

      .float-right {
        float: right;
      }

      span[role="button"] {
        height: 28px;
        line-height: 28px;
        margin: 0 8px;
        width: auto;
      }
    }
    form {
      position: relative;
      span.row {
        // display: block;
      }

      div.button-bar {
        margin-top: 75px;

        > *:first-child {
          margin-right: 10px;
        }
      }

      div.reverse {
        > *:first-child {
          margin-right: 0;
        }
      }

      label {
        display: none;
      }
      input[type="hidden"] {
        display: none;
      }
      input {
        box-shadow: none;
        width: 100%;
        border: none;
        border-radius: 0;
        background-color: white;
        height: 60px;
        border-bottom: 1px solid $color-border-base;
        transition: border-bottom $animation-swift-out;
      }

      .dialog-label {
        float: left;
        line-height: 2.5;
        width: 100px;

        &.bold {
          font-weight: bold;
          margin-left: 10px;
        }
      }

      .two-column-layout {
        width: 50%;
        float: left;

        .mr-20 {
          width: 223px;
        }
      }

      select {
        cursor: pointer;
        width: 100%;
        background: white;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $color-border-base;
        height: 60px;

        padding: 0 15px;

        &.react-datepicker__month-select,
        &.react-datepicker__year-select,
        &.dialog-form {
          cursor: pointer;
          width: 100%;
          border: none;
          border-radius: 0;
          background: #f0f0f0;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
          height: 24px;
          border-radius: 9px;
          padding: 0 14px;
          margin-top: 4px;
          color: #222;
          font-size: 15px;

          &:focus {
            background: #fff;
          }
        }

        &.dialog-form {
          height: 40px;
          margin-top: 0;
        }
      }

      > .errors-block {
        margin-top: 10px;
        color: red;
      }
    }
  }
}

.errors-block {
  margin-top: 10px;
  color: red;
  overflow-wrap: break-word;
}

input {
  &.password-text {
    &::-webkit-contacts-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    &::-webkit-caps-lock-indicator {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    &::-ms-clear {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }

    &::-ms-reveal {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  }
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

div.dialog-overlay {
  div.dialog.box.form-modal {
    flex: 608px 0 1;
    min-height: 300px;
    border-radius: 15px;
    box-shadow: 1px 5px 12px 1px rgba(0, 0, 0, 0.06);

    header {
      height: auto;
      margin: 0;
    }

    .content {
      background-color: #f7f7f7;
      padding: 40px 55px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      min-height: 245px;
    }

    &.user-invite {
      flex: 535px 0 1;

      &.invite-admin {
        flex: 400px 0 1;
      }

      form {
        .button-bar {
          button {
            width: auto;
          }
        }
      }
    }

    &.confirmation-modal {
      flex: 535px 0 1;

      header {
        line-height: inherit;
        text-align: center;
      }

      div.title {
        font-weight: $font-weight-bold;
        text-align: center;
      }

      div.caution {
        text-align: center;
        color: red;
        margin-bottom: 3em;
      }
    }
  }
}

.top-bar {
  position: absolute;
  width: 50%;
  display: inline-block;
  border-top: 1px solid #c8c8c8;
  padding-top: 15px;
  left: 0;
  right: 0;
  margin: 65px auto auto;
}

@import "./layout-landing";
@import "./layout-center";
@import "./layout-main";

.tablet-image {
  background-image: url(../images/tablet-image.png);
  background-size: cover;
  display: inline-block;
  width: 273px;
  height: 210px;
}

.tablet-image.studies-image {
  background-image: url(../images/ipadScreens_Studies_273x210.jpg);
}

.tablet-image.patients-image {
  background-image: url(../images/ipadScreens_Patients_273x210.jpg);
}

.tablet-image.landing-image {
  background-image: url(../images/ipadScreens_Landing_273x210.jpg);
  width: 273px;
  height: 210px;
}

@media (-webkit-min-device-pixel-ratio: 2),
  (min--moz-device-pixel-ratio: 2),
  (min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
  .tablet-image.studies-image {
    background-image: url(../images/ipadScreens_Studies_545x420.jpg);
  }

  .tablet-image.patients-image {
    background-image: url(../images/ipadScreens_Patients_545x420.jpg);
  }

  .tablet-image.landing-image {
    background-image: url(../images/ipadScreens_Landing_545x420.jpg);
  }
}

.box {
  > header {
    .help-text {
      color: #ccc;
    }
  }
  > .no-data-image-container {
    text-align: center;
    display: flex;

    > .no-data-image {
      margin: auto;
      /* height: 84px; */
      /* width: 84px; */
      /* display: inline-block; */
      /* background-image: url(/react/1e63ba8….png); */
      background-color: white;
      width: 101px;
      height: 30px;
      opacity: 0.5;
      font-family: GillSans;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #929292;
      border: 1px solid #929292;
      border-radius: 7px;
    }
  }

  position: relative;
  height: 100%;
}

.chart-circle-container {
  border-radius: 142px;
  border: 20px solid #dfdfdf;
  padding: 11px;
  width: 200px;
  height: 200px;
}

.__react_component_tooltip {
  border-radius: 0;
  border-color: #d5d5d5;
  text-align: center;
  line-height: 1.4;
  border-radius: 10px;
  padding: 4px 10;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  font-size: 14.4px;
  border: 0;
  color: #222;
  white-space: normal;
  max-width: 500px;
  word-break: break-all;

  .line {
    height: 1px;
    border-bottom: 1px #ffffff dashed;
    margin: 8px 0;
  }

  &.type-light {
    &::before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      top: auto;
    }

    &::after {
      border-color: transparent;
      border-bottom-color: #ffffff;
      border-width: 10px;
      margin-left: -10px;
    }

    &::before {
      border-color: transparent;
      border-bottom-color: $color-border;
      border-width: 11px;
      margin-left: -11px;
    }

    &.place-bottom {
      &::after {
        border-bottom-width: 10px;
      }
    }

    &::before {
      border-bottom-color: #f1f1f1;
    }
  }
}

.approval-status {
  width: 20px;
  height: 20px;
  background-size: 20px;

  // @media (max-width: $width-laptop-hidpi) {
  //   width: 17px;
  //   height: 17px;
  // }

  @keyframes rotating {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }

  &.accepted {
    background: url("../images/rx/patient-accepted.svg") no-repeat;
    background-size: 20px;
    // @media (max-width: $width-laptop-hidpi) {
    //   background-size: 16px;
    // }
  }

  &.active {
    background-image: url("../images/rx/active.svg");
  }

  &.user-pending {
    background-image: url("../images/esi/pending.png");
  }

  &.un-accepted {
    background: url("../images/rx/patient-un-accepted.svg") no-repeat;
    background-size: 20px;
    // @media (max-width: $width-laptop-hidpi) {
    //   background-size: 16px;
    // }
  }

  &.inactive-medicine {
    background: url("../images/rx/inactiveMedicine.svg") no-repeat;
    background-size: 20px;
    // @media (max-width: $width-laptop-hidpi) {
    //   background-size: 16px;
    // }
  }

  &.fa-disabled {
    background: url("../images/superuser/disabled.svg") no-repeat;
    background-size: 20px;
  }

  &.fa-enabled {
    background: url("../images/superuser/enabled.svg") no-repeat;
    background-size: 20px;
  }

  &.pending {
    background: url("../images/rx/pendingLoader.png") no-repeat;
    animation: rotating 3s linear infinite;
    // @media (max-width: $width-laptop-hidpi) {
    //   background-size: 16px;
    // }
  }
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 179px); // TODO - base on variables or find another solution to centralize

  .content {
    width: 168px;
    text-align: center;

    .image {
      margin-top: 1em;

      width: 168px;
      height: 168px;

      background: url("../images/no-data.png") no-repeat;
      background-size: 168px 168px;
    }
  }
}

.landing-image {
  max-width: 286px;
  height: auto;
}

.version-number {
  text-align: center;
  padding: 10px;
  color: #c3c3c3;
  border-right: 1px solid #ebebeb;
}

.illuminate-username {
  margin-top: 30px;
  font-size: 28px * $scale-koef;
  color: #2492cf;
}

.left-bar {
  flex: auto 0 0;
  width: 217px;
  transition: width $animation-swift-out;

  &.collapsed {
    width: 61px;
  }
}

.left-bar-diagnostic-info {
  width: 217px;
  position: fixed;
  bottom: 0px;
  padding: 5px;
  color: #ddd;
  font-size: 12px;
  //transition: width $animation-swift-out;
  background-color: $side-menu-background;
  padding-left: 20px;

  &.collapsed {
    width: 0px;
  }
}

.left-bar-diagnostic-info-tooltip {
  min-width: 175px;
}
