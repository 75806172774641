//$font-family-base: 'SF UI Text', "Helvetica Neue", "Helvetica";
$font-family-base: "gillsans", "Raleway", "Century Gothic", "Helvetica Neue",
"Helvetica";

$scale-koef: 0.875;

$font-size-tr: 18px;
$font-size-th: 18px;
$font-size-base: 15px;

$font-size-large: 1.125em;
$font-size-h2: 1.56em;
$font-size-small: 0.931em;
$font-size-smaller: 0.875em;
$font-size-h1: 2.56em;

$font-weight-semibold: 400;
$font-weight-bold: 500;

$font-color-base: #7f7f7f;
$font-color-light: #878787; /* @todo: maybe #909090 ? */
$font-color-lighter: #c3c3c3;
$font-color-dark: #555;
$font-color-placeholder: #b0b0b0;
$font-weight-base: 300;
$toast-background-color: #222;


$--main-theme-color: --main-theme-color;
$--secondary-theme-color: --secondary-theme-color;
$--left-nav-color: --left-nav-color;
$--left-nav-child-color: --left-nav-child-color;
$--left-nav-hover-color: --left-nav-hover-color;
$--left-nav-hover-offset: --left-nav-hover-offset;
$--left-nav-active-color: --left-nav-active-color;
$--left-nav-active-label-color: --left-nav-active-label-color;
$--left-nav-toggle-icon: --left-nav-toggle-icon;
$--left-nav-active-border-radius: --left-nav-active-border-radius;
$--controls-color: --controls-color;
$--right-arrow-icon: --right-arrow-icon;
$--arrow-down-icon: --arrow-down-icon;
$--download-icon: --download-icon;
$--sideNav-arrow-icon: --sideNav-arrow-icon;
$--bigRoundPlus-icon: --bigRoundPlus-icon;
$--select-icon: --select-icon;
$--plus-icon: --plus-icon;
$--svg-filter: --svg-filter;
$--opaque-main-color-7: --opaque-main-color-7;
$--opaque-main-color-44: --opaque-main-color-44;
$--opaque-main-color-55: --opaque-main-color-55;
$--opaque-main-color-66: --opaque-main-color-66;
$--opaque-main-color-30: --opaque-main-color-30;
$--slider-color: --slider-color;
$--table-hover-color-odd: --table-hover-color-odd;
$--table-hover-color-even: --table-hover-color-even;


$color-brand-new: #40519d;
$color-brand-new-slightly-lighter: #325ba7;

$color_td_name: var($--main-theme-color);

$color-brand: #0093d5;
$color-brand-light: #298ddc;
$color-brand-red: #e6819f;
$color-brand-gray: #F7F7F7;
$color-required-mark: #e71d5b;
$color-required: #DA547D;
$color-error: #DA547D;
$color-warning: #F9A353;
$color-required-background: rgba(218, 84, 125, 0.1);
$color-brand-grey: #929292;
$color-gradient-blue: linear-gradient(#3097e1, #2080c3);
$color-gradient-white: linear-gradient(white, #f8f8f8);
$color-gradient-red: linear-gradient(#fd3a6c, #e32a59);

$color-graph-temp-above: rgba(239, 159, 179, 0.23);

$color-background: #ecf0f3;
$color-background-shaded: #fbfbfb;
$color-background-input: #fdfdfe;
$color-background-inactive: #f2f3f6;
$color-background-light: #fff;
$color-background-dark: #dfdfdf;
$color-background-darker: #929292;
$color-background-darkest: #4f4f4f;
$color-background-highlight: #fdfdfe;
$color-hover-border-red: #e71d5b;
$color-background-input-new: #f7f7f7;

$color-border-base: #ebebeb;
$color-border: #929292;
$color-border-dark: #929292;
$color-border-darker: #bbbbbb;
$color-border-darkest: #8f8f8f;
$color-border-light: #ebebeb;
$color-border-table-head: #dbdbdb;
$color-border-input: #d4d4d4;
$color-background-light-hover: #f7f7f7;

/* plot colours */
$color-plot-complied: #92da7b;
$color-plot-complied-secondary: #4ec02b;
$color-plot-missed: $color-brand-red;
$color-plot-skipped: #ffc000;
$color-plot-pending: $color-brand-light;

$animation-duration: 0.65s;
$animation-swift-out: $animation-duration cubic-bezier(0.3, 1, 0.4, 1) 0s;
$margin-main: 32px;
$margin-mobile: 20px;

$side-menu-width-extended: 217px;
$side-menu-width-collapsed: 0px;
$side-menu-collapsed-offset: -156px;
$side-menu-hovered-offset: var($--left-nav-hover-offset);
$side-menu-item-label: var($--left-nav-active-color);
$--left-nav-opaque-icon-color: --left-nav-opaque-icon-color;
$side-menu-background: var($--left-nav-color);
$side-menu-child-background: var($--left-nav-child-color);
$side-menu-active-background: var($--left-nav-active-color);
$side-menu-background-hover: var($--left-nav-hover-color);
$side-menu-border: #ECECEC;

$shadow-settings: 1px 1px 2px rgba(0, 0, 0, 0.15);

$color-menu-active: var($--left-nav-active-label-color);
$color-table-th: $color-background-darkest;

$color-date-picker-label: #555555;
// $color-profile-name: #2492cf;
$color-profile-name: var($--main-theme-color);
$transparency: 0.45;
$transparency2: 0.57;
$transparencyPc: 55%;
$transparencyPc2: 43%;

//screen width
$iPad: 768px;
$phone: 480px;
$iPhone6: 375px;

$input-border: #d4d4d4;
$input-background: #fff;
$border-radius: 13px;
$modal-border-radius: 17px;

$color-good: #74B236;
$color-average: #F99002;
$color-poor: #E83C99;
$color-bad: #E83C99;

@mixin input-hover-animation($width: 10px) {
  &:hover {
    box-shadow: 0 0 $width 0 transparentize(#56c8ed, 0.4);
    animation: pulse 2s infinite;
    animation-direction: alternate;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 transparentize(#56c8ed, 0.8);
    }
    100% {
      box-shadow: 0 0 $width 0 transparentize(#56c8ed, 0.4);
    }
  }
}

@mixin input-focus-animation($width: 5px) {
  &:focus {
    background-color: #f7f7f7;
    // box-shadow: 0 0 $width 0 transparentize(#56c8ed, 0.4);
    // animation: pulse 2s infinite;
    animation-direction: alternate;

    &.dialog-form {
      box-shadow: none;
      // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 transparentize(#56c8ed, 0.8);
    }
    100% {
      box-shadow: 0 0 $width 0 transparentize(#56c8ed, 0.4);
    }
  }
}

@mixin top-arrow($width, $left: 50%) {
  &::after,
  &::before {
    bottom: 100%;
    left: $left;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    top: auto;
  }

  &::after {
    border-color: transparent;
    border-bottom-color: $color-background-shaded;
    border-width: $width;
    margin-left: -$width;
  }

  &::before {
    border-color: transparent;
    border-bottom-color: #dcdcdc;
    border-width: $width + 1;
    margin-left: -$width - 1;
  }
}

@mixin new-form() {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 0;
    background: #ffffff;
    padding: 0 25px;
    color: #b0b0b0;

    &::placeholder {
      color: #b0b0b0;
    }

    &::-webkit-input-placeholder {
      color: #b0b0b0;
    }

    &::-moz-placeholder {
      color: #b0b0b0;
    }

    &:-ms-input-placeholder {
      color: #b0b0b0;
    }

    &:-moz-placeholder {
      color: #b0b0b0;
    }

    &.errors {
      &::placeholder {
        color: #e71d5b;
      }

      &::-webkit-input-placeholder {
        color: #b0b0b0;
      }

      &::-moz-placeholder {
        color: #b0b0b0;
      }

      &:-ms-input-placeholder {
        color: #b0b0b0;
      }

      &:-moz-placeholder {
        color: #b0b0b0;
      }
    }

    @include input-focus-animation();

    margin-bottom: 20px * $scale-koef;
  }

  textarea {
    border: 0;
    resize: vertical;
    height: 150px;
    width: 100%;
    line-height: 25px;
    padding: 15px 25px;
  }


  select {
    cursor: pointer;
    width: 100%;
    background: white;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $color-border-base;
    height: 60px;

    padding: 0 15px;

    &.react-datepicker__month-select,
    &.react-datepicker__year-select,
    &.dialog-form {
      cursor: pointer;
      width: 100%;
      border: none;
      background: #f0f0f0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
      height: 24px;
      border-radius: 9px;
      padding: 0 14px;
      margin-top: 4px;
      color: #222;
      font-size: 15px;

      &:focus {
        background: #fff;
      }
    }

    &.dialog-form {
      height: 40px;
      margin-top: 0;
    }
  }

  .errors-block {
    color: #e71d5b;

    & > div {
      margin-top: -10px;
      margin-bottom: 40px;
      color: #e71d5b;
    }
  }

  .autocomplete {
    height: 50px;
    overflow-y: visible;
    margin-bottom: 45px;

    .select {
      @include input-hover-animation();
      border: 1px solid #d4d4d4;
      background: #ffffff;

      input {
        background: url("~images/small-arrow-bottom.svg") no-repeat right 18px center;
        background-size: 12px 8px;
        margin-bottom: 0;
        border: none;
        @include input-focus-animation-reset();
      }

      .items-wrapper {
        display: none;

        position: relative;
        z-index: 1;
        background: #ffffff;
        padding: 5px;

        border-bottom: 1px solid #d4d4d4;

        .items {
          padding: 0 20px;

          max-height: 320px;
          overflow-y: auto;

          .item {
            line-height: 45px;
            cursor: pointer;
          }
        }
      }

      &.opened {
        .line {
          margin: 0 5px;
          border-top: 1px #d4d4d4 dashed;
        }

        border-bottom: none;

        .items-wrapper {
          display: block;
        }
      }
    }
  }
}

@mixin input-focus-animation-reset() {
  &:focus {
    box-shadow: none;
    animation: none;
  }
}

@mixin left-arrow($width, $background, $color) {
  &::after,
  &::before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: transparent;
    border-right-color: $background;
    border-width: $width;
    margin-top: -$width;
  }

  &::before {
    border-color: transparent;
    border-right-color: $color;
    border-width: $width + 1;
    margin-top: -$width - 1;
  }
}

$width-laptop-high-res:1400px;
$width-laptop-hidpi: 1280px;
$width-laptop: 1280px;
$width-tablet: 768px;

$header-height: 50px;
$header-height-normal: 74px;
$header-height-large: 70px;


// brands
$--logo: --logo;
$--login-logo: --login-logo;
$--navBar-logo-left-margin: --navBar-logo-left-margin;