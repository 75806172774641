@import "variables";

.patient-page-content {
  // padding-top: 50px;
  .plot-row {
    div.column {
      min-width: 30%;
    }
  }

  .buttons-bar {
    display: -webkit-flex; /* Safari */
    -webkit-justify-content: space-between; /* Safari 6.1+ */
    display: flex;
    justify-content: space-between;
  }

  div.box {
    height: auto;
    min-height: 235px;
    margin-top: 0;
    padding: 20px;
  }

  .slider-picker-wrapper {
    margin-top: 0px;
  }
}

.alert-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  .alert {
    height: 26px;
    width: 26px;
    border-radius: 20px;
    &.on {
        background-color: #AB3439;
        // color: #fff;
    }
    &.off {
        background-color: #fff;
        border: 1px solid #bababa;
    }
  }

  .icon-and-text-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    .device-icon-container {
      width: 100%;
      height: 40px;
      border: 1px solid #bababa;
      background-color: #fff;
      border-radius: 10px;
      &.alerted {
        border-color: $color-error;
        background-color: #F7EAEE;
      }
      &.missed {
        border-color: $color-warning;
        background-color: #FCFAF7;
      }
      .device-icon {
        min-width: 40px;
        width: 100%;
        height: 40px;

        background-color: #4F4F4F;
        mask-composite: exclude;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-position: center;
        mask-position: center;

        &.alerted {
          background-color: $color-error;
        }
        &.missed {
          background-color: $color-warning;
        }
        &.none {
          background-color: unset;
        }
      }
    }
    .measurement {
      font-size: 15px;
      &.alerted {
        color: $color-error;
      }
      &.missed {
        color: $color-warning;
      }
      &.grey {
        color: #bababa;
      }
    }
  }
}

.device-icon,
.widget-icon {
  &.temperature {
    -webkit-mask-image: url("../images/rx/vitals/Icon_Temperature.svg");
    mask-image: url("../images/rx/vitals/Icon_Temperature.svg");
  }
  &.blood_pressure {
    -webkit-mask-image: url("../images/rx/vitals/Icon_BloodPressure.svg");
    mask-image: url("../images/rx/vitals/Icon_BloodPressure.svg");
  }
  &.weight {
    -webkit-mask-image: url("../images/rx/vitals/Icon_Weight.svg");
    mask-image: url("../images/rx/vitals/Icon_Weight.svg");
  }
  &.pulseoximetry {
    -webkit-mask-image: url("../images/rx/vitals/Icon_PulseOximetry.svg");
    mask-image: url("../images/rx/vitals/Icon_PulseOximetry.svg");
  }
  &.glucose {
    -webkit-mask-image: url("../images/rx/vitals/Icon_Glucose.svg");
    mask-image: url("../images/rx/vitals/Icon_Glucose.svg");
  }
  &.spirometry {
    -webkit-mask-image: url("../images/rx/vitals/Icon_Spirometry.svg");
    mask-image: url("../images/rx/vitals/Icon_Spirometry.svg");
  }
  &.steps {
    -webkit-mask-image: url("../images/rx/vitals/Icon_Steps.svg");
    mask-image: url("../images/rx/vitals/Icon_Steps.svg");
  }
  &.burned_calories {
    -webkit-mask-image: url("../images/rx/vitals/Icon_BurnedCalories.svg");
    mask-image: url("../images/rx/vitals/Icon_BurnedCalories.svg");
  }
  &.exercise_time {
    -webkit-mask-image: url("../images/rx/vitals/Icon_ExerciseTime.svg");
    mask-image: url("../images/rx/vitals/Icon_ExerciseTime.svg");
  }
  &.sleep {
    -webkit-mask-image: url("../images/rx/vitals/Icon_Sleep.svg");
    mask-image: url("../images/rx/vitals/Icon_Sleep.svg");
  }
  &.heart_rate {
    -webkit-mask-image: url("../images/rx/vitals/Icon_HeartRate.svg");
    mask-image: url("../images/rx/vitals/Icon_HeartRate.svg");
  }
  &.schedule {
    -webkit-mask-image: url("../images/rx/vitals/Icon_Pill.svg");
    mask-image: url("../images/rx/vitals/Icon_Pill.svg");
  }
  &.air_quality {
    -webkit-mask-image: url("../images/rx/vitals/Icon_AirQuality.svg");
    mask-image: url("../images/rx/vitals/Icon_AirQuality.svg");
  }
  &.inhaler {
    -webkit-mask-image: url("../images/rx/vitals/Icon_Inhaler.svg");
    mask-image: url("../images/rx/vitals/Icon_Inhaler.svg");
  }
  &.coagulation {
    -webkit-mask-image: url("../images/rx/vitals/Icon_Coagulation.svg");
    mask-image: url("../images/rx/vitals/Icon_Coagulation.svg");
  }
  &.billable_time {
    -webkit-mask-image: url("../images/rx/vitals/Icon_Time.svg");
    mask-image: url("../images/rx/vitals/Icon_Time.svg");
    -webkit-mask-size: 60%;
    mask-size: 60%;
  }
}

.billable-time-dot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-right: 13px;
  &.red {
    background-color: $color-error;
  }
  &.green {
    background-color: $color-good;
  }
  &.grey {
    background-color: #bababa;
  }
}