@mixin medicationsIcons($width, $height, $columns...) {
  @each $c in $columns {
    &.#{nth($c, 1)} {
      background-position-y: #{nth($c, 2)*$height};
    }
  }
}

.patient-portal {
  .status-icon {
    background-image: url('../images/patient/medication-statuses.png');
    background-size: 26px 156px;
    width: 26px;
    height: 26px;

    @include medicationsIcons(26px, 26px,
            'complied' 6,
            'missed' 5,
            'pending' 4,
            'scheduled' 3
    );
  }
}
