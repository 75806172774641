@import '../../style/variables';

.edit-rpm-schedule,
.enroll-patient,
.start-enrollmend-and-schedules,
.reset-enrollment-and-unattach-devices {
  .reminders-row {
    .row {
      justify-content: flex-end;
      flex-direction: row-reverse;
      column-gap: 15px;
      label {
        font-size: 18px * $scale-koef;
      }
    }
    &.horizontal-flex {
      align-items: center;
    }
    &.horizontal-flex > * {
      flex: unset;
    }
    input[type=checkbox] {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
    label {
      color: #000;
    }

    &.reminder-missed_doses {
      :nth-child(2) {
        display: block;
        input[type=number] {
          max-width: 106px;
        }
        .required-asterisk::after {
          display: none;
        }
      }
    }
  }

  .reminder-text {
    position: relative;
    .help {
      background-color: #D8D8D8;
      color: #000;
      border-radius: 100px;
      text-align: center;
      width: 31px;
      height: 31px;
      font-size: 15px;
      line-height: 31px;
      position: absolute;
      bottom: 12px * $scale-koef;
      right: 12px * $scale-koef;
    }
    .__react_component_tooltip {
      span {
        color: #D8D8D8;
      }
    }
    .reset-reminder-text {
      height: 31px;
      line-height: 31px;
      position: absolute;
      bottom: 12px * $scale-koef;
      right: 50px;
    }
    .required-asterisk::after {
      display: none;
    }
  }

  .hr:last-child {
    display: none;
  }
}

