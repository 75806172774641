svg.plot.adherence-7days {
  g.axis.bottom {
    .tick {
      text.Su, text.S {
        fill: $color-brand-red;
      }

      &:last-of-type {
        text {
          fill: $color-brand-light
        }
      }
    }
  }

  g.go-grey > g.series > rect:not(.active) {
    fill: transparentize($color-border-dark, $transparency);
    //stroke: $color-border-dark;
  }
}

.empty {
  fill: $color-background-inactive;
  //fill-opacity: 0.5;
}
