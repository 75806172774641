@import "../../style/_variables";
$new-scale-koef: 0.75;
$scale-koef: 0.75;
// $scale-koef: 0.875;
$font-color: #4f4f4f;
$font-secondary-color: #272727;
$active-color: #b1d590;
$content-padding: 60px;

.add-schedule-to-the-cap {
  letter-spacing: 1.5px;
  #set_timezone {
    max-width: 100%;
    letter-spacing: 1.5px;
    padding-left: 10px;
    &:disabled {
      cursor: not-allowed;
    }
  }

  .select-timezone {
    background: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    height: 40px;
    width: 68%;
  }

  &.wizardWrapper {
    flex: 535px 0 1;
    box-shadow: 1px 5px 12px 1px rgba(0, 0, 0, 0.06);
    border: 1px solid #ebebeb;
    background-color: white;
    min-width: 320px * $scale-koef;
    max-width: 520px * $scale-koef;
    height: auto;
    position: relative;

    &.isEsi {
      border-radius: 20px;
      box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.13);
      border-radius: 20px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .wizardHeader {
    position: relative;
    color: $color-background-darkest;
    font-size: 27px * $scale-koef;
    letter-spacing: 0px;
    text-align: center;
    padding: 43px * $scale-koef 0;
    font-weight: 600;
  }

  .wizardContent {
    position: relative;
    background-color: #fbfbfb;
    padding: 45px 0;
    height: 665px * $scale-koef;
    border-top: solid 1px #d9d9d9;
  }

  .wizardContentEsi {
    position: relative;
    background-color: transparent;
    padding: $content-padding * $scale-koef;
    height: 350px * $scale-koef;
  }

  .wizardForm {
    position: relative;
    height: 100%;

    :global {
      .PhoneInput {
        margin-bottom: 20px * $scale-koef;

        /* error div */
        & + * {
          margin-top: -14px;
        }
      }
    }

    // div {
    //   color: #4e4e4e;
    //   font-size: 18px;
    // }
  }

  .stepProgress {
    height: 8px;
    width: 80%;
    background-color: #ebebeb;
    /* top: -45px; */
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 2px;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }

  .buttonsBar {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    bottom: 0;

    .button {
      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }

      &:first-child:last-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .prevButton {
    position: absolute;
    width: 36px * $scale-koef;
    height: 36px * $scale-koef;
    background-color: transparent;
    background-image: url("../../images/rx/whiteGreyArrow.svg");
    background-repeat: no-repeat;
    background-size: 36px * $scale-koef 36px * $scale-koef;
    background-position: right center;
    transform: rotate(90deg);
    left: 60px * $scale-koef;
    top: 35px;
    cursor: pointer;

    &:hover {
      background-image: url("../../images/rx/darkGreyArrow.svg");
    }
  }

  .button {
    margin: 0;
  }

  &.wizardAdaptiveLayout {
    .wizardContent {
      height: 540px;
      min-height: 400px;
      position: relative;

      .schedule-add {
        .dark-text {
          color: #000;
        }

        .flex-container {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0px;
          color: #000000;

          .flex-left {
            width: 50%;
          }

          .flex-right {
            width: 50%;
            word-break: break-word;
          }
        }

        .buttons {
          position: absolute;
          bottom: 51px;
        }

        input {
          box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.22);
          width: 100%;
          &#medication_name {
            &:disabled {
              cursor: not-allowed;
              background: #ddd;
            }
          }
        }

        &.content-block {
          display: flex;
          flex-direction: column;
          height: 55vh;
          align-items: stretch;
          padding: 0 45px;
          overflow-y: auto;
          overflow-x: hidden;
          margin-bottom: 54px;

          .divider {
            margin: 0 10px;
          }

          .buttons {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            margin-top: auto;
            width: 77%;
            button {
              flex-grow: 1;
            }
          }

          select {
            font-family: GillSans;
            font-size: 22px * $scale-koef;
            text-align: center;
            background: transparent;
            color: $font-color;
            border: 0;
            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari and Chrome */
            appearance: none;

            background-position: left;
            padding-left: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background-size: 50px;
            background: url("../../images/rx/locked.svg") no-repeat;

            &.timezone {
              background: url("../../images/rx/medicationSelect.svg") no-repeat;
              background-size: 33px;
            }
            &.left {
              background-position: left;
              padding-left: 30px;
              padding: 8px 12px 13px 55px;
            }

            &.right {
              background-position: right;
              padding-left: 30px;
              padding: 8px 55px 13px 12px;
            }
          }

          .row {
            .auto-spinner-conatiner {
              position: relative;
              .auto-spinner {
                position: absolute;
                right: 0;
                top: 26px;
                right: -11px;
              }
            }

            &.title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 15px;

              span {
                color: #4f4f4f;
              }

              .switch-text {
                color: #4f4f4f;
              }

              input {
                max-width: 60px;
                background: #f7f7f7;
                text-indent: 0px;
                letter-spacing: 2px;
                -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
                -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
                box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
              }

              input[type="checkbox"] {
                max-width: 50px;
              }

              #frequency {
                background: transparent;
                border: 0;
                box-shadow: none;
                background: url("../../images/rx/locked.svg") no-repeat;
                background-position: right;
                background-size: 37px;
                max-width: 150px;
                color: #2492cf;
                margin-bottom: 0;
                text-transform: capitalize;
                cursor: not-allowed;
              }

              .img-input-container {
                cursor: pointer;
                border-radius: 10px;
                border-radius: 12px;
                position: relative;
                .step {
                  display: none;
                  position: absolute;
                  width: 10px;
                  height: 6px;
                  margin-left: 14px;
                  margin-top: 5px;
                  opacity: 0.8;

                  &:hover {
                    opacity: 1;
                  }

                  &:focus {
                    opacity: 1;
                  }

                  &.up {
                    transform: rotate(180deg);
                  }

                  &.down {
                    margin-top: -10px;
                    width: 11px;
                  }
                }

                &:hover {
                  background-color: #14568d;
                  color: white;
                  .step {
                    background-image: url("../../images/rx/stepDown.svg");
                    display: block;
                  }

                  input {
                    color: white;
                  }
                }
              }

              #timesPerDay {
                // background-image:url('../../images/rx/locked-padlock.svg');
                background-repeat: no-repeat;
                padding-left: 20px;
                background-position: right;
                background-position-x: 66px;
                background-size: 12px;
                margin-bottom: 0;
                color: #979797;
                // cursor: not-allowed;
              }

              .timePicker {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .timeStep {
                  max-width: 40px;
                  padding: 0px;
                  text-align: center;
                  background: transparent;
                  border: 0;
                  box-shadow: none;
                  margin: 0;
                  height: 46px;
                  cursor: pointer;
                  caret-color: transparent;

                  &:hover {
                    background-color: #14568d;
                    color: white;
                    caret-color: transparent;
                    cursor: pointer;
                  }
                }
              }

              .ampmSwitch {
                display: flex;
                padding: 2px;
                border-radius: 13px;
                -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
                -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
                box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1) inset;
                border: solid 1px #d9d9d9;
                background-color: #f7f7f7;
                width: 80px;
                height: 40px;
                margin: 0 0 0 9px;
                cursor: pointer;

                .block {
                  width: 39px;
                  height: 38px;
                  padding: 10px 8px 11px 10px;
                  object-fit: contain;
                  border-radius: 11px;
                  margin: -2px -1px;
                }

                .active {
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
                  background-color: #14568d;
                  color: white;
                }
              }
            }
          }
        }
      }
    }

    .buttonsBar {
      position: static;
      margin-top: 30px;
    }
  }
}
