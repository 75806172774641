$width: 64px;
$height: 32px;
$pillSize: $height - 2px;

.checkboxes-and-radios {
  input {
    display: none !important;
    position: absolute !important;
    visibility: hidden !important;
  }

  label {
    cursor: pointer;
    position: relative;
    display: block;
    width: $width;
    height: $height;
    border-radius: $height;
    background: #cdcdcd;
    transition: all 200ms ease-out;

    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      transition: all 200ms ease-out;
      background: white;
      left: 1px;
      top: 1px;
      width: $pillSize;
      height: $pillSize;
      border-radius: $pillSize;
    }

    &:after {
      opacity: 0;
      background: url("../images/rx/tick.png") center center no-repeat white;
    }
  }

  input:checked + label {
    background: #92DA7B;

    &:before, &:after {
      left: $width - $pillSize - 1px;
    }

    &:after {
      opacity: 1;
    }
  }
}
