.patient-portal {
  .patient-settings {
    &.content-block {
      div.box {
        height: auto;
        margin: 0;

        .caption {
          line-height: 54px;
          padding: 0;
        }
      }

      .caregivers-table {
        position: relative;
      }
    }
  }
}
