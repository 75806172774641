@import "variables";

.ml-20 {
  margin-left: 20px;
}

.my-studies-page {
  .plot-row {
    margin: 0;
    div.column {
      margin: 0;

      > div.box {
        margin: 0;
        height: auto;
      }
    }
  }

  .loader-wrapper.inline {
    height: 70px;
  }

  table {
    .column {
      &.study-name {
        color: $color-brand;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 1450px) {
          max-width: 250px;
        }
        @media (max-width: 1050px) {
          max-width: 200px;
        }
      }
    }
  }


  table .table-header .column.study-name {
    text-decoration: none;
  }

  .slider-picker-wrapper {
    margin-bottom: 75px;
    margin-top: 5px;
  }
}
