@import "../../style/_variables";
$new-scale-koef: 0.75;
$scale-koef: 0.75;
// $scale-koef: 0.875;
$font-color: #4f4f4f;
$font-secondary-color: #272727;
$active-color: #b1d590;

.modal-content form {

  .schedule{

    &.content-block {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      row-gap: 10px;
      padding: 0;

      .divider {
        margin: 0 10px;
      }
  
      .buttons {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        margin-top: auto;    
        button {
          flex-grow: 1;
        }
      }
    
        select {
          font-family: GillSans;
          font-size: 22px * $scale-koef;
          text-align: center;
          background: transparent;
          color: $font-color;
          border: 0;
          -moz-appearance: none; /* Firefox */
          -webkit-appearance: none; /* Safari and Chrome */
          appearance: none;
          
          background-position: left;
          padding-left: 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          background-size: 50px;
          background: url("../../images/rx/locked.svg") no-repeat;
            &.left {
              background-position: left;
              padding-left: 30px;
              padding: 8px 12px 13px 55px;
            }
        
            &.right {
               
              background-position: right;
              padding-left: 30px;
              padding: 8px 55px 13px 12px;
            }
          }
    
      .row {
        &.title {
          display: flex;
          justify-content: space-between;
          align-items: center;

        input {
          max-width: 92px;
        }
    
        #frequency {
          background: transparent;
          border: 0;
          box-shadow: none;
          background: url("../../images/rx/locked.svg") no-repeat;
          background-position: right;
          background-size: 37px;
          max-width: 150px;
          color: #2492cf;
          margin-bottom: 0;
          text-transform: capitalize;
          cursor: not-allowed;
        }
    
        .img-input-container {
          cursor: pointer;
          border-radius: 10px;
          border-radius: 12px;
          position: relative;
          .step {
            display: none;
            position: absolute;
            width: 10px;
            height: 6px;
            margin-left: 14px;
            margin-top: 5px;
            opacity: .8;
    
            &:hover {
              opacity: 1;
            }

            &:focus{
              opacity: 1;
            }
    
            &.up {
              transform: rotate(180deg);
            }
    
            &.down {
              margin-top: -10px;
              width: 11px;
            }
          }
    
          &:hover {
            // background-image: linear-gradient(to bottom, #3097e1, #2080c3);
            background: var($--main-theme-color);
            color: white;
            .step {
              background-image:url('../../images/rx/stepDown.svg');
              display: block;
            }
    
            input {
              color:white;
            }
          }
        }
    
        
    
        #timesPerDay {
          background-image:url('../../images/rx/locked-padlock.svg');
          background-repeat: no-repeat;
          padding-left: 20px;
          background-position: right;
          background-position-x: 66px;
          background-size: 12px;
          margin-bottom: 0;
          color: #979797;
          cursor: not-allowed;
        }
    
        .timePicker {
          display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    
          .timeStep {
            max-width: 40px;
            padding: 0px;
            text-align: center;
            background: transparent;
            border: 0;
            box-shadow: none;
            margin: 0;
            height: 46px;
            cursor: pointer;
            caret-color: transparent;
    
            &:hover {
              // background-image: linear-gradient(to bottom, #3097e1, #2080c3);
              background: var($--main-theme-color);
              color: white;
              caret-color: transparent;
              cursor: pointer;
            }
          }
        }
    
        .ampmSwitch {
          display: flex;
          padding: 2px;
          border-radius: 13px;
          -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1) inset;
          -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1) inset;
          box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1) inset;
          border: solid 1px #d9d9d9;
          background-color: #f7f7f7;
          width: 80px;
          height: 40px;
          margin: 0 0 0 9px;
          cursor: pointer;
    
          .block {
            width: 39px;
            height: 38px;
            padding: 10px 8px 11px 10px;
            object-fit: contain;
            border-radius: 11px;
            margin: -2px -1px;
          }
    
    
          .active {
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
            background-color: #ffffff;
          }
        }
        }
    
      }
    }
          
  } 
}