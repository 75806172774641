.with-loader {
  position: relative;

  .loader {
    position: absolute;
    top: 280px;
    left: 50%;
    margin-left: -50px;
    margin-top: 0;
    z-index: 10;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 1;
  }

  .for-app-content {
    margin: 0 -50px;
  }

  .spinner-container {
    .spinner {
      // position: absolute;
      // top: 200px;
      // left: 48%;
      // width: 24px;
      // height: 24px;
      // background-size: 24px 24px;
    }
  }
}

.loading {
  opacity: 0;
  transition: opacity 0.4s;
}

.loaded {
  opacity: 1;
  transition: opacity 0.4s;
}
