.drs-outer {
    display: flex;
    flex-direction: column;
}

.drs-date-section {
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: large;
}

.drs-controls {
    display: flex;
    flex-direction: row;
    border-color: blue;
    border-width: 2px;
    border-style: solid;
}

.drs-controls-date-inputs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-style: solid;
    border-color: chartreuse;
    border-width: 2px;
    height: 46px;
}

.drs-controls-date-button {
    display: flex;
    justify-content: flex-end;
    border-width: 2px;
    border-color: cyan;
    border-style: solid;
}

.drs-calendar-button {
    cursor: pointer;
    width: 46px;
    height: 46px;

    background-position: center;
    background: url('../images/calendar.png') no-repeat;
    background-size: 100%;
    z-index: 9;
}

.drs-calendar-button-space {
    width: 46px;
    height: 46px;
}
.drs-datepicker {
    font-size: 98%;
}

.drs-datepicker.react-datepicker__input_container.input {
    width: 100%;
}

.drs-input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    &.center {
        justify-content: center;
        column-gap: 20px;
        .row {
          flex: 1;
        }
    }
}

.date-range-selection-for-events-reports-modal,
.data-range-selection-modalion,
.recover-password {
  .empty-space-for-calendar {
    height: 155px;
  }
    .round-button,
    .bar-item .remove-icon {
        display: none;
    }
    .bar-item {
        padding: 5px 15px;
        pointer-events: none;
        height: auto;
        white-space: nowrap;
        flex: 1;
        display: flex;
        justify-content: start;
        &:hover {
            background-color: unset;
            border: none;
        }
    }
  .checkbox-container .row:not(:last-child){
    margin-bottom: 15px;
  }
  .checkbox-container.disabled {
    filter: grayscale(1);
    opacity: 0.8;
  }
  .horizontal-rule {
    color: #D9D9D9;
    background-color: #D9D9D9;
    height: 1px;
    width: 100%;
    border-color : #D9D9D9;
  }
  .grid-with-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
  .apply-filters-checkbox {
    flex-direction: row-reverse;
    justify-content: start;
    column-gap: 15px;
    label {
        color: #000;
    }
  }
  .filters-grid {
    display: flex;
    grid-gap: 10px;
    flex-wrap: wrap;
  }
  .react-datepicker-wrapper {
    width: 100%;
    display: block;
    div {
      width: 100%;
      display: block;
    }
    input {
      width: 100%;
    }
  }
  .content-block {
    min-height: 490px;
  }
  .start-date {
    position: relative;
  }
  .signal-alert {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #F9A353;
    -webkit-mask-position: center;
    mask-position: center;
  }
}
