@import '../../style/variables';

$content-padding: 40px;
$wizard-content-height: 665px * $scale-koef;
$content-block-padding: 30px * $scale-koef;

.wizard {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 5px 12px 1px rgba(0, 0, 0, 0.06);
  border: 1px solid #ebebeb;
  border-radius: $modal-border-radius;
  overflow: hidden;
  background-color: #fff;
  min-width: 600px * $scale-koef;
  max-width: 620px * $scale-koef;
  height: auto;

  .wizardHeader {
    position: relative;
    color: #000;
    font-size: 27px * $scale-koef;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    padding: 43px * $scale-koef;
    padding-bottom: 17px * $scale-koef;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .header-content {
      height: 15px * 2.5;
    }
    .addMenu-menuItem {
      font-weight: 400;
    }
  }
  
  .wizardContent {
    position: relative;
    background-color: #f7f7f7;
    padding: $content-padding * $scale-koef;
    padding-top: 0px;
    height: $wizard-content-height;
    background-color: #FBFBFB;
    border-top: solid 1px #d9d9d9;
    white-space: pre-line;
  }

  .back-button {
    min-width: unset;
    float: left;
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
  }

  .requiredFieldsError {
    color: $color-required;
    font-size: 18px * $scale-koef;
    line-height: 18px * $scale-koef;
    font-weight: 400;
  }

  .scroll-content {
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -15px;
    padding-right: 15px;
    margin-left: -15px;
    padding-left: 15px;
    flex: 1;
    max-height: 100%;
  }

  .scrollarea-fade-in-out {
    position: absolute;
    width: 100%;
    height: 15px;
    z-index: 1;
    content: "";
  }
  .simplebar-mask {
    &:before {
      @extend .scrollarea-fade-in-out;
      background: linear-gradient(to bottom, rgba(251,251,251,1) 0%, rgba(251,251,251,0) 15px);
      top: 0;
    }
    &:after {
      @extend .scrollarea-fade-in-out;
      background: linear-gradient(to top, rgba(251,251,251,1) 0%, rgba(251,251,251,0) 15px);
      bottom: 0;
    }
  }

  .buttons {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    width: 100%;
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
    .bottom-button-container {
      width: 50%;
      button {
        width: 100%;
      }
    }
  }
  .horizontal-flex {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    &.center {
      align-items: center;
    }
  }
  .horizontal-flex:not(.unequal-children) > * {
    flex: 1;
  }

  .vertical-flex {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .page-info-header {
    color: var($--main-theme-color);
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    letter-spacing: 0px;
  }

  a {
    color: var($--main-theme-color);
  }
}

.wizard, .floating-modal, .config-modal {
  button {
    margin: 0;
  }

  label {
    color: #4F4F4F;
  }

  textarea {
    width: 100%;
    background-color: #f7f7f7;
    box-shadow: inset 0 3px 5px 0 rgb(0 0 0 / 22%);
    padding: 12px;
    border-radius: 13px;
    border: solid 1px #bababa;
    resize: none;
    transition: none;
  }

  input:not([type="radio"]):not([type="checkbox"]):not(.react-time-picker__inputGroup__input):not(.react-date-picker__inputGroup__input):not(.react-select__input), textarea {
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #bababa;
    background-color: #F7F7F7;
    color: #000;
    &.invalid-control-background {
      background-color: $color-required-background;
    }
    &:disabled {
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }

  .react-autosuggest__input {
    margin-bottom: unset;
  }

  .input-error {
    position: absolute;
    right: 0;
    &.no-label {
      top: 0 - 21px * $scale-koef - 1px;
    }
  }

  .content-block {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: start;
    align-items: stretch;
    padding-top: $content-block-padding;
    padding-bottom: $content-block-padding;
  }
  
  .row {
    display: flex;
    flex-direction: column;
    position: relative;
    &.checkbox-first {
      flex-direction: row-reverse;
      column-gap: 10px;
      justify-content: start;
      align-items: center;
    }

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 1.4em;
      height: 1.4em;
      top: 1.2em;
      + .radio-button {
        box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
        border-radius: 100%;
        border: 1px solid #bababa;
        display: flex;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        cursor: pointer;
        align-items: center;
        justify-content: center;
      }
      &:checked {
        + .radio-button {
          &::after {
            content: "";
            width: 0.8em;
            height: 0.8em;
            border-radius: 100%;
            background-color: #74B236;
            display: block;
          }
        }
      }
      &:focus {
        + .radio-button {
          outline: none;
          border-color: $color-good;
        }
      }
      &:disabled {
        + .radio-button {
          filter: grayscale(1);
          opacity: 0.8;
          cursor: not-allowed;
        }
      }
    }
  }

  .required {
    .react-select-container {
      background-color: $color-required-background;
    }
    input, textarea {
      background-color: $color-required-background;
    }
  }
}

.wizard.horizontal {
  min-width: 1200px * $scale-koef;
  max-width: 1220px * $scale-koef;
  .content-block {
    flex: 1 1 auto;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .page-info-header {
    grid-column: 1 / -1;
  }
  .gridspan-3 {
    grid-row: span 3;
  }
  .gridspan-4 {
    grid-row: span 4;
  }
  .grid-full-width {
    grid-column: 1 / -1;
  }
  .double-width-right {
    grid-column-start: 3;
    grid-column-end: 5;
  }
  .double-width-left {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .multiselect-selected-options {
    row-gap: 10px;
    align-items: start;
    flex-direction: row;
    height: fit-content;
  }
  .checkbox-container {
    height: 46px* $scale-koef;
    align-self: end;
    &.adjust-height {
      height: calc(100% - 21px);
      display: flex;
      .row {
        width: 100%;
      }
    }
  }
  textarea {
    height: 100%;
  }

  .stage-title {
    font-size: 30px;
    color: #000;
    border-bottom: solid 3px #FABA00;
  }
  .stage-progress {
    background-color: #F7F7F7;
    color: #CDCDCD;
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
    padding-right: 50px;
    &.completed {
      background-color: #00C4BA;
      color: #fff;
      background-image: url("../../images/rx/icon_tick.svg") no-repeat center/70%;
    }
  }
  @property --progress {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 0%;
  }
  .progress-bar {
    background: linear-gradient(90deg, #00C4BA var(--progress), transparent 0);
    transition: .3s --progress;
    width: 100%;
    height: 3px;
  }

  .page-info-chip {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    padding: 1px;
    padding-right: 10px;
    border-radius: 16px;
    border: solid 1px #bababa;
    width: -moz-fit-content;
    width: fit-content;
  }
  .missing-fields-count {
    border-radius: 100%;
    height: 20px;
    width: 20px;
    line-height: 20px;
    color: white;
    text-align: center;
    background-color: #fff;
    &.not-valid {
      background-color: $color-error;
    }
    &.valid {
      background-color: var($--main-theme-color);
      -webkit-mask: url("../../images/rx/icon_tick.svg") no-repeat center/70%;
      mask: url("../../images/rx/icon_tick.svg") no-repeat center/70%;
    }
    &.valid-color {
      background-color: var($--main-theme-color);
      -webkit-mask: url("../../images/rx/icon_tick.svg") no-repeat center/70%, linear-gradient(#fff 0 0);
      mask: url("../../images/rx/icon_tick.svg") no-repeat center/70%, linear-gradient(#fff 0 0);
      mask-composite: exclude;
    }
  }
}