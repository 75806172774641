@import "../../../style/variables";

$old-scale-koef: $scale-koef;
$new-scale-koef: 0.90;

.infoHeader {
    &.spaceBeteween {
      justify-content: space-between;
    }

    .rowDirection {
        display: flex;
        flex-direction: row;
        column-gap: 15px;
        .refresh-button-placeholder {
            width: 42px;
            min-width: 42px;
        }
    }
}

.addMenu {
    position: relative;
    line-height: normal;
    .addMenu-button {
        display: inline-flex;
        align-items: center;
        min-width: unset;
        border: #BABABA 1px solid;
        box-shadow: none;
        text-align: left;
        margin: 0;
        padding-right: 40px;
        color: #4F4F4F;
        font-size: 18px *$new-scale-koef;
        font-weight: 600;
        line-height: 46px * $new-scale-koef;
        height: 46px * $new-scale-koef;
        background-image: var($--arrow-down-icon);
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 20px 20px;
        overflow: hidden;
        &:hover {
            box-shadow: unset;
        }
        &.expanded {
            &::before {
                transform: rotate(180deg);
            }
        }
        &.visibility-icon {
            height: 34px;
            width: 34px;
            min-width: 40px;
            border-radius: 7px;
            padding: 0;
            margin: 0;
            float: right;
            background: none;
            border: unset;
            display: block;
        }
    }

    .addMenu-menu {
        position: absolute;
        right: 0;
        z-index: 11;
        padding: 6px;
        border: #BABABA 1px solid;
        border-radius: 12px;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.13);
        background-color: #fff;
        &.visibility-icon {
            top: 40px;
        }

        .addMenu-menuItem {
            position: relative;
            z-index: 10;
            width: 100%;
            border-radius: 8px;
            padding: 10px 30px;
            font-size: 16px;
            line-height: 16px;
            text-align: right;
            white-space: nowrap;
            cursor: pointer;
            &.with-checkbox {
                display: flex;
                justify-content: start;
                flex-direction: row;
                column-gap: 10px;
                padding: 10px;
            }
            &:hover {
                background-color: var($--opaque-main-color-7);
            }
            &.disabled {
                cursor: not-allowed;
                filter: grayscale(100%);
                opacity: .5;
            }
        }
    }	
}

.tab-view {
    &.patient-tab-view {
        flex: 1 1 auto;
        min-height: 0;
        height: calc(100% - $header-height-normal);
    }
}

.patient-tab-page {
    display: flex;
}

.tab-content {
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
}
.panel-content {
    hr {
        margin: 20px 0 10px 0;
        &:last-child {
            display: none;
        }
    }

    max-height: 240px;
    overflow-y: auto;
}

.pd32 {
    padding: 0 32px;
}

.add-meds {
    min-height: 240px;
}

.meds-name {
    text-transform: uppercase;
}

.schedules-table tr td:first-child {
    color: var($--main-theme-color);
}

.patientHeader {
    display: flex;
    flex-direction: row;
    height: 60px;
    margin: 22px 0;
    justify-content: space-between;

    input {
    width: 472px * $scale-koef * $new-scale-koef;
    margin-right: 15px;

    margin-left: 15px;
    color: #4F4F4F;
    border: 0;
    background-color: #F7F7F7;
    -moz-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);

    &::placeholder {
        color: #000;
    }

    &.search {
        background-repeat: no-repeat;
        background-position: (400px * $new-scale-koef - 20px*$new-scale-koef - 10px) center;
        background-size: 20px*$new-scale-koef;
    }
    }
}

.plus-box {
    display: flex;
    width: 100%;
    height: calc(100% - #{$header-height} - 10px);
    justify-content: center;
    align-items: center;
    background-color: #FBFBFB;

    .plus-border {
        width: 124px * $scale-koef;
        height: 124px * $scale-koef;
        background-color: #FFFFFF;
        border: #DADADA 1px solid;
        border-radius: 100px;
    }

    .plus {
        width: 124px * $scale-koef;
        height: 124px * $scale-koef;
        background-color: #FFFFFF;
        border-radius: 100px;
        display: block;
        background-color: var($--main-theme-color);
        -webkit-mask: url("../../../images/rx/thin_plus.svg") no-repeat center/30%;
        mask: url("../../../images/rx/thin_plus.svg") no-repeat center/30%;
        cursor: pointer;
    }
    &:hover {
        .plus-border {
            box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.25);
        }
    }
}

.patient-page {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.patient-page-innerdiv {
    flex: 0 1 auto;
}

.infoSection {
    .flexbox {
        display: flex;
        flex-direction: column;

        .content {
            display: flex;
            flex-direction: row;
        }
        .content-flexbox {
            &.header {
                font-size: 20px * $scale-koef;
                font-weight: 600;
                color: #000;
            }

            &.sub-title {
                margin-top: 6px;
                span {
                    color: var($--main-theme-color);
                    font-size: 14px;
                    margin-right: 33px;
                }
            }
        }
    }
}

.panels {
    margin-top: 20px * $scale-koef;

    .row {
        display: flex;
        flex-wrap: wrap;

        & > * {
            margin-right: 20px * $scale-koef;
            width: 31%;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.panel {
    border-radius: 20px;
    border: solid 1px #d9d9d9;
    overflow: hidden;
    background-color: #fbfbfb;
    margin-bottom: 22px;

    .trash-icon {
        cursor: pointer;
        background-size: 15px;
    }

    &.add-meds {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title {
        font-size: 20px * $scale-koef;
        font-weight: 600;
        display: flex;
        align-items: center;
        color: #000000;
        background: #fff;
        padding: 25px 26px 23px 30px;
        border-radius: 20px;
        border: 0;
        border-bottom: 1px solid #ddd;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .button_container {
            margin-left: auto;

            button {
                font-size: 14px * $scale-koef;
                font-weight: 600;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;

                width: 156px * $scale-koef;
                height: 46px * $scale-koef;
                margin: 0;
                padding: 0;

                color: #ffffff;
                border-radius: 13px * $scale-koef;
                background-color: #14568d;
                min-width: auto;
                border-radius: 18px;
            }
        }
    }

    .panel-content {
        padding: 6px 26px;
        margin: 18px 0;
    }

    .rows {
        .row {
            display: flex;
            align-items: center;
            height: 59px * $scale-koef;
            margin-bottom: 9px * $scale-koef;
            padding-top: 9px * $scale-koef;
            padding-bottom: 9px * $scale-koef;
            border-bottom: 1px dashed #d9d9d9;
            flex-wrap: wrap;

            &:last-child {
                margin-bottom: 0;
            }

            .text {
                font-size: 20px * $scale-koef;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #4e4e4e;

                &.big {
                    font-size: 24px * $scale-koef;
                }
            }

            .button_container {
                display: flex;
                flex-grow: 0;
                margin-left: auto;

                .button {
                    display: block;
                    width: 44px * $scale-koef;
                    height: 44px * $scale-koef;
                    cursor: pointer;
                    background: url("../../../images/arrowRight.svg") center center no-repeat;
                    background-size: 100% 100%;
                    min-width: auto;

                    &.ibutton {
                        border-radius: 18px;
                    }
                }
            }

            .label {
                display: flex;
                flex-grow: 0;
                margin-left: auto;

                .title {
                    font-size: 20px * $scale-koef;
                    font-weight: normal;
                    margin: 0 12px * $scale-koef 0 0;
                    white-space: nowrap;
                    color: #4e4e4e;
                }

                .content {
                    font-size: 24px * $scale-koef;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 134px * $scale-koef;
                    height: 40px * $scale-koef;
                    color: #14568d;
                    border: solid 1px #d9d9d9;
                    border-radius: 10px * $scale-koef;
                    background-color: #ffffff;
                }

                &.good {
                    .content {
                        border: solid 2px $color-good;
                    }
                }

                &.average {
                    .content {
                        border: solid 2px $color-average;
                    }
                }

                &.poor {
                    .content {
                        position: relative;
                        border: solid 2px $color-poor;

                        &:after {
                            position: absolute;
                            top: -12px * $scale-koef;
                            right: -12px * $scale-koef;
                            display: block;
                            width: 24px * $scale-koef;
                            height: 24px * $scale-koef;
                            content: "";
                            background: url("../../../images/iconWarning.svg") center center no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
            }
        }
    }
}

.editImg {
    width: 17px;
    height: 16px;
    background: url("../../../images/rx/edit.svg") no-repeat;
    background-size: 10px 10px;
    margin: auto;
    background-position: 3px 6px;
    display: inline-block;
    cursor: pointer;
}

.patient-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px * $new-scale-koef;
    &.topPadding {
        padding-top: 30px;
    }
    .details-container {
        padding-top: 30px;
    }
    .rowContent {
        border: 0;

        &:first-child {
            padding: 0;
        }
    }

    label {
        color: var($--main-theme-color);
        font-size: 16px;
        @media all and (max-width: 1300px) {
            font-size: 14px;
        }
    }

    .widgetContainer {
        container: none;
    }

    .width70 {
        min-width: 70px;
    }

    .width100 {
        min-width: 100px;
    }

    .width110 {
        min-width: 110px;
    }

    .width120 {
        min-width: 120px;
    }

    .width130 {
        min-width: 130px;
    }

    .width140 {
        min-width: 140px;
    }

    .width150 {
        min-width: 150px;
    }

    .width160 {
        min-width: 160px;
    }

    .labelValue {
        color: #000;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-indent: 5px;

        @media all and (max-width: 1300px) {
            font-size: 14px;
        }
    }
}

$header-height: 60px;
$patient-details-col-width: 230px;
$medical-history-col-width: 230px;
$center-details-col-width: 250px;
$transplant-details-col-width: 250px;
$insurance-col-width: 200px;
$medication-details-col-width: 215px;
$medication-details-simplified-col-width: 140px;
$cohort-col-width: 200px;

.widget-scrollable {
    height: 100%;
    background: repeating-linear-gradient(
        to bottom,
        #f5f5f5 0px + $header-height,
        #f5f5f5 50px + $header-height,
        #fff 50px + $header-height,
        #fff 100px + $header-height
    );

    .addMenu {
        .addMenu-menu {
            position: fixed;
            right: unset;
        }
        .button-container {
            background-color: var($--main-theme-color);
            width: fit-content;
            border-radius: 13px;
        }
        .addMenu-button {
            opacity: 1;
            border: unset;
            padding-right: 40px;
            color: #fff;
            background-color: #fff;
            -webkit-mask: url("../../../images/plus.svg") no-repeat center/30%;
            mask: url("../../../images/plus.svg") no-repeat center/30%;
            background-image: unset;
        }
    }
    &.no-stripes {
        background: unset;
    }
    .table-and-stripes {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .stripes {
        flex: 0 0 auto;
        .border {
            height: 100%;
            border-right: 1px solid #d9d9d9;
            box-shadow: 0 0 3px 2px #d9d9d9;
            -moz-box-shadow: 0 0 3px 2px #d9d9d9;
            -webkit-box-shadow: 0 0 3px 2px #d9d9d9;
            -webkit-clip-path: inset(0px -15px 0px 0px);
            clip-path: inset(0px -15px 0px 0px);
            &.patient-details-col {
                width: $patient-details-col-width;
            }
            &.medical-history-col {
                width: $medical-history-col-width;
            }
            &.center-details-col {
                width: $center-details-col-width;
            }
            &.transplant-details-col {
                width: $transplant-details-col-width;
            }
            &.insurance-col {
                width: $insurance-col-width;
            }
            &.medication-details-col {
                width: $medication-details-col-width;
                &.simplified {
                    width: $medication-details-simplified-col-width;
                }
            }
            &.cohort-col {
                width: $cohort-col-width;
            }            
        }
    }
    &:not(.collapsed) .os-scrollbar-vertical {
        margin-top: 70px;
        margin-bottom: 12px;
    }

    table.patient-widget {
        flex: 0 0 auto;
        border: unset;
        display: table;
        table-layout: fixed;
        width: 100%;
        color: #4e4e4e;
        transform: translate(0, 0);

        .patient-details-col {
            width: $patient-details-col-width;
        }
        .medical-history-col {
            width: $medical-history-col-width;
        }
        .center-details-col {
            width: $center-details-col-width;
        }
        .transplant-details-col {
            width: $transplant-details-col-width;
        }
        .insurance-col {
            width: $insurance-col-width;
        }
        .medication-details-col {
            width: $medication-details-col-width;
            &.simplified {
                width: $medication-details-simplified-col-width;
            }
        }
        .cohort-col {
            width: $cohort-col-width;
        }
        & > thead {
            & > tr {
                height: $header-height;
              & > th {
                border-right: unset;
                padding-left: 17px;
                cursor: unset;
                text-align: left;
                padding: 7px 50px 7px 20px;
                a {
                    font-size: 22px;
                    color: #4f4f4f;
                    line-height: 45px;
                    flex: 1 1 auto;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
        
                &:first-child {
                  box-shadow: unset;
                }
        
                &:last-child {
                  border-right: medium none;
                  padding-right: 15px;
                }
              }
            }
          }        
        & > tbody {
          padding: 0;
          border: unset;
          & > tr {
            width: 100%;
            cursor: unset;
            & > td {
              max-width:100%;
              white-space:nowrap;
              padding: 9px 17px 9px 17px;
              &:first-child {
                color: var($--main-theme-color);
              }
              &:last-child {
                width: 100%;
              }
            }
            &:last-child {
              & > td {
                &:first-child {
                  border-bottom-left-radius: unset;
                }
      
                &:last-child {
                  border-bottom-right-radius: unset;
                }
              }
            }
          }
        }
        .row-with-buttons {
            display: flex;
            flex-direction: row;
            column-gap: 15px;
            justify-content: space-between;
            align-items: center;
            flex: 0 0 auto;
            .row-buttons {
                display: flex;
                flex-direction: row;
                column-gap: 10px;
                align-items: center;
                cursor: pointer;
                > .buttons-icon-container {
                    display: none;
                }
            }
        }
        .buttons-icon-container {
            width: 38px;
            height: 38px;
            min-width: 38px;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            background-color: #fff;
            &:hover:not(.disabled) {
              border: 1px solid transparent;
            }
          }

        .cap-and-delete {
            display: flex;
            flex-direction: row;
            column-gap: 15px;
            align-items: center;
        }
        .cap-and-delete .buttons-icon-container,
        td .buttons-icon-container {
            width: 30px;
            height: 30px;
            min-width: 30px;
            display: none;
        }
            
        .cap-and-delete .buttons-icon-container .icon,
        td .buttons-icon-container .icon {
            width: 30px;
            height: 30px;
            min-width: 30px;
        }
    }
    .icon-and-text-container {
        flex: 1;
        min-width: 0;
        .icon-and-text-content {
            flex: 1;
            min-width: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .table-and-stripes:hover > table.patient-widget > thead > tr > th .row-buttons > .buttons-icon-container,
    table.patient-widget > tbody > tr:hover > td .cap-and-delete > .buttons-icon-container,
    table.patient-widget > tbody > tr:hover > td .row-buttons > .buttons-icon-container {
        display: block;
    }
    .__react_component_tooltip.place-top:before {
        display: none;
    }
    .__react_component_tooltip {
        min-width: 150px;
    }
}

.list-table-border.with-wrapping {
    table.patient-widget > tbody > tr > td
    {
        white-space: pre-wrap;
    }
}

.patient-info-dashboard {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    .widgetSelectionContainer {
        top: -4px;
        right: 115px;
    }
    h2 {
        margin-top: 0;
    }
}

.hr20 {
    width: 1px;
    height: 20px;
    margin: 0 30.3px 0 31.7px;
    border: solid 1px #c5c5c5;
  }

.mini-adherence-dashboard {
    margin-bottom: 14px;
}
.mobile {
    .mini-adherence-dashboard {
        padding-right: $margin-mobile;
        padding-left: $margin-mobile;
    }
  }

.collapse-icon {
    width: 25px*$new-scale-koef;
    min-width: 25px*$new-scale-koef;
    height: 25px*$new-scale-koef;
    background-image: var($--arrow-down-icon);
    background-repeat: no-repeat;
    background-size: 25px*$new-scale-koef;
    &.collapsed {
        transform: rotate(270deg);
    }
}

.patient-medications-chart {
    min-height: 500px;
}

.raw-data-table{
    height: 100%;
    min-height: 0;
    display: flex;
    padding-top: 20px;
    > div {
        min-width: 0;
    }
}

table.import-data {
  th:not(:first-child), td:not(:first-child) {
    max-width: 30vw;
  }
}