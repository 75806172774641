@import "../../../style/variables";


.device-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 308px;
}
.tab-view  {
    .tab-content  {
        display: block;
    }
}

.tabButton {
    &[role="button"]{
        &:hover {
            box-shadow: none;
        }
    }
    &button{
        &:hover {
            box-shadow: none;  
        }
    }
}


.back {
    font-size: 15.75px;
    line-height: 41.4px;
    width: auto;
    min-width: auto;
    height: 41.4px;
    margin: 0;
    border-radius: 13px;
    border: solid 1px #bababa;
    background-color: #FFF;
    color: #000000;
    margin-right: 35px;
  }
  
  .editImg {
    width: 17px;
    height: 16px;
    background: url("../../../images/rx/edit.svg") no-repeat;
    background-size: 10px 10px;
    margin: auto;
    background-position: 3px 6px;
    display: inline-block;
    cursor: pointer;
  }
  
  .rowDirection {
    display: flex;
    flex-direction:row;
  }
  
  .newHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  
  .infoHeader {
    min-height: $header-height-normal;
    padding: 14.5px $margin-main 13.5px;
    margin: 0 (-$margin-main);
    border: solid 1px #ebebeb;
    background-color: #f7f7f7;
    border-right: 0;
    border-left: 0;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 10px;
    .page-header {
      padding: 0;
      min-height: unset;
    }
    &.white {
      background-color: #fff;
      margin-right: 0;
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      border: unset;
    }
  
    .feildBox {
      line-height: 45px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0px;
      color: #4f4f4f;
    }
  
    .feildValue {
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      font-weight: normal;
    }
    
    .pathHr {
      width: 1px;
      height: 44px;
      border: solid 1px #c5c5c5;
    }
  }

  .graph-container {
    height: 600px;
  }
  
  .mobile {
    .back {
      width: 41.4px;;
      min-width: 41.4px;;
      height: 41.4px;
      margin: 0;
      border-radius: 100%;
      border: solid 1px #bababa;
      background-color: #FFF;
      margin-right: 0;
      position: relative;
      text-indent: -1000em;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        background-color: var($--main-theme-color);
        -webkit-mask: url("../../../images/leftArrow.svg") no-repeat center/contain;
        mask: url("../../../images/leftArrow.svg") no-repeat center/contain;
        width: 41.4px;;
        min-width: 41.4px;;
        height: 41.4px;
      }
    }
    .pathHr {
      margin: 0;
    }
  }