@import "../../style/_variables";

$font-color-tooltip-descr: #4F4F4F;
$font-color-tooltip-title: #000;


.graphStandardContainer {
  width: 100%;
  height: 400px;
}

.graphDoctorDashboardContainer {
  width: 100%;
  height: calc(100% - 72px);
}

.capEventsContainer {
  width: 100%;
  max-height: 400px;
}

.graphDiv {
  height: 100%;
  position: relative;
  &.withPadding {
    padding: 10px;
  }
  &.withBorder {
    border-radius: 20px;
    border-color: #D9D9D9;
    border-style: solid;
    border-width: 1px;
  }

  .recharts-surface {
    border-radius: 0;
  }

  .with-shadow {
    position: relative;
    &:before {
      -webkit-box-shadow:  inset 0 6px 5px -5px #c1c1c1;
      box-shadow:  inset 0 6px 5px -5px #c1c1c1;
      position: absolute;
      left: 0;
      width: 100%;
      height: 6px;
      content: "";
      top: -2px;
    }
  }
  .vertical-shadow {
    position: absolute;
    z-index: 1;
    border-right: 1px solid #d9d9d9;
    -moz-box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.15);
    clip-path: inset(0px -15px 0px 0px);
  }
  .y-axis-extension {
    position: absolute;
    z-index: 1;
    border-right: 2px solid #d9d9d9; 
  }
}

.scrollableGraph {
  display: flex;
  flex-direction: row;
  column-gap: 0;
  justify-content: flex-start;
  .graphContent {
    width: calc(100% - 80px);
    height: 500px;
    overflow-y: hidden;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.customTooltip {
  background-color: $color-background-light;
  border-width: 1px;
  border-style: solid;
  border-color: $color-border;
  border-radius: 20px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  visibility: visible;
  min-width: 150px;
}
.customTooltipTitle {
  color: $font-color-tooltip-title;
  padding-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: $color-border-light;
}
.customTooltipDescrHeader {
  text-align: center;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  color: $font-color-tooltip-descr;
  &.left-align {
    text-align: left;
  }
}

.customTooltipDescr {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  color: $font-color-tooltip-descr;
  &.left-align {
    text-align: left;
  }
  span {
    color: $font-color-tooltip-descr;
  }
}

.graphAlternateRowBackground{
  color: $color-background-light-hover;
}
.graphRowBackground{
  color: $color-background-light;
}

.legendContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.left {
    justify-content: flex-start;
  }
  .label {
    padding-right: 5px;
  }
  .reminders-legend {
    display: flex;
    align-items: center;
  }
  .hr-vertical {
    color: #D9D9D9;
    background-color: #D9D9D9;
    width: 2px;
    height: 20px;
    border-color : #D9D9D9;
  }
  .legendItem {
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 10px;
    border-radius: 20px;
    background-color: #F0F0F0;
    border: 1px solid #F0F0F0;
    &.selected {
      background-color: #fff;
      border: 1px solid #D9D9D9;
    }
    .label {
      line-height: 16px;
      white-space: nowrap;
    }
  }
}

.graph-surface-background {
  background: linear-gradient(to right,#FBFCFE 37px, #fff 37px, #fff calc(100% - 37px), #FBFCFE calc(100% - 37px), #FBFCFE 100%);
}

.mobile {
  .legendContainer {
    height: 45px;
  }

}

.dot {
  width: 13px;
  height: 13px;
  min-width: 13px;
  border-radius: 50%;

  &.pr {
    background: #fc5edc;
  }
  &.dp {
    background: #2369e7;
  }
  &.map {
    background: #47d826;
  }
  &.sp {
    background: #27e4d1;
  }
  &.low {
    background: #DA547D;
  }
  &.medium {
    background: #F9A452;
  }
  &.high {
    background: #4EB067;
  }
  &.unknown {
    background: #0060AA;
  }
  &.awake {
    background: #FCAC5E;
  }
  &.rem {
    background: #FC5EDC;
  }
  &.lightSleep {
    background: #5EC4FC;
  }
  &.deepSleep {
    background: #645EFC;
  }
}

.label{
  color: #000;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  .greyLabel {
    color: #4F4F4F;
  }
  &.greyLabel {
    color: #4F4F4F;
  }
}

.gm-style-iw-d {
  overflow: hidden;
  width: 250px;
}

.recharts-tooltip-wrapper {
  z-index: 50;
}

.doughnutCenterText {
  font-size: 75px;
  fill: #000;
  font-weight: 400;

  &.top {
    transform: translateY(-10px);
  }
  &.bottom {
    transform: translateY(20px);
    font-size: $font-size-small;
  }
}

.refAreaNotVisible {
  visibility: hidden;
}
.refAreaVisibleOnHover {
  visibility: hidden;
  pointer-events: all;
  &:hover {
    visibility: visible;
  }
}

.gm-style {
  button {
    height: unset;
    min-width: unset;
  }
}

.page-header {
  .row {
    display: flex;
    align-items: center;
    column-gap: 10px;
    label {
      color: #000;
      font-size: 18px * $scale-koef;
    }
  }
}

$mobileGraphNavHeight: 88px;
$mobileGraphNavWithTogglesHeight: 146px;
.mobile {
  .graphDiv {
    .swiper.swiper-initialized.swiper-horizontal,
    .swiper.swiper-initialized.swiper-horizontal:not(.swiper-backface-hidden) {
      padding-left: $margin-mobile;
      padding-right: 0;
    }
    .swiper {
      margin-left: 0;
      margin-right: 0;
    }
    .swiper-slide {
      width: unset;
      display: flex;
      align-items: center;
    }
  }
}

.mobile-range-picker {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  .range-types-picker {
    width: calc(100% - 2*$margin-mobile);
    height: 36px;
    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    justify-content: space-between;
    align-items: center;
    margin: 0 $margin-mobile;
    padding: 0 1px;
    background-color: #E8EEF3;
    box-shadow: inset rgba(0, 0, 0, 0.22) 0px 3px 5px 0px;
    border-radius: 12px;
    .range-type {
      position: relative;
      text-align: center;
      height: 32px;
      line-height: 32px;
      border-radius: 12px;
      &.active {
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
      }
      // new design doesn't have those but it was hard to figure out the selector so I'm leaving this for now
      // &:not(:last-child):not(.next-active):not(.active)::after {
      //   content: "";
      //   position: absolute;
      //   right: 0;
      //   transform: translate(0, 50%);
      //   height: 50%;
      //   width: 1px;
      //   border-right: 1px solid #D9D9D9;
      // }
    }
  }
  .dates-and-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #D9D9D9;
    .dates-range {
      flex: 1;
      align-self: stretch;
      color: #4F4F4F;
      font-size: 15px;
      border-right: 1px solid #D9D9D9;
      border-left: 1px solid #D9D9D9;
      display: flex;
      align-items: center;
      justify-content: center;
      &.highlighted {
        background-color: var($--opaque-main-color-30);
        span {
          color: var($--main-theme-color);
        }
      }
    }
    button, [role="button"] {
      width: 76px;
      height: 44px;
      min-width: 50px;
      margin: 0;
      padding: 0;
      transform: translate(-2px, -1px);
      background-color: var($--main-theme-color);
      -webkit-mask: url("../../images/leftArrow.svg") no-repeat center/contain;
      mask: url("../../images/leftArrow.svg") no-repeat center/contain;
  
      &.disabled {
        background-color: #000;
        pointer-events: none;
      }
  
      &.next {
        transform: translate(-1px, -1px) rotate(180deg);
      }
    }
  }
}

.dashboard-table {
  th {
    span {
      font-size: 18px;
      color: #000000;
    }
  }
}

// fix for responsive container slow resize on render, as suggested here: https://github.com/recharts/recharts/issues/1767#issuecomment-598607012
.graph-responsive-container {
  position: absolute;
}
.graph-label {
  color: #000;
}

.y-axis-tick {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mobile {
  .recharts-responsive-container.brush {
    position: absolute;
    bottom: 25px;
    z-index: 1;
    line-height: 7px;
  }
}

.tooltip-widget {
  display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: $margin-mobile;
  .grid-readings-cell {
    height: 90px;
    background-color: #F4F8FC;
    .title {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}