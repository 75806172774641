
@import '../../style/variables';

.DropdownMenuContent {
  z-index: 100;
  padding: 6px;
  border: #BABABA 1px solid;
  border-radius: 12px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
}
.filtervisibility-container.relative {
  position: relative;
  float: right;
}

.DropdownMenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  padding: 5px;
  border-radius: 12px;
}

.DropdownMenuItem[data-highlighted] {
  background-color: var($--opaque-main-color-7);
}