@import "./variables";

div.center-layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  text-align: center;
  font-weight: 200;

  header {
    border: 1px solid $color-border-base;
    display: flex;
    flex-flow: row nowrap;
    justify-content:center;
    div.logo-image {
      height: 26px;
      width: 26px;
      align-self: center;
      background-image: url(../images/rx/rxCapicon.svg);
      background-size: cover;
    }
  }

  .title {
    height: 56px;
    line-height: 56px;
    margin-top: 31px;
    margin-bottom: 38px;
    border-bottom: 1px dashed $color-border-base;
    text-align: center;

    h1 {
      font-size: $font-size-base;
      font-weight: 200;
      color: black;
      margin: 0;
    }
  }

  div.center-content {
    margin-bottom: 125px;
    max-width: 458px;
    text-align: center;
    align-self: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    label, input, button {
      display: block;
    }

    input {
      width: 320px;

      &[role="button"] {
        width: auto;
      }
    }

    label {
      margin: 38px 0 18px;
    }

    .caption {
      color: $font-color-light;
      margin: 0 auto 30px;

      .tablet-image {
        margin-bottom: 40px;
      }

      span {
        display: inline-block;
      }
    }

    .buttons {
      padding-top: 101px;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      &.column {
        padding-top: 0;
        flex-flow: column nowrap;
        justify-content: center;
        width: 212px;
        button, [role=button] {
          width: 212px;
          & + button, & + [role=button] {
            margin-top: 20px;
          }

        }
      }

      &.row {
        margin: 0 -7px;
        button, [role=button] {
          flex: auto 1 1;
          margin: 0 7px;
        }
      }
    }

    span.img-placeholder {
      width: 270px;
      height: 210px;
      border-radius: 11px;
      background-color: #e8e7e3;
      border: 0;
      display: block;
      margin: 0 auto 40px;
      align-self: center;
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        border: 1px solid white;
        top: 22px;
        bottom: 22px;
        left: 22px;
        right: 22px;
        background-color: #485455;
        box-shadow: inset 0 0 20px 8px rgba(255, 255, 255, 0.1);
      }
    }
  }
}
