.switchable {
  label {
    width: 30%;
    display: inline-block;
    float: left;
    label {
      font-weight: 200;
      color: #555;
      margin-bottom: 0;
    }
  }
  .field {
    width: 70%;
    display: inline-block;
    margin-bottom: 30px;

    select.switchableSelect {
      width: 55% !important;
      float: left;
      margin-right: 15px !important;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    height: 40px;
    width: 35%;

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f7f7f7;
      border: 1px solid #c8c8c8;
      transition: background 0.45s cubic-bezier(0.3, 1, 0.4, 1) 0s,
        left 0.45s cubic-bezier(0.3, 1, 0.4, 1) 0s,
        right 0.45s cubic-bezier(0.3, 1, 0.4, 1) 0s;
    }

    .slider:before {
      position: absolute;
      content: "OFF";
      text-align: center;
      height: 34px;
      width: 50%;
      left: 3px;
      bottom: 2px;
      line-height: 34px;
      font-size: $font-size-base;
      transition: background 0.45s cubic-bezier(0.3, 1, 0.4, 1) 0s,
        left 0.45s cubic-bezier(0.3, 1, 0.4, 1) 0s,
        right 0.45s cubic-bezier(0.3, 1, 0.4, 1) 0s;
      background: linear-gradient(#fff, #f8f8f8);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 17px;
    }
  }
  .switch.active > .slider::before {
    right: 3px;
    left: initial;
    background: linear-gradient(#298ddc, #1c72b9);
    content: "ON";
    color: white;
  }
}
