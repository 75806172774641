/* -----------------------------------------------------------------------------

  ROOT ELEMENTS
  
----------------------------------------------------------------------------- */

//html,
//body {
//  margin: 0;
//  padding: 0;
//  height: 100%;
//}

//body {
//  color: $color--neutral;
//  background-color: #fff;
  //font-size: $font-size--small;
  //line-height: $line-height--medium;
//}