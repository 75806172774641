@import '../../style/variables';

.checkbox-container {
  &.navigate {
    background-color: #fff;
    color: #000;
    font-size: 18px * $scale-koef;
    cursor: pointer;
    &::after {
      content: " ";
      position: absolute;
      right: 10px;
      background-image: var(--arrow-down-icon);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 20px;
      width: 63px;
      height: 20px;
      transform: rotate(-90deg);
    }
  }
}

